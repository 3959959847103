import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { Dashboard } from '@app/Components/Dashboard/Dashboard';
import { TemplateID } from '@app/Global/Models/ClientModels';
import { AccountSummaryDetail } from './AccountSummaryDetail/AccountSummaryDetail';

@Component({
  selector: 'AccountSummaryConfigurations',
  templateUrl: './AccountSummaryConfigurations.html',
  styleUrls: ['./AccountSummaryConfigurations.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ]
})
export class AccountSummaryConfigurations implements OnInit {

  public AccountSummaryConfigs = [];

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    public dialog: MatDialog) {

  }

  //Angular startup method
  ngOnInit() {

    //Grab all lender config on init, so that the template can loop through and show them.
    this.AccountSummaryConfigs_GetAll();
  }

  public AccountSummaryConfigs_GetAll() {
    this.AccountSummaryConfigs.push({ ID: "Config1", Name: "Account Summary Config (Default)" });
    this.AccountSummaryConfigs.push({ ID: "Config2", Name: "Account Summary Config (Customer Service)" });
  }

  //Launch modal of chosen lender config
  public AccountSummaryConfigDetail_View() {

    //this.clientDataStore.SetShowFullscreenLoading(true);



    //Get the component of this modal, and set a property on it. looks like it is stored in statementModalRef.componentInstance
    this.globalFunctions.FeatureModal_Launch(AccountSummaryDetail, this.globalFunctions.GetFeatureModalConfig('40%', false, true), this.dialog, "Account Summary Config", 0, true, false);
  }
}