import { Injectable } from "@angular/core";
import moment from "moment";
import { NotifyService } from '@app/Services/NotifyService';
import { ClientDataStore, StoreItemTypes } from './ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NavigationUrls, StaticDataControllerMethods } from './EnumManager';
import { LoginModalWrapper } from '@app/Components/Loan/LoginModal/LoginModalWrapper';
import { environment } from "@env/environment";
import { DataRow, DataUnit, Entity } from "./Models/EntityModels";
import { TemplateID } from "./Models/ClientModels";
import { Delta } from "quill/core";
import { Overlay } from '@angular/cdk/overlay';

@Injectable({
	providedIn: "root",
})

//helper functions in a global singleton class
export class GlobalFunctions {
	constructor(private clientDataStore: ClientDataStore,
		private matDialog: MatDialog,
		private router: Router,
		public overlay: Overlay) {
	}

	//List of child entities
	public ChildEntities = ["ClientAddress", "ClientContact", "ClientIdentifiers", "IndividualEmployments", "IndividualSelfEmployed", "ClientCustomFields", "ClientCustomNumeric", "LoanTaskNotes", "LoanTaskDocuments", "LoanValuations", "LoanInsurance", "AssetLocations"];

	//List of feature mdoals
	public FeatureModals = [];

	//To show or hide the minimized dialog content
	public ShowMinimizedDialogMenu = false;

	//Mat date picker overlay
	public MatDatePicker: any;

	//Get minimized dialogs to show in the menu dialog
	public MinimizedDialogs_Get() {
		return this.FeatureModals.filter(x => x.IsMinimized === true);
	}

	//Add the minimized modal to the feature modal list and minimize it
	public MinimizedDialogs_Add(modalIdentifier) {

		//Find the matching modal in the array
		const matchingModal = this.FeatureModals.filter(x => x.GUID === modalIdentifier)[0];
		if (!this.isEmpty(matchingModal)) {

			matchingModal.IsVisible = false;
			matchingModal.IsMinimized = true;

			//Now minimize the dialog
			this.MinimizedDialog_Hide(matchingModal);
		}
	}

	//Toggle the ShowMinimizedDialog with force boolean
	public MinimizedDialogsVisibility_Toggle(showDialogMenu = false) {
		this.ShowMinimizedDialogMenu = showDialogMenu;

		//Force sync if we are going to show the menu dialog
		if (showDialogMenu) {

			//Now sync the display of menu
			this.MinimizedDialogsVisibility_Sync();
		}
	}

	//To show/hide the minimized dialogs screen
	public MinimizedDialogsVisibility_Sync(): void {

		this.ShowMinimizedDialogMenu = false;
		if (this.MinimizedDialogs_Get().length > 0) {
			this.ShowMinimizedDialogMenu = true;
		}
		else {

			//Reset the body overflow overrides
			this.ScrollBarBlock_Sync();
		}
	}

	//To minimize the dialog
	public MinimizedDialog_Hide(targetMenuDialog) {

		//Find the element to store the position of the dialog/modal being minimized
		const childElemRect = targetMenuDialog.DialogRef._containerInstance['_elementRef'].nativeElement.getBoundingClientRect();

		if (!this.isEmpty(childElemRect)) {

			//Declare translateX value to offset
			let translateXValue = 0;

			//Get computed style of the parent element which contains the transform values when a modal is dragged (cdkDrag)
			const parentElemStyle = window.getComputedStyle(targetMenuDialog.DialogRef._containerInstance['_elementRef'].nativeElement.parentElement);

			if (!this.isEmpty(parentElemStyle)) {
				//Get the transform matrix value from the parent element style. It returns in a string format
				const transformPropertyValue = parentElemStyle.getPropertyValue('transform');

				//If not dragged yet, the value will be none
				if (!this.isEmpty(transformPropertyValue) && transformPropertyValue !== "none") {

					//If dragged, the transform property value will be return in a matrix format matrix(1, 0, 0, 1, 10, 20). Here fifth item is the translateX
					const matrixTransformArray = transformPropertyValue.split(",");

					//Check if the array has any values
					if (matrixTransformArray.length > 0) {

						const transformX = matrixTransformArray[4];
						if (!this.isEmpty(transformX)) {
							translateXValue = parseInt(transformX.trim());
						}
					}
				}

				//This method can be used to extract translateX
				//const matrix = new WebKitCSSMatrix(parentElemStyle.transform);
				//console.log('translateX: ', matrix.m41);
			}

			//Lets store the dialog position before minimizing
			targetMenuDialog.xPosition = childElemRect.left + (-1 * translateXValue) + 'px';
		}

		//Now we need to disable the backdrop when a modal is minimized
		this.MinimizedDialogBackdrop_Toggle(targetMenuDialog.GUID, false);

		//Move the dialog away from the screen
		targetMenuDialog.DialogRef.updatePosition({ right: '-5000px', top: '-5000px' });
		targetMenuDialog.IsVisible = false;

		//Sync the minimized dialogs array
		this.MinimizedDialogsVisibility_Sync();

		//Enable the scroll bar
		this.ScrollBarBlock_Sync();
	}

	//To maximize the dialog
	public MinimizeDialog_Show(targetMenuDialog) {

		//When a dialog is maximized, loop through all other dialogs in the array and minimize them
		this.FeatureModals.filter(x => x.IsMinimized === true && x.IsVisible === true).forEach(dialog => {
			this.MinimizedDialog_Hide(dialog);
		});

		//Default x position of the maximized dialog
		let xPosition = '300px';
		if (!this.isEmpty(targetMenuDialog.xPosition)) {
			xPosition = targetMenuDialog.xPosition;
		}

		//Bring the target modal to its original position when maximized
		targetMenuDialog.DialogRef.updatePosition({ left: xPosition, bottom: "0" });
		targetMenuDialog.IsVisible = true;

		//Enable the backdrop
		this.MinimizedDialogBackdrop_Toggle(targetMenuDialog.GUID, true);

		//Hide the scroll bar
		this.ScrollBarBlock_Sync();

		//Now sync the minimized dialogs list
		this.MinimizedDialogsVisibility_Sync();
	}

	//Remove all the loan related dialogs when a user leave the account index page
	public MinimizedDialogs_Reset(): void {

		//Check if there are any dialogs in the minimized list
		if (!this.isEmpty(this.FeatureModals) && this.FeatureModals.length > 0) {

			//List the account related modals from the array to destroy
			this.FeatureModals.filter(x => x.RefAccountID !== 0).forEach(dialog => {

				if (!this.isEmpty(dialog)) {

					//Close the modal
					this.FeatureModal_Close(dialog.GUID);
				}
			});
		}

		//Sync the display of dialog icon
		this.MinimizedDialogsVisibility_Sync();
	}

	//Close all feature modals
	public FeatureModals_CloseAll(): void {

		//Check if there are any dialogs in the minimized list
		if (!this.isEmpty(this.FeatureModals) && this.FeatureModals.length > 0) {

			//List the account related modals from the array to destroy
			this.FeatureModals.forEach(dialog => {

				if (!this.isEmpty(dialog)) {

					//Close the modal
					this.FeatureModal_Close(dialog.GUID);
				}
			});
		}

		//Sync the display of dialog icon
		this.MinimizedDialogsVisibility_Sync();
	}

	//Toggle backdrop when a modal is minimized or maximized
	public MinimizedDialogBackdrop_Toggle(targetClassName = "", enable = true): void {

		//Toggle the backdrop
		const backdrop = document.getElementsByClassName(targetClassName)[0];

		if (!this.isEmpty(backdrop) && !this.isEmpty(backdrop.classList)) {

			//CSS to disable backdrop. These styles are being used for piercing selector in appcomponent.scss to target the element for enabling/disabling the backdrop
			let cssToAdd = "glb_disableBackdrop";
			let cssToRemove = "glb_enableBackdrop";

			//Check if we need to enable it
			if (enable) {
				cssToAdd = "glb_enableBackdrop";
				cssToRemove = "glb_disableBackdrop";
			}

			//Remove the css
			backdrop.classList.remove(cssToRemove);

			//Add the css if not already exists
			if (!backdrop.classList.contains(cssToAdd)) {
				backdrop.classList.add(cssToAdd);
			}
		}
	}

	//Toggle the minimized dialog/windows based on the click
	public MinimizedWindowsSwitch_Toggle(dialog) {

		//Get the clicked dialog
		const dialogClicked = this.FeatureModals.filter(x => x.GUID === dialog.GUID)[0];

		if (dialogClicked.IsMinimizableDialog === true) {
			//If clicked on minimized one, lets maximize it
			if (dialogClicked.IsVisible === false) {

				//Maximize the dialog
				this.MinimizeDialog_Show(dialogClicked);
			}

			//Or vice-versa
			else {

				//Minimize the dialog
				this.MinimizedDialog_Hide(dialogClicked);
			}
		}

		//Close the Mat Date Picker if there is any opened ones
		this.MatDatePicker_Close();
	}

	//Get the feature modal base on the dialog name, if it exists
	public FeatureModal_Get(dialogName: string) {

		//Get the dialog from the feature modal based on the name
		return this.FeatureModals.filter(x => x.Name === dialogName)[0];
	}

	//Toggle y scroll bar when a modal is minimized or maximized
	public ScrollBarBlock_Sync(): void {

		//Get the HTML element
		const htmlElement = document.getElementsByTagName("html")[0];

		if (!this.isEmpty(htmlElement)) {

			//CSS that blocks scrolling
			const scrollBlockCSS = "glb_scrollblock";

			//Remove the css that blocks scrolling
			htmlElement.classList.remove(scrollBlockCSS);

			if (!this.isEmpty(this.FeatureModals)) {

				//Check if there is any modal launched/visible on the screen
				const launchedModal = this.FeatureModals.filter(x => x.IsVisible)[0];
				if (!this.isEmpty(launchedModal)) {

					//There is a feature modal on the screen, add the css to block the scrolling, if not already exists
					if (!htmlElement.classList.contains(scrollBlockCSS)) {
						htmlElement.classList.add(scrollBlockCSS);
					}
				}
			}
		}
	}

	//When a date picker calendar is opened
	public DatePicker_Opened(picker) {

		//Set the mat date picker overlay instance to the class property
		this.MatDatePicker = picker;
	}

	//Close the mat datepicker panel if it is open
	public MatDatePicker_Close(): void {

		//Check if we have any opened instance of mat date picker
		if (!this.isEmpty(this.MatDatePicker)) {
			this.MatDatePicker.close();
		}
	}

	//Close the Mat Select overlay panal when it is not focused
	public MatSelectOverlay_Close(matSelect): void {

		if (!this.isEmpty(matSelect)) {

			//Add a bit delay for the selected item to be binded before it closes
			this.delay(500).then(() => {
				matSelect.close();
			});
		}
	}

	//Method to launch the dialog and assigning an identifier to the launched modal
	public FeatureModal_Launch(component, modalConfig, matDialog, name, accountID, isNestedDialog = false, isMinimizableDialog = false, overrideModalIdentifierGUID = null) {

		//Let's create a unique identifier when a modal is launched. This will be used for tracking the minimizing/maximizing the windows
		let modalIdentifier = this.GenerateFastGUID();

		//See if the override guid is being passed from the caller
		if (!this.isEmpty(overrideModalIdentifierGUID)) {
			modalIdentifier = overrideModalIdentifierGUID;
		}

		//Class "cdk-overlay-dark-backdrop" is the default class that gets added when backdrop is enabled. We are explicitly adding here so that we can concatanate the modal identifier here that will be used for enabling/disabling the backdrop when a modal is minimized or maximized
		const backDropClassModified = ['cdk-overlay-dark-backdrop'];
		backDropClassModified.push(modalIdentifier);

		//If it is a nested modal, add the static identifier css to be used for backdrop purpose
		if (isNestedDialog) {

			//Push the identifier for nested modal
			backDropClassModified.push('NestedModalGUID');

			//Hide the minimized menu dialog
			this.MinimizedDialogsVisibility_Toggle(false);

			//Nested can never be minimized
			isMinimizableDialog = false;
		}

		modalConfig.backdropClass = backDropClassModified;

		//Get the component of this modal, and set a property on it. 
		const dialogRef = matDialog.open(component, modalConfig, modalIdentifier);

		//Construct a feature modal with additoin
		const featureMenuDialog = new FeatureModal({ Name: name, DialogRef: dialogRef, IsVisible: true, RefAccountID: accountID, GUID: modalIdentifier, IsMinimizableDialog: isMinimizableDialog });

		//This unique identifier is used to control the backdrop when the modal is minimized/maximized
		if (!this.isEmpty(featureMenuDialog.DialogRef.componentInstance)) {
			featureMenuDialog.DialogRef.componentInstance.ModalIdentifier = modalIdentifier;
			featureMenuDialog.DialogRef.componentInstance.AccountID = accountID;
		}

		//Push the feature modal to the array
		this.FeatureModals.push(featureMenuDialog);

		//Sync the scroll for HTML
		this.ScrollBarBlock_Sync();

		//Return the ref
		return featureMenuDialog;
	}

	//Close the modal dialog
	public FeatureModal_Close(modalIdentifier, refreshPage: boolean = null, ngDestroySubscription = false) {

		if (!this.isEmpty(this.FeatureModals) && !this.isEmpty(modalIdentifier)) {
			const matchingModal = this.FeatureModals.filter(x => x.GUID === modalIdentifier)[0];

			if (!this.isEmpty(matchingModal)) {
				//Find the index to splice from the array
				const matchingDialogIndex = this.FeatureModals.findIndex(x => x.GUID === matchingModal.GUID);
				this.FeatureModals.splice(matchingDialogIndex, 1);

				//this.MinimizedDialog_Remove(matchingModal.DialogRef, Number(matchingModal.RefAccountID));
				if (!ngDestroySubscription) {
					if (!this.isEmpty(matchingModal.DialogRef)) {
						if (this.isEmpty(refreshPage)) {
							matchingModal.DialogRef.close();
						}
						else {
							matchingModal.DialogRef.close(refreshPage);
						}
					}
				}
			}
		}

		//Sync the scroll for HTML
		this.ScrollBarBlock_Sync();

		//Now sync the minimized dialogs and check if there are any more minimized items
		this.MinimizedDialogsVisibility_Sync();
	}

	//Feature CSS
	//Default Flex css needed for a component to flex to its container
	public FlexCSS = "col-12 glb_customFlexRow";

	//2 Col display for md and large sizes
	public Col2CSS = "col-12 glb_customFlexRow col-md-6 col-lg-6";

	//3 Col display for md and large sizes
	public Col3CSS = "col-12 glb_customFlexRow col-md-4 col-lg-4";

	//Default Flex css for Level 1 sections on the page. This contains some vertical padding to spread things out
	public L1SectionCSS = "col-12 glb_customFlexRow glb_PaddingVerticalSM";

	//Default Flex css for Level 1 sections on the page for small inputs. This contains some vertical padding to spread things out
	public L1SectionCSSSM = "col-xl-4 col-lg-4 col-md-4 col-12 glb_customFlexRow glb_PaddingVerticalSM";

	//Default Flex css for Level 1 sections on the page. This contains some vertical padding to spread things out
	public L1ParentSectionCSS = "col-lg-9 col-md-9 col-12 glb_customFlexRow glb_PaddingVerticalSM";

	//Default css for children of yes/no questions (L1 pselect sections) that share a background
	public ChildNoWrapCSS = "col-12 glb_customFlexNoWrapRow glb_PaddingHorizontalXS glb_inputBackgroundColor glb_boxedPaddingNarrow";

	//Textarea valid lengths
	public TextAreaValidLengthXS = 3;
	public TextAreaValidLengthSM = 10;
	public TextAreaValidLengthMD = 50;
	public TextAreaValidLengthLG = 100;

	public TextAreaValidLengthOptional = -1;
	public TextAreaValidMaxLengthXS = 100;
	public TextAreaValidMaxLengthSM = 500;
	public TextAreaValidMaxLengthMD = 1000;
	public TextAreaValidMaxLengthLG = 5000;

	//Numeric valid range
	public TextAreaValidMinNumeric = 0;
	public TextAreaValidMinNumericGreaterThanZero = 0.01;
	public TextAreaValidMaxNumeric = 99999999999;
	public TextAreaValidMaxPercentage = 99999;
	public TextAreaValidMaxNumericMD = 50000;

	//Deep Clone the object using JSON
	public Deep_Clone(item) {
		const itemCloned = JSON.parse(JSON.stringify(item));
		if (this.isEmpty(itemCloned) == true) {
			return '';
		}
		else {
			return itemCloned;
		}
	}

	//check if a string is numeric
	public IsNumeric(value: string | number, allowNull = false): boolean {
		if (allowNull && this.isEmpty(value)) {
			return true;
		}

		return ((value != null) &&
			(value !== '') &&
			!isNaN(Number(value.toString())));
	}

	//Get the property value in an object
	public DynamicProperty_Get<T, K extends keyof T>(obj: T, key: K) {
		return obj[key];
	}

	//Set the property value in an object
	public DynamicProperty_Set<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
		obj[key] = value;
	}

	//Check the user access claim
	public Claim_VerifyPermission(clientDataStore: any, claimName: string, modifyClaimType: string): boolean {
		let permissionVerified = false;
		if (this.isEmpty(clientDataStore.ClientClaims) === false) {
			if (clientDataStore.ClientClaims.filter(x => x.Name == claimName).length > 0) {

				//Now check the modify claim type.
				const claim = clientDataStore.ClientClaims.filter(x => x.Name == claimName)[0];

				//Get the value of the modifyClaimType in the claim object
				if (this.DynamicProperty_Get(claim, modifyClaimType) === true) {
					permissionVerified = true;
				}
			}
		}
		return permissionVerified;
	}

	//Check the AMALEmployee claim
	public Claim_VerifyAMALEmployee(clientDataStore: any): boolean {
		let permissionVerified = false;
		if (this.isEmpty(clientDataStore.ClientClaims) === false) {
			if (clientDataStore.ClientClaims.filter(x => x.Name == "AMALEmployee").length > 0) {
				permissionVerified = true;
			}
		}
		return permissionVerified;
	}

	//Check if the user has entity property claim
	public EntityPropertyClaim_VerifyPermission(clientDataStore: any, entityName: string, entityPropertyName: string, modifyClaimType: string) {
		let permissionVerified = false;

		//Find the matching entity property claim, if it exists
		const matchingClaim = clientDataStore.ClientClaims.filter(x => x.Name === "EntityProperty" && x.EntityPropertyClaim.EntityFriendlyName === entityName && x.EntityPropertyClaim.EntityPropertyName === entityPropertyName);

		if (!this.isEmpty(matchingClaim)) {

			//Get the value of the modifyClaimType in the claim object
			if (this.DynamicProperty_Get(matchingClaim[0], modifyClaimType) === true) {
				permissionVerified = true;
			}
		}
		return permissionVerified;
	}

	//Check if the user has entity claim
	public EntityClaim_VerifyPermission(clientDataStore: any, entityName: string, modifyClaimType: string) {
		let permissionVerified = false;

		//Find the matching entity claim, if it exists
		const matchingClaim = clientDataStore.ClientClaims.filter(x => x.Name === "Entities" && x.EntityClaim.EntityFriendlyName === entityName);

		if (!this.isEmpty(matchingClaim)) {

			//Get the value of the modifyClaimType in the claim object
			if (this.DynamicProperty_Get(matchingClaim[0], modifyClaimType) === true) {
				permissionVerified = true;
			}
		}
		return permissionVerified;
	}

	//Filter control data based on some incoming input, client side only
	public ControlData_Filter(controlType = '') {
		let filteredControlData = [];

		//Grabs Control data, no filters
		filteredControlData = this.clientDataStore.ControlData.filter(x => x.ControlType == controlType);
		return filteredControlData;
	}

	//prepare an autocomplete apirequest
	public AutoComplete_PrepareAPIRequest(requestType: string, controlType: string, requestValue: string, fieldType: string, accountID = 0, autoCompleteLinkType = "") {
		//construct a AutoComplete request body that we can post to the server
		const apiRequest = { RequestType: requestType, ControlType: controlType, SearchValue: requestValue, LinkType: '', AutoCompleteEndpoint: '', AccountID: accountID };

		//need to flip the endpoint here, dependant on the field that we are looking up.
		let autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete];

		if (!this.isEmpty(autoCompleteLinkType)) {
			apiRequest.LinkType = autoCompleteLinkType;
		}
		else {
			if (fieldType === "LinkType") {
				autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete];
			}
			else if (fieldType === "Client") {
				autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete_Client];
			}
			else if (fieldType === "Role") {
				autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete_Role];
			}
			else if (fieldType === "Employee") {
				autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete_Employee];
			}
			else if (fieldType === "Lender") {
				autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete_Client];
				//inject the restrictor for link types
				apiRequest.LinkType = fieldType;
			}
		}

		apiRequest.AutoCompleteEndpoint = autoCompleteEndpoint;
		return apiRequest;
	}

	//saves the autocompleted value locally on a click, for later use when sending request to the server. must be provided a local class property to bind to, via html
	public AutoComplete_SaveSelectedControlRecord(value: any, localBind: any) {
		if (!this.isEmpty(value) && !this.isEmpty(value.value)) {
			//save it into the local Bind variable.
			localBind = { ControlDisplay: value.value.ControlValue, ControlGUID: value.value.ControlGUID, ControlValue: value.value.ControlValue, ControlType: "" };

			//let's try copying values with deep clone instead of ref. couldn't get this to work, leave it for now
			//localBind.ControlDisplay = JSON.parse(JSON.stringify(value.value.ControlValue));
			//localBind.ControlGUID = JSON.parse(JSON.stringify(value.value.ControlGUID));
			//localBind.ControlValue = JSON.parse(JSON.stringify(value.value.ControlValue));
			//localBind.ControlType = '';
		}
		return localBind;
	}

	//processes the autocomplete data returned by the server and pushes it into the supplied array
	public AutoComplete_ProcessResponse(apiResponse: any[], controlArray: any): void {
		if (!this.isEmpty(apiResponse)) {
			//reset the array, then chuck the data in
			//console.log('controlArray', controlArray);

			//dont process if the passed in control array is empty.
			if (!this.isEmpty(controlArray)) {
				//setting the length of an array to zero it the fastest way to clear an array in TS.
				controlArray.length = 0;
			}

			const results = apiResponse;
			for (const key in results) {
				const ControlDataUnit = {
					Entity: results[key].Entity,
					ControlType: results[key].ControlType,
					ControlGUID: results[key].ControlGUID,
					ControlValue: this.HTMLUnescape(results[key].ControlValue),
				};
				//push the result in to the control array by ref
				controlArray.push(ControlDataUnit);
			}
		}
		else {
			//no results! empty out the array, only when it is non empty
			if (!this.isEmpty(controlArray)) {
				controlArray.length = 0;
			}
		}
	}

	//in relation to autocomplete - read the response, return the ControlValue out of it. this allows autocomplete to show the 'pretty' value in the input box
	public AutoComplete_GetPrettyName(value: any): string {
		//console.log("AutoComplete_GetPrettyName value", value);
		if (value === null) {
			return "";
		}
		else {
			if (value.ControlValue != null) {
				return value.ControlValue;
			}
			else {
				return value;
			}
		}
	}

	//Get current date
	public CurrentDate_Get(): Date {
		//Set defaults for the Payout Date
		const date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();

		//Now return (no time)
		return new Date(y, m, d);
	}

	//Primeng File upload preparation. Invoking component must have FileBase64String class property
	public FileSelected_Process(event: Event, fileUpload, notifyService: NotifyService, component: any): any {

		//File upload data to return file data
		const fileUploadData = { FileUpload_FileName: "", FileUpload_IsValid: false, FileUpload_IsChosen: false, FileUpload_FileBase64String: null };

		//Reset the FileBase64String on invoking component
		component.FileBase64String = null;

		//Get the Primeng target file
		const targetFile = event["currentFiles"][0] as HTMLInputElement;

		//Check that a file was chosen
		if (this.isEmpty(targetFile)) {
			notifyService.Warning_Show("No file chosen", "Please select a file")

			//Clear the file selection
			fileUpload.clear();
			return fileUploadData;
		}
		else {

			//Ok so a file was chosen
			fileUploadData.FileUpload_IsChosen = true;
		}

		//Check file size, stop large ones.
		const fileSize = targetFile.size;

		//This is in bytes. anything larger than 15 MB is probably too big.
		if (fileSize > 15728640) {
			fileUploadData.FileUpload_IsValid = false;
			notifyService.Warning_Show("File must be under 15 Megabytes", "File too large")
			fileUpload.clear();
			return fileUploadData;
		}

		//Check if the file is empty
		if (fileSize <= 0) {
			fileUploadData.FileUpload_IsValid = false;
			notifyService.Warning_Show("File is empty or missing content", "Empty File")
			fileUpload.clear();
			return fileUploadData;
		}

		//Check file type, only allow certain ones.
		//Grab the file name
		fileUploadData.FileUpload_FileName = targetFile.name

		//Now grab the extension
		const fileExtension = fileUploadData.FileUpload_FileName.substring(fileUploadData.FileUpload_FileName.lastIndexOf('.') + 1).toUpperCase();

		//Check if its an allowed file type
		if (this.AllowedFileExtensions.filter(x => x.toUpperCase() === fileExtension).length === 0) {

			//Invalid
			fileUploadData.FileUpload_IsValid = false;
			notifyService.Warning_Show("Allowed types are: " + this.GetAllowedFileTypes(), "Invalid file Type")
			fileUpload.clear();
			return fileUploadData;
		}
		else {

			//Indicate that its valid
			fileUploadData.FileUpload_IsValid = true;
		}

		//Convert the file into Base64 in prep for upload, with callback support
		this.File_GetBase64(component, targetFile, this.File_HandleFileBase64Result).then(() => {

			fileUploadData.FileUpload_FileBase64String = component.FileUploadData.FileUpload_FileBase64String;

			//This is a specific callback for FormInputDataUnit form
			component.ModelDataUnit_Update({ id: component.ID }, fileUploadData);
		},
			() => { console.log("Error getting FileBase64") });

		return fileUploadData;
	}

	//Gets the base64 from a selected file
	private File_GetBase64(obj, file, ResultHandler): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			//This is a callback
			reader.onload = function () {

				//Pass the parent class to the handler, so that it can set variables on it
				ResultHandler(obj, reader.result);
				resolve();
			};
			reader.onerror = function (error) {

				//TODO improve this
				console.log('Error: ', error);
				reject();
			};
			return;
		}
		)
	}

	//Handles getting the base64 of the selected file
	private File_HandleFileBase64Result(obj, result: string) {

		//The raw base64 string is after the first comma
		const base64result = result.split(',')[1];

		//Set the variable on the invoking component so that it can be pulled into the save requested later
		obj.FileUploadData.FileUpload_FileBase64String = base64result;
	}

	//limit a string to x length, return that string with x + "...". useful for when a hover/popup/tooltip style would show the full text
	public LimitTextSize(input: any, length: number) {

		//Limit the text size if it is not empty and its length is greater than the limit
		if (!this.isEmpty(input) && input.length > length) {
			return input.substring(0, length) + '...';
		}

		//Otherwise return the text as it is
		return input;
	}

	//HTML unescape for values retrieved from server
	public HTMLUnescape(str) {
		if (this.isEmpty(str)) { return str }
		return str
			.replace(/&quot;/g, '"')
			.replace(/&#39;/g, "'")
			.replace(/&lt;/g, '<')
			.replace(/&gt;/g, '>')
			//important that ampersand goes last, otherwise it will get unescaped multiple times, since it exists in other escape sequences.
			.replace(/&amp;/g, '&');
	}

	//HTML unescape for values retrieved from server
	public HTMLEscape(str) {
		if (this.isEmpty(str)) { return str }
		return str
			//important that ampersand goes first, otherwise it will get escaped multiple times, since it exists in other escape sequences.
			.replace(/&/g, '&amp;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, "&#39;")
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;');
	}

	//gen a fast GUID
	public GenerateFastGUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	//returns the application name
	public GetApplicationName(): string {
		return "XCHANGE"
	}

	//when using GUIDS to bind to elements in html, we can't have the curly braces { and } or even spaces! use this method to strip them out, as needed
	public StripBracesAndSpaces(str: string) {
		return this.ReplaceAllString(this.ReplaceAllString(this.ReplaceAllString(str, "{", ""), "}", ""), /\s/, '');
	}

	//replace ALL instances of a string with the replacement value
	public ReplaceAllString(str, find, replace) {
		return str.replace(new RegExp(find, 'g'), replace);
	}

	//for when we have to send the stripped keys back to the server for new calls or updates - put ARMNet braces back in
	public ReinstateBraces(str: string) {
		return '{' + str + '}';
	}

	public IsValidDate(date) {
		return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
	}

	//parses the entity data into custom datarows that the client can use, including its child entities
	public Entity_ParseIntoDataRows(rootIdentifier: string, incomingServerEntity: Entity, localDataRowsByRef: DataRow[] | any, entityName: string, pushLocation = "", existingItemIndex = -99) {
		//console.log("server entity reponse: ", incomingServerEntity);

		//loop through each row here
		Object.entries(incomingServerEntity.EntityRows).forEach(
			([rowkey, value]) => {
				//console.log("entity identifier: ", rowkey);
				//console.log("entity row: ", value);

				const row = new DataRow(rootIdentifier, entityName, value.EntityIdentifier.GUID, value.EntityIdentifier.RowStyle, value.EntityIdentifier.EntityName, value.EntityIdentifier.DeleteLock, value.EntityIdentifier.EntityFriendlyName, value.EntityIdentifier.ControlDataType, value.ChildNavTabs, value.EntityIdentifier.IsParentRecord);

				//Check if there are any additional data units attached to the entity row
				if (!this.isEmpty(value.OverlayDataUnits)) {
					value.OverlayDataUnits.forEach(element => {
						row.OverlayDataUnits.push(element);
					});

					//Show overlay panel for additional DUs
					row.ShowOverlayDataUnits = true;
				}

				//console.log("entity row ChildNavTabs: ", value.ChildNavTabs);
				//loop through each ChildNavTabs, loop through each ChildNavTabEntities, and process each one. these are records for certain entities, e.g. UserTaskNotes and UserTaskDocuments.
				value.ChildNavTabs.forEach(element => {
					//console.log('element', element);

					element.ChildNavTabEntities.forEach(childNavTabEntity => {
						//console.log('childNavTabEntity', childNavTabEntity);

						//create a new property, that contains the processed child entities for this nav tab. and send that in to be processed
						childNavTabEntity.ProcessedChildNavTabEntity = [];
						this.Entity_ParseIntoDataRows(rootIdentifier, childNavTabEntity, childNavTabEntity.ProcessedChildNavTabEntity, childNavTabEntity.EntityType)

						//TODO to save on processing/space, can we just kill the other incoming records in childNavTabEntity? target whatever holds lots of data and wipe it out? hmm.
					});
				});

				//grab a reference to this inner column of dataUnits, and sort it
				const innerUnsorted = value.DataUnits;
				const inner: any[] = innerUnsorted.sort((n1, n2) => n1.Order - n2.Order);
				//now loop through each column
				for (let i = 0; i < inner.length; i++) {
					const obj = inner[i];
					const dataUnit = new DataUnit();
					dataUnit.Name = obj.Name;
					//keep the original value
					dataUnit.Value = obj.Value;
					//store a separate, client side, value display column. we use this to format the output
					dataUnit.ValueDisplay = obj.Value;
					//store the control GUID
					dataUnit.ControlGUID = obj.ControlGUID;
					//this will get updated later, just leave it out for now
					//dataUnit.ControlDisplay = obj.ControlDisplay;
					dataUnit.Type = obj.Type;
					dataUnit.Index = i;
					dataUnit.Link = obj.Link;
					dataUnit.HasReadAccess = obj.HasReadAccess;
					dataUnit.HasWriteAccess = obj.HasWriteAccess;
					dataUnit.DisplaySection = obj.DisplaySection;
					dataUnit.DisplaySectionAlignment = obj.DisplaySectionAlignment;
					dataUnit.EditBoxSize = obj.EditBoxSize;
					dataUnit.EditLock = obj.EditLock;
					dataUnit.ShowLabel = obj.ShowLabel;
					dataUnit.HideDisplay = obj.HideDisplay;
					dataUnit.HideEdit = obj.HideEdit;
					dataUnit.HideCreate = obj.HideCreate;
					dataUnit.CSSClass = obj.CSSClass;

					if (dataUnit.Type == "entity") {

						//this is where we pluck out the child entity.
						const nested = dataUnit.Link;

						this.Entity_ParseIntoDataRows(rootIdentifier, nested, row.ChildEntity, obj.Name)
					}
					else {
						//check and parse the autocomplete ones differently here
						if (dataUnit.Type == "autocomplete" || dataUnit.Type == "control") {
							//maybe set the value to the control guid? but sometimes they dont exist (e..g states). so check if non empty first.
							if (!this.isEmpty(obj.ControlGUID)) {
								dataUnit.Value = obj.ControlGUID;
								//unescape the display value
								dataUnit.ValueDisplay = this.HTMLUnescape(obj.ControlDisplay);
							}
						}
						else {
							//format the data types, which also handles unescaping.
							dataUnit.ValueDisplay = this.customDataTypeParser(dataUnit.ValueDisplay, dataUnit.Type);
							//why don't we have to unescape dataunit.Value too? because the loan entity model only shows the valuedisplay. only when launching the loanentitymodify modal do we care about modifying this so that the user can edit it in its raw form.
						}

						try {
							row.DataUnits.push(dataUnit);
						} catch (error) {
							//tbds
						}
					}
				}

				//add it to the class variable
				try {
					//first check if we are replacing some existing value.
					if (existingItemIndex >= 0) {
						localDataRowsByRef.splice(existingItemIndex, 1, row);
					}
					else {
						//check if we want to unshift (add to start) or not.
						if (pushLocation === "unshift") {
							localDataRowsByRef.unshift(row);
						}
						else if (pushLocation === "push") {
							localDataRowsByRef.push(row);
						}
						else {
							//default behaviour is to push
							localDataRowsByRef.push(row);
						}
					}
				} catch (error) {
					//tbd
				}
			}
		);

		//after the list has been processed, should we sort the data again? how do we deal with new rows being inserted?
		//the client doesn't actually know which columns to sort by. so let's just shift insert it at the top for now (unshift above will handle that for us, when requested by caller)
	}

	//nagivatiom URLs
	public navigationUrls = NavigationUrls;

	//Check the buildVersion against the stored value, if its different, then let the user know with a message, asking them to kindly hard refresh 
	public CheckBuildVersion() {

		//Get the build version we already have stored
		const prevBuildVersion = JSON.parse(localStorage.getItem(StoreItemTypes[StoreItemTypes.BuildVersion]));
		//console.log('prevBuildVersion', prevBuildVersion);

		//Get the current version
		const currentBuildVersion = environment.buildVersion;
		//console.log('currentBuildVersion', currentBuildVersion);

		//is the prev one empty?
		if (!this.isEmpty(prevBuildVersion)) {
			//now check if the build versions match
			if (prevBuildVersion !== currentBuildVersion) {
				//No match! time to cache bust!
				//console.log('prevBuildVersion DOESNT match currentBuildVersion:', currentBuildVersion);

				//Just let the user know it has been updated in non prod environments. We no longer need to tell users to manually refresh anymore.
				if (!environment.production) {
					const messageToUser = "The website has been updated!"
					window.alert(messageToUser);
				}

				//Flick the value and allow it to continue next time
				localStorage.setItem(StoreItemTypes[StoreItemTypes.BuildVersion], JSON.stringify(currentBuildVersion));
				//console.log('saved buildVersion:', currentBuildVersion);

				//Note that we do have outputHashing set to 'all' on the stage build now. hopefully that helps in ensuring that the latest code is used in a consistent manner
			}
			else {
				//matches, no need to cache bust
				localStorage.setItem(StoreItemTypes[StoreItemTypes.BuildVersion], JSON.stringify(currentBuildVersion));
				//console.log('prevBuildVersion matches currentBuildVersion:', currentBuildVersion);
			}
		}
		else {
			//we have never visited before, just save it and move on
			localStorage.setItem(StoreItemTypes[StoreItemTypes.BuildVersion], JSON.stringify(currentBuildVersion));
			//console.log('saved buildVersion:', currentBuildVersion);
		}
	}

	//the search highlight colour
	public GetSearchHighlightColor(): string {
		//orange/yellowish
		return "#6f7016";
		//green
		//return "#62c426";
	}

	//the filter highlight color (used in local loan entity filter/search)
	public GetLoanEntityFilterHighlightColor(): string {
		//hmm
		return "#ffdd60";
	}

	//some global static variables
	public customHighlightTagStart = '<markcustom style="background-color: #ffdd60">';
	public customHighlightTagEnd = '</markcustom>';
	//highlight tag version that lets you supply your own color
	public getCustomHighlightTagStart(color: string) {
		return '<markcustom style="background-color: ' + color + '">'
	}

	//method to remove all mark tags from a value (useful when copying for clipboard)
	public StripAllHighlightTags(inputValue: any, color: string): string {
		if (!this.isEmpty(inputValue)) {
			let modifiedValue = inputValue;
			modifiedValue = modifiedValue.replaceAll(this.getCustomHighlightTagStart(color), '');
			modifiedValue = modifiedValue.replaceAll(this.customHighlightTagEnd, '');
			return modifiedValue;
		}
	}

	//match these with the server values
	public static PasswordMinLength = 12;
	public static PasswordMaxLength = 50;

	//Return confirm modal config. This is now just a wrapper, it defaults the width to 30% and calls GetFeatureModalConfig and sets the nested dialog to true. All confirm modal must render above maximized menu
	public GetConfirmModalConfig(width = '30%') {
		return this.GetFeatureModalConfig(width)
	}

	//Return feature modal config
	public GetFeatureModalConfig(width = '90%', resizable = false, fixedHeight = false, fixedHeightClass = "glb_fixedHeightDialog") {

		const panelClass = ['glb_featureDialog'];

		//Check if we want to make this feature modal resizable
		if (resizable) {
			panelClass.push('glb_resizableDialog')
		}

		//Check if we want to make this feature modal fixed height
		if (fixedHeight) {
			panelClass.push(fixedHeightClass)
		}

		//Create a modal config
		const modalConfig = {
			backdrop: 'static'
			, keyboard: false
			, animated: true
			, ignoreBackdropClick: true
			, disableClose: true
			, class: ''
			, panelClass: panelClass
			, height: ''
			, width: width
			, scrollStrategy: this.overlay.scrollStrategies.noop()
		};

		return modalConfig;
	}

	//Validate image size validation uploaded via quill editor (source: https://stackoverflow.com/questions/64893574/how-can-i-add-validation-using-ql-image-on-p-editor-in-angular)
	public QuillImage_Validate(quill, myNotifyService: NotifyService): void {

		//Access quill toolbar
		const toolbar = quill.getModule('toolbar');

		//If image upload button/icon is clicked
		//() => [Lexical scoping] is important to allow a function to access the parent. E.g. NotifyService in this case.
		toolbar.addHandler('image', function () {

			let fileInput = toolbar.container.querySelector(
				'input.ql-image[type=file]'
			);
			if (fileInput == null) {
				fileInput = document.createElement('input');
				fileInput.setAttribute('type', 'file');
				fileInput.setAttribute(
					'accept',
					'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
				);
				fileInput.classList.add('ql-image');
				fileInput.addEventListener('change', function () {

					if (fileInput.files != null && fileInput.files[0] != null) {

						//Current max allowed in 300kb
						if (fileInput.files[0].size > 307200) {
							fileInput.value = '';
							myNotifyService.Warning_Show("Image must be less than 300kb", "Error");
						} else {

							//Embed the file in the quill editor
							const reader = new FileReader();
							reader.onload = function (e) {
								const range = quill.getSelection(true);
								quill.updateContents(
									new Delta().retain(range.index).delete(range.length).insert({
										image: e.target.result
									}),
									'user'
								);
								quill.setSelection(range.index + 1, 'silent');
								fileInput.value = '';
							};
							reader.readAsDataURL(fileInput.files[0]);
						}
					}
				});
				toolbar.container.appendChild(fileInput);
			}
			fileInput.click();
		});
	}

	//launch login modal
	public LaunchLoginModal(reloadAfterLogin = true, headerMessage = "Your session has expired, please log in", allowClose = false, goHome = false) {

		//Before we think about launching the login modal, check if it is already launched
		if (this.clientDataStore.LoginModalLaunched) {
			//its already launched, dont launch a new one
			return;
		}

		//Close all mat dialogs before launching the login page
		this.CloseAllMatDialogs();

		//now flip it on
		this.clientDataStore.LoginModalLaunched = true;

		//wrapper needed to avoid circular dependencies
		const dialogRef = this.FeatureModal_Launch(LoginModalWrapper, this.GetConfirmModalConfig(), this.matDialog, "Login Modal", 0, true, false);

		//use html content so that we can style it. this is no longer in the component instance, since we are using a wrapper, but we can pass it down via an @input!
		dialogRef.DialogRef.componentInstance.HtmlContent = headerMessage;
		//we can also pass in other styling requests. like if the close button should exist
		dialogRef.DialogRef.componentInstance.AllowClose = allowClose

		//after the dialog is closed, we can try to refresh
		dialogRef.DialogRef.afterClosed().subscribe(result => {
			//console.log("login dialog after close result: ", result);

			//Flow depends on dialog result
			if (result) {
				if (goHome) {
					//Go to the Home page
					const targetURL = [NavigationUrls.Home];

					//Tell router to reload the component, even if the URL is the same
					this.router.navigate(targetURL, { onSameUrlNavigation: 'reload' });
				}
				//Only reload if we were asked to
				else if (reloadAfterLogin) {
					//Return to the same page
					let targetURL = [this.router.url];

					//Except if we are already home or at the root, then just go home
					if (this.router.url.toUpperCase().includes("HOME") || this.router.url === "/") {
						//Go to the Home Page
						targetURL = [NavigationUrls.Home]
					}

					//Tell router to reload the component, even if the URL is the same
					this.router.navigate(targetURL, { onSameUrlNavigation: 'reload' });
				}
				else {
					//If we were asked to NOT reload, there might be a few pages where its still necessary. Let's try to help the client out here. So Home and Dashboard, for now, need a refresh. Turn this into a list later (so we can expand and add other pages, as needed)
					const targetURL = [this.router.url];

					if (this.router.url.toUpperCase().includes("HOME") || this.router.url === "/" || this.router.url.toUpperCase().includes(NavigationUrls.Dashboard.toString().toUpperCase())) {
						//Tell router to reload the component, even if the URL is the same
						this.router.navigate(targetURL, { onSameUrlNavigation: 'reload' });
					}
				}
			}
		});
	}

	//close all mat dialogs
	public CloseAllMatDialogs() {

		//Close all feature modals and clear the array
		this.FeatureModals_CloseAll();

		//In case any dialog is left
		this.matDialog.closeAll();
	}

	//checks and returns if the client thinks it is logged in
	public LoginCheck() {
		let isLoggedIn = false;
		if (this.clientDataStore.loginDataDirect != null) {
			//check logindata, if status is not logged in
			if (this.clientDataStore.loginDataDirect.UserLoggedInStatus === false) {
				isLoggedIn = false;
			}
			else if (this.clientDataStore.loginDataDirect.UserLoggedInStatus === true) {
				isLoggedIn = true;
			}
		}
		return isLoggedIn;
	}

	//List of on Demand entities
	public OnDemandEntities: { EntityName: string, IsParent: boolean, IsChild: boolean }[] = [
		{ EntityName: "InterestAccruals", IsParent: true, IsChild: true }
		, { EntityName: "LoanNotes", IsParent: true, IsChild: true }
		, { EntityName: "Arrears", IsParent: true, IsChild: true }
		, { EntityName: "ArrearsBreakdown", IsParent: false, IsChild: true }
		, { EntityName: "LoanDocuments", IsParent: true, IsChild: true }
		, { EntityName: "LoanFeeSetup", IsParent: true, IsChild: true }
		, { EntityName: "CustomFields", IsParent: true, IsChild: false }
		, { EntityName: "CustomFieldStrings", IsParent: false, IsChild: true }
		, { EntityName: "CustomFieldNumeric", IsParent: false, IsChild: true }
		, { EntityName: "CustomFieldDates", IsParent: false, IsChild: true }
		, { EntityName: "CustomFieldCurrency", IsParent: false, IsChild: true }
		, { EntityName: "CalculatedFields", IsParent: true, IsChild: true }
		, { EntityName: "AccountDataFields", IsParent: true, IsChild: true }
		, { EntityName: "BankDetails", IsParent: true, IsChild: true }
		, { EntityName: "LoanDates", IsParent: true, IsChild: true }
		, { EntityName: "LoanBalances", IsParent: true, IsChild: true }
		, { EntityName: "Securities", IsParent: true, IsChild: false }
		, { EntityName: "LoanSecurities", IsParent: false, IsChild: true }
		, { EntityName: "LoanVehicleSecurities", IsParent: false, IsChild: true }
		, { EntityName: "LoanRates", IsParent: true, IsChild: true }
		, { EntityName: "LoanTasks", IsParent: true, IsChild: true }
		, { EntityName: "LoanPayments", IsParent: true, IsChild: true }
		, { EntityName: "LoanTransfers", IsParent: true, IsChild: true }
		, { EntityName: "PendingTasks", IsParent: true, IsChild: true }
		, { EntityName: "LoanTransactions", IsParent: true, IsChild: true }
		, { EntityName: "Facility", IsParent: true, IsChild: false }
		, { EntityName: "FacilityBalances", IsParent: false, IsChild: true }
		, { EntityName: "FacilityLVR", IsParent: false, IsChild: true }
		, { EntityName: "LinkedAccounts", IsParent: false, IsChild: true }
	]

	//allowed list of file extensions that can be uploaded
	public AllowedFileExtensions: string[] = ["pdf", "txt", "doc", "docx", "xls", "xlsx", "jpg", "jpeg", "bmp", "text", "png", "eml", "msg", "xml", "csv"];

	//allowed list of file extensions that can be uploaded
	public AllowedFileTypesForView: string[] = ["pdf", "png", "jpg", "jpeg", "txt", "xml", "docx", "doc", "msg", "eml", "xls", "xlsx", "csv"];

	//Allowed list of file extensions that can be downloaded directly from the DocViewer
	public AllowedFileTypesForClientDownload: string[] = ["pdf", "png", "jpg", "jpeg", "txt", "xml", "csv"];

	//returns a friendly looking string with all the allowed file types that can be uploaded
	public GetAllowedFileTypes() {
		let fileTypes = "";
		this.AllowedFileExtensions.forEach(item => fileTypes = fileTypes + item + ", ");
		fileTypes = fileTypes.substring(0, fileTypes.length - 2);
		return fileTypes;
	}

	//Returns a friendly looking string with all the allowed file types that can be downloaded directly from the DocViewer
	public AllowedFileTypesToDownload_Get() {
		let fileTypes = "";
		this.AllowedFileTypesForClientDownload.forEach(item => fileTypes = fileTypes + item + ", ");
		fileTypes = fileTypes.substring(0, fileTypes.length - 2);
		return fileTypes;
	}

	//regex for integers
	regExInteger = new RegExp('^-?\\d*?$');
	//regex for currency 
	regExCurrency = new RegExp('^-?\\$?\\d*(\\.\\d{0,2})?$');
	regExCurrency8 = new RegExp('^-?\\$?\\d*(\\.\\d{0,8})?$');
	//regex for percents
	regExPercent = new RegExp('^-?\\d*(\\.\\d{0,4})?$');
	//regex for decimals
	regExDecimal2 = new RegExp('^-?\\d*(\\.\\d{0,2})?$');
	regExDecimal4 = new RegExp('^-?\\d*(\\.\\d{0,4})?$');

	//Get Regex by type
	public RegExp_Get(type): RegExp {

		let regExType = null;
		if (type == 'integer') {
			regExType = this.regExInteger;
		}
		else if (type == 'percent') {
			regExType = this.regExPercent;
		}
		else if (type.includes('decimal.2') === true) {

			//Decimal can have differing precision. pick the right one
			regExType = this.regExDecimal2;
		}
		else if (type.includes('decimal.4') === true) {
			regExType = this.regExDecimal4;
		}
		else if (type == 'currency') {

			//Updating the format in place is a lot of work. requires us to store the original unformatted string, and a pretty version.
			//Code that i have done before in xChange mobile. will come back to it if desired. for now lets just use in place, with regex (no formatting applied)
			regExType = this.regExCurrency;

			//Formatting? i think we may show a separate formatted display to the right? TO DECIDE later
		}
		else if (type.includes('currency.8') === true) {

			//Higher precision currency
			regExType = this.regExCurrency8;
		}

		return regExType;
	}

	//slight delay before checking if a loan was selected. useful for on startup.
	async delay(ms: number) {
		await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
	}

	//converts base 64 to a blob that can be displayed in a browser page, used for converting the document data from the server
	base64toBlob(b64Data, contentType = '', sliceSize = 512): Blob {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	//could be useful for formatting currency values to display. unused for now. move to global lib.
	formatMoney(input: number, n, x) {
		const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
		return input.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
	}

	//receive a number, and return a DATE that is number of months added to it. from the current date
	monthAdder(count: number): Date {
		if (count < 0) {
			return moment().subtract(Math.abs(count), 'months').toDate();
		}
		else {
			return moment().add(Math.abs(count), 'months').toDate();
		}
	}

	//for deep cloning an object, useful when filtering arrays.
	deepClone<T>(obj: T): T {
		return JSON.parse(JSON.stringify(obj)) as T;
	}

	//returns a javascript time that can be used for comparing dates
	getJScriptTime(date: any): number {
		return new Date(date).getTime();
	}

	isEmpty(str) {
		return !str || 0 === str.length || str == '""';
	}

	//returns true or false, used to determine if the first index should be displayed or not. currently unused
	displayIndex(str) {
		if (str == "0") {
			return true;
		}
		return false;
	}

	//removes a string from another string by index. used by the regex validator on keypress
	removeStringByIndex(str, index) {
		if (index == 0) {
			return str.slice(1)
		} else {
			return str.slice(0, index - 1) + str.slice(index);
		}
	}

	//this regexp will only remove the quotes if they are the first and last characters of the string
	cleanseString(str) {
		str = str.replace(/^"(.*)"$/, "$1");
		return str;
	}

	//format as percentage
	formatPercent(value: number, type: string) {
		//grab the precision, use the convention, split after the dot.
		//default precision to 4
		const split = type.split(".");
		let precision = 4;
		if (split[1] != null) {
			precision = Number(split[1]);
		}

		let val = (value * 1).toFixed(precision).replace(/\d(?=(\d{3})+\.)/g, "$&,");
		//stick the minus sign before the digits
		if (val.includes("-")) {
			val = val.replace("-", "")
			val = "- " + val;
		}

		return val + '%';
	}

	//Format as integer
	formatInteger(value: any) {

		//Set precision to 2 by default, we will remove the decimal point and the 2 precision digits later
		const precision = 2;

		//Get the decimal value with 2 precision
		let val = (value * 1).toFixed(precision);

		//Stick the minus sign before the digits
		if (val.includes("-")) {
			val = val.replace("-", "")
			val = "- " + val;
		}

		//Strip out the precision here. We default to precision = 2, so removing the last 3 characters (.00)
		val = val.substring(0, val.length - 3);

		return val;
	}

	//Format as decimal
	formatDecimal(value: any, type: string) {
		//grab the precision, use the convention, split after the dot.
		//default precision to 2
		const split = type.split(".");
		let precision = 2;
		let removePrecision = false;
		if (split[1] != null) {
			if (Number(split[1]) === 0) {
				removePrecision = true;
			}
			else {
				precision = Number(split[1]);
			}
		}

		let val = (value * 1).toFixed(precision).replace(/\d(?=(\d{3})+\.)/g, "$&,");
		//stick the minus sign before the digits
		if (val.includes("-")) {
			val = val.replace("-", "")
			val = "- " + val;
		}

		//We need to strip out the precision if the type passed is decimal.0. For 0 precision, we default to precision = 2, so removing the last 3 characters (.00)
		if (removePrecision) {
			val = val.substring(0, val.length - 3);
		}
		return val;
	}

	//format as currency
	formatCurrency(str, type) {
		//grab the precision, use the convention, split after the dot.
		//default precision to 2
		const split = type.split(".");
		let precision = 2;
		if (split[1] != null) {
			precision = Number(split[1]);
		}
		let val = (str * 1).toFixed(precision).replace(/\d(?=(\d{3})+\.)/g, "$&,")

		//stick the minus sign before the currency
		if (val.includes("-")) {
			val = val.replace("-", "")
			val = "- " + "$" + val;
		}
		else {
			val = "$" + val;
		}
		return val;
	}

	//Used for getting iso date that angular is happier to deal with, in components like calendar picker
	getISODate(str) {
		//This allows us to save empty date on the server
		if (this.isEmpty(str) || str === this.getServerNullString()) {
			return "";
		}

		//Added timestamp format to preserve the time component from the server
		const dateMoment = moment(str, "MM/DD/YYYY HH:mm:ss.SSS A");

		let dateVal = "";
		if (dateMoment.isValid) {

			//Remove the timezone but keep the 24 hour formatting on the time component & convert to ISO format (similar to toISOString method)
			dateVal = dateMoment.format('YYYY-MM-DDTHH:mm:ss.SSS');
		} else {
			dateVal = str;
		}

		return dateVal;
	}

	//Get the timestamp to append on the downloaded file name
	public FileTimeStamp_Get(): string {
		return moment(new Date(), "DD / MM / YYYY HH:mm A").format('YYYY-MM-DDTHH-mm-ss');
	}

	//Just format the entire thing in one function, based on type
	public customDataTypeParser(str, type, locale = "us") {

		//If its empty, then we don't need to do anything with it
		if (str !== this.getServerNullString()) {
			if (type.includes("date")) {
				str = this.getCustomDateFormat(str, type, locale);
			}
			if (type == "percent") {
				str = this.formatPercent(str, type);
			}
			if (type.includes("currency")) {
				str = this.formatCurrency(str, type);
			}
			if (type.includes("decimal")) {
				str = this.formatDecimal(str, type);
			}
			if (type.includes("integer")) {
				str = this.formatInteger(str);
			}
		}

		//and unescape it too.
		return this.HTMLUnescape(str);
	}

	//null string that is defined on the server. if the server GlobalAppData.NullStringDisplay is changed, then so must this
	getServerNullString(): string {
		return "(empty)";
	}

	//my own date parser. since the date pipe in angular is a piece of shit
	getCustomDateFormat(str, type, locale = "us", customDateFormat = "") {
		let dateMoment = null;
		if (locale == "us") {
			dateMoment = moment(str, "MM/DD/YYYY hh:mm:ss A");
		}
		else if (locale == "aus") {
			dateMoment = moment(str, "DD/MM/YYYY hh:mm:ss A");
		}
		else if (locale == "custom") {
			dateMoment = moment(str, customDateFormat);
		}

		let dateVal = "";
		if (dateMoment.isValid) {
			let format = "";
			if (type == "shortdatetime") {
				format = "DD MMMM YYYY";
			}
			else if (type == "shortdate") {
				format = "DD MMM YYYY";
			}
			else if (type == "longdate") {
				format = "DD MMM YY h:mm A";
			}
			else {
				format = "DD MMMM YYYY h.mm A";
				//format = 'DD MMMM YY';
			}
			//console.log(moment(dateMoment).format(format));
			dateVal = moment(dateMoment).format(format);
			if (dateVal.toLowerCase() == "invalid date") {
				//leave the original value.
				dateVal = str;
			}
		} else {
			//leave the original value.
			dateVal = str;
		}
		return dateVal;
	}

	/**
	 * Rounds the given value the exp
	 * @param value 
	 * @param precission 
	 * @returns  
	 */
	public round(value: any, precission: number) {
		if (typeof precission === 'undefined' || +precission === 0)
			return Math.round(value);

		value = +value;
		precission = +precission;

		if (isNaN(value) || !(typeof precission === 'number' && precission % 1 === 0))
			return NaN;

		// Shift
		value = value.toString().split('e');
		value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + precission) : precission)));

		// Shift back
		value = value.toString().split('e');
		return +(value[0] + 'e' + (value[1] ? (+value[1] - precission) : -precission));
	}

	//Get the css class based on the Template identifier state which drives if it should be displayed or not
	public TemplateID_GetCSS(identifierID: string, inverted = false, templateIdentifiers: TemplateID[]): string {
		let returnClass = "";
		if (!this.isEmpty(identifierID)) {
			//Inverted flag allows us to bind the active or inactive state. Eg. Chevron Down arrow bind to the inverted state
			if (!inverted) {
				if (!this.TemplateID_GetEnabledState(identifierID, templateIdentifiers)) {
					returnClass = returnClass + "glb_hiddenObjectImmediate ";
				}
			}
			else {
				if (this.TemplateID_GetEnabledState(identifierID, templateIdentifiers)) {
					returnClass = returnClass + "glb_hiddenObjectImmediate ";
				}
			}
			return returnClass;
		}
		return "";
	}

	//Collapse all the elements in the array
	public TemplateID_CollapseAll(templateIdentifiers: TemplateID[]): void {
		if (!this.isEmpty(templateIdentifiers)) {
			templateIdentifiers.forEach(template => {
				template.IsEnabled = false;
			});
		}
	}

	//Find and Toggle the IsEnabled Flag on the Template identifier
	public TemplateID_Toggle(identifierID: string, templateIdentifiers: TemplateID[], enableFlag = false): void {
		//Get the Template identifier from the passed array. Initialize if it doesn't exist
		const templateIdentifierID = this.TemplateID_Get(identifierID, templateIdentifiers, enableFlag);

		if (enableFlag) {
			templateIdentifierID.IsEnabled = enableFlag;
		}
		else {
			//Switch the is enabled flag on the Template identifier
			templateIdentifierID.IsEnabled = !(templateIdentifierID.IsEnabled);
		}
	}

	//HTML Unescaping for Lender config Data
	public LenderConfig_Unescape(lenderConfig) {

		lenderConfig.Name = this.HTMLUnescape(lenderConfig.LenderName);
		lenderConfig.CreaterName = this.HTMLUnescape(lenderConfig.DefaultAssigneeName);
		lenderConfig.LenderIndustryType = this.HTMLUnescape(lenderConfig.LenderIndustryType);
		lenderConfig.EmailReplyTo = this.HTMLUnescape(lenderConfig.EmailReplyTo);
		lenderConfig.EmailCCTo = this.HTMLUnescape(lenderConfig.EmailCCTo);
		lenderConfig.FromEmailDisplay = this.HTMLUnescape(lenderConfig.FromEmailDisplay);
		lenderConfig.EmailSubject_CS_Reminder = this.HTMLUnescape(lenderConfig.EmailSubject_CS_Reminder);
		lenderConfig.EmailSubject_CS_Followup = this.HTMLUnescape(lenderConfig.EmailSubject_CS_Followup);

		return lenderConfig;
	}

	//Get the Template identifier from the passed array. Initialize if it doesn't exist and return the identifier
	private TemplateID_Get(identifierID: string, templateIdentifiers: TemplateID[], enableFlag = false): TemplateID {
		//Look for the matching Identifier from the array
		const templateIdentifierIDArray = templateIdentifiers.filter(x => x.TemplateIdentifierGUID == identifierID);

		//If it doesn't exist
		if (this.isEmpty(templateIdentifierIDArray)) {
			//Initialize a default record for it
			const newTemplateID = { TemplateIdentifierGUID: identifierID, IsEnabled: enableFlag }
			templateIdentifiers.push(newTemplateID);

			//And return this new record
			return newTemplateID;
		}

		//Otherwise, return the record that we found
		return templateIdentifierIDArray[0];
	}

	//Return the is enabled state of the Template identifier
	private TemplateID_GetEnabledState(identifierID: string, templateIdentifiers: TemplateID[]): boolean {
		return this.TemplateID_Get(identifierID, templateIdentifiers).IsEnabled;
	}

	//Change the Javascript Date (p-calendar) to ISO Format
	public Date_ToISO(date): void {
		date.ISODate = moment(date.JSDate, "DD/MM/YYYY HH:mm A").format('YYYY-MM-DDTHH:mm');
	}

	//Get the Array Item based on a key and property name
	public ArrayItem_Get(key: string, property: string, arrayItems) {
		const arrayItem = arrayItems.filter(x => x.Key == key);
		if (!this.isEmpty(arrayItem[0])) {
			return this.DynamicProperty_Get(arrayItem[0], property);
		}

		return "";
	}

	//Toggle when a item is checked in the multiselect list
	public MultiSelect_Toggle(selectAll, options, selectedItems) {

		//Unselect the selectAll checkbox
		selectAll.Value = false;

		//Set the selectAll to true if the selected list contains all the available options
		if (selectedItems.length === options.length) {
			selectAll.Value = true;
		}
	}

	//Toggle all for a Multiselect input
	public MultiSelectAll_Toggle(e, selectAll, options, selectedItems, input) {

		//Toggle the selectAll flag
		selectAll.Value = e.checked;

		//Initialise selectedItems with a new empty array to trigger the label display sync. Using the existing array doesn't emit the onchange trigger in Primeng 17
		selectedItems = [];

		//If checked, fill all the available options to the selected list
		if (selectAll.Value === true) {
			for (const item of options) {
				selectedItems.push(item);
			}
		}

		//Force the multiselect to update the model. The select all wasn't syncing
		input.updateModel(selectedItems);
	}

	//Get the current window size
	public Window_GetCurrentSize(event: UIEvent | null): number {

		//Check if event is null, if so, use the current screen width.
		let windowLength = 768;
		if (event === null) {
			windowLength = window.innerWidth;
		}
		else {
			const eventTarget = event.target as Window;
			windowLength = eventTarget.innerWidth
		}

		return windowLength;
	}

	//Min window sizes for comparison
	public static MinSizeSM = 768;
	public static MinSizeMD = 992;
	public static MinSizeLG = 1200;

	//Check if the current window size is greater than XS
	public Window_IsGreaterThanSize(event: UIEvent | null, compareSize: number): boolean {

		//Let's check the window width, and then flip some display switches as needed.
		//Here are the current bootstrap responsize sizes. we could chuck this in a global static class, if we like.
		// xs (for phones - screens less than 768px wide)
		// sm (for tablets - screens equal to or greater than 768px wide)
		// md (for small laptops - screens equal to or greater than 992px wide)
		// lg (for laptops and desktops - screens equal to or greater than 1200px wide)


		if (this.Window_GetCurrentSize(event) >= compareSize) {
			return true;
		}
		else {
			return false;
		}
	}

	//Simple way to check if two arrays are equal. its a little compute heavy, so try to use this one on small arrays.
	public ArraysEqualStringify(a1, a2) {
		//WARNING: arrays must not contain {objects} or behavior may be undefined
		//stringify the entire array. this can be expensive!
		return JSON.stringify(a1) == JSON.stringify(a2);
	}

	//Show/hide tool tip based on the size and the max limit
	public ValueWithinLimit_Check(value, limit): boolean {
		if (!this.isEmpty(value)) {
			if (value.length > limit) {
				return false;
			}
		}

		return true;
	}
}

@Injectable({
	providedIn: "root",
})
export class CsvDataService {
	constructor(
		private notifyService: NotifyService,
		private globalFunction: GlobalFunctions
	) {
	}
	exportToCsv(filename: string, rows: object[]) {
		if (!rows || (rows.length === 0)) {
			return;
		}
		const separator = ',';
		const keys = Object.keys(rows[0]);
		const csvContent =
			keys.join(separator) +
			'\n' +
			rows.map(row => {
				return keys.map(k => {
					let cell = row[k] === null || row[k] === undefined ? '' : row[k];
					cell = cell instanceof Date
						? cell.toLocaleString()
						: cell.toString().replace(/"/g, '""');
					if (cell.search(/("|,|\n)/g) >= 0) {
						cell = `"${cell}"`;
					}

					//Add double quote for cells that have value starting with 0
					if (!this.globalFunction.isEmpty(cell) && !isNaN(cell)) {
						if (cell.length > 0 && cell.substring(0, 1) === "0") {
							cell = `"${cell}"`;
						}
					}
					return cell;
				}).join(separator);
			}).join('\n');

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

		const link = document.createElement('a');

		//Add timestamp to the file name
		filename = filename + "_" + this.globalFunction.FileTimeStamp_Get() + ".csv";
		if (link.download !== undefined) {
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', filename);
			link.style.visibility = '';
			link.download = filename;
			//window.open(url);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.notifyService.Success_Show("Saved Successfully", "Check your Downloads folder for " + filename)
		}
	}
}

//Class to store the feature modal and some other reference data
export class FeatureModal {
	public Name;
	public DialogRef;
	public IsVisible = false;
	public RefAccountID;
	public GUID;
	public LeftPosition;

	//To indicate whether this modal can be minimized or not
	public IsMinimizableDialog = false;
	public IsMinimized = false;

	//This constructor makes it easy for us to create the object with a minimal set of properties (partial construction)
	public constructor(init?: Partial<FeatureModal>) {
		Object.assign(this, init);
	}
}

