<div #AccountSummaryDetailModal id="AccountSummaryDetailID" class="glb_boxedPadding glb_formBGColor text-white glb_Font">

  <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
      <button id="BTN_CloseSLADrillthrough" tabindex="3"
        class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
        (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
        <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
      </button>
    </div>
  </div>

  <div class="glb_Font header-padding glb_customFlexRow">
    <div class="header-padding glb_customFlexRow col-12 glb_paddingZeroOffset">
      <div class="col-8 glb_paddingZeroOffset">
        <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
          Account Summary Configuration (Default)
        </div>
      </div>
    </div>
  </div>

  <div class="glb_boxedPadding glb_customFlexRow col-12">
    <div class="glb_boxedPadding glb_customFlexRow col-6">
      <div class="col-12" *ngFor="let sectionGroup of AccountSummaryGroups_Get()">
        <div class="drag-container">
          <div class="col-12 glb_customFlexRow align-items-center glb_PaddingHorizontalXXS">
            <div class="glb_FontSizeSummary col-11 ">{{sectionGroup.Name}}

            </div>
            <div class="glb_customFlexRow justify-content-end col-1">
              <button class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline text-white"
                (click)="RemoveSection_Click(sectionGroup.GroupGUID)">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Remove">
                </fa-icon>
              </button>
            </div>
          </div>

          <div cdkDropList id="{{sectionGroup.GroupGUID}}" [cdkDropListData]="sectionGroup.DetailGUIDs"
            [cdkDropListConnectedTo]="ConnectedToAccountSummaryGroups" class="item-list glb_allBordersGreyRoundedSM"
            (cdkDropListDropped)="drop($event)">
            <div class="glb_boxedPadding glb_customFlexRow">
              <div class="item-box glb_LabelFontDark col-12" *ngFor="let du of sectionGroup.DetailGUIDs" cdkDrag cdkDragDisabled="false">
                {{DataUnitName_Get(du)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="glb_boxedPadding glb_customFlexRow col-6">
      <div class="col-12" *ngFor="let duGroup of DataUnitGroups_Get()">
        <div class="drag-container">
          <div class="col-12 glb_customFlexRow align-items-center glb_PaddingHorizontalXXS">
            <div class="glb_FontSizeSummary col-11 ">Available Data Units

            </div>
            <div class="glb_customFlexRow justify-content-end col-1">
              <button class="btn glb_clickableIconButtonStyleNone glb_skinLightOutline text-white">
                <fa-icon class="fa-fw glb_fixedSizeIcons glb_iconColorTransparent" [icon]="['fal', 'trash']" matTooltip=""></fa-icon>
              </button>
            </div>
          </div>

          <div cdkDropList id="{{duGroup.GroupGUID}}" [cdkDropListData]="duGroup.DetailGUIDs"
            [cdkDropListConnectedTo]="ConnectedToAccountSummaryGroups" class="item-list glb_allBordersGreyRoundedSM"
            (cdkDropListDropped)="drop($event)">
            <div class="glb_boxedPadding glb_customFlexRow">
              <div class="item-box glb_LabelFontDark col-12" *ngFor="let du of duGroup.DetailGUIDs" cdkDrag>{{DataUnitName_Get(du)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="glb_customFlexRow col-12 row-cols-1 justify-content-center align-items-center glb_paddingBottomXS">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 glb_PaddingHorizontalXS  d-grid">
      <button id="BTN_SummarySetup" tabindex="1" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
        (click)="AddNewSection_Click()">
        <span class="glb_Font glb_smallerText">Add New Section</span>
      </button>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 glb_PaddingHorizontalXS d-grid">
      <button id="BTN_SummarySetup" tabindex="1" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
        (click)="SummarySetup_Click()">
        <span class="glb_Font glb_smallerText">Save</span>
      </button>
    </div>
  </div>

</div>