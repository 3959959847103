import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ApiService } from '@app/Services/APIService';
import { GlobalFunctions, CsvDataService } from '@app/Global/GlobalFunctions';
import { Subscription } from 'rxjs';
import { DataUnit, Entity, DataRow } from '@app/Global/Models/EntityModels';
import { NotifyService } from '@app/Services/NotifyService';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { MatDialog } from '@angular/material/dialog';
import { AccountsControllerMethods, NavigationUrls, RequestTypes } from '@app/Global/EnumManager';
import { Router, ActivatedRoute } from '@angular/router';
import { LoanEntity } from '@app/Components/Loan/LoanEntity/LoanEntity';
import { animate, style, transition, trigger } from '@angular/animations';
import { ClientDataStore, StoreItemTypes } from '@app/Global/ClientDataStore';
import { LoanEntityModify } from '@app/Components/Loan/LoanEntityModify/LoanEntityModify';
import { DocViewer } from '@app/Components/Loan/DocViewer/DocViewer';
import moment from 'moment';
import { LoanHeader } from '@app/Components/Loan/LoanHeader/LoanHeader';
import { SearchBar } from '@app/Components/Loan/SearchBar/SearchBar';
import { TaskQualityReview } from '@app/Components/Loan/TaskQualityReview/TaskQualityReview';
import { CreateEmail } from '@app/Components/Loan/CreateEmail/CreateEmail';
import { CreateEmailData, SideBarButton } from '@app/Global/Models/ClientModels';
import { FinaliseTask as FinaliseTask } from '@app/Components/Loan/FinaliseTask/FinaliseTask';
import { TaskFollowup } from '@app/Components/Loan/TaskFollowup/TaskFollowup';
import { LeasePayoutCalculator } from '@app/Components/Loan/LeasePayoutCalculator/LeasePayoutCalculator';
import { PayoutCalculator } from '@app/Components/Loan/PayoutCalculator/PayoutCalculator';
import { AccountSummary } from '@app/Components/Loan/AccountSummary/AccountSummary';
import { DocumentGenerator } from '@app/Components/Loan/DocumentGenerator/DocumentGenerator';
import { StatementGenerator } from '@app/Components/Loan/StatementGenerator/StatementGenerator';
import { ResidentialRepaymentCalculator } from '@app/Components/Loan/ResidentialRepaymentCalculator/ResidentialRepaymentCalculator';

@Component({
	selector: 'LoanIndex',
	templateUrl: './LoanIndex.html',
	styleUrls: ['./LoanIndex.scss'],
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: '0' }),
				animate('0.1s ease-out', style({ opacity: '1' })),
			]),
		]),
		trigger('fadeOut', [
			transition(':leave', [
				style({ opacity: '1' }),
				animate('0.1s ease-out', style({ opacity: '0' })),
			]),
		]),
	]
})
export class LoanIndex implements OnInit, OnDestroy {
	//The currently selected account and its details
	AccountID: string;
	SelectedAccount;

	//To track whether the loan is going to be loaded from facility screen
	public LinkedAccountClicked = false;

	//Store a dictionary that contains all the entity arrays, their display names, etc. this makes it easier for the html template to access objects inside them and do their thing.
	EntityDict: { [key: string]: any };

	//This lets us access a collection of child components inside this parent. these are the Loan Entities scripts, whose data is actually stored in the EntityDict above. TODO - EntityDict data could potentially be migrated down to the LoanEntityScripts
	@ViewChildren('LoanEntities', { read: LoanEntity }) public LoanEntityScripts: QueryList<LoanEntity>;

	//Loan Header
	@ViewChild('LoanHeader') LoanHeader: LoanHeader;

	//The material sidebar, we want a reference so that the button can toggle its visiblity
	@ViewChild('SB_Drawer') SBDrawer;

	//Store control data, view model and entity types
	ControlData = [];
	ClientViewModelData = [];
	ClientEntityTypes = [];

	//For loading the single/headless version of the Entity Update component
	public SingleEntityModalIdentifier;

	//Currently selected datarow in the modify template/modal, used for editing objects via the Modal
	DataRowModal: DataRow;

	//RXJS Subscriptions
	routeSubscription: Subscription;
	accountSubscription: Subscription;

	//Control GUIDs
	ConditionSubsequentControlGUID = "{b3085471-e68f-4bc4-a80f-d1b6e3b36d8a}";
	TaskStatusCompleteControlGUID = "{6d702449-2d99-4f67-b7a1-206663b19008}";

	//Control GUID for Association Type = Portfolio/Fund
	public PortfolioAssociationTypeGUID = "{41dd364c-d1b2-4689-8e20-a4a31ff49394}";

	//SearchBar Component
	public SearchBarComponent: SearchBar;

	//SideBar Button ID prefix
	public SideBarButtonIDPrefix = "BTN_Sidebar";

	//Account view type option: summary or details
	public ViewTypeOptions: any[] = [{ label: 'Summary', value: '0' }, { label: 'Details', value: '1' }];

	//TODO: Set it to 0 (Summary) later. Currently defaults to Details
	public ViewTypeValue = "1";

	//Flag to show/hide the ViewType selector
	public ShowViewTypeOption = false;

	//Loan Header
	@ViewChild('AccountSummary') AccountSummary: AccountSummary;

	constructor(
		private apiService: ApiService
		, private globalFunctions: GlobalFunctions = null
		//must have it
		, private notifyService: NotifyService
		//needed for delete requests
		, private dialog: MatDialog
		//must have it
		, public store: ClientDataStore
		//these two only needed on standard usage, redirects to search page if its an invalid loan.
		, private router: Router = null
		, private route: ActivatedRoute = null
		//optional
		, private csvDataService: CsvDataService = null
	) {
		this.firstNavItem = "";

		if (this.firstNavItem != this.store.EntityTypesDisplay[0].Name) {
			this.firstNavItem = this.store.EntityTypesDisplay[0].Name;
			this.CurrentNavItem = 'nav-' + this.firstNavItem + '-tab';
		}
	}

	//Array of Sidebar buttons
	public SideBarButtons = [];

	//The sidebar visibility toggler, this one is declared statically outside the SideBarButtons array, as it is rendered in a different location.
	public SidebarToggleVisibility = new SideBarButton({
		ID: this.SideBarButtonIDPrefix + "ToggleVisibility", Icon: "bars", DisplayText: "", ShowText: false, Tooltip: "Toggle the Side Bar"
		, ClickAction: () => this.SideBarButtons_Toggle()
	});

	//Flag to hide the inner contents of the button
	public SideBarButtonToggleState = true;

	//Method to hide sidebar buttons
	public SideBarButtons_Toggle() {

		//Loop through each sidebar button and disable its state. We are doing this so that the icon and text disappear before sliding away. It looks silly to leave it while sliding on a transparent background.
		this.SideBarButtons.forEach(sideBarButton => {
			sideBarButton.IsSideBarVisible = !this.SideBarButtonToggleState;
		});

		//Now slide it away
		this.SBDrawer.toggle();

		//Flip the state of the side bar visiblity
		this.SideBarButtonToggleState = !this.SideBarButtonToggleState;
	}

	//Action when a viewtype option is selected
	public ViewTypeOption_Click(event): void {

		//Set the view type value
		this.ViewTypeValue = event.value;
	}

	//To get the CSS to show/hide the element
	public DisplayCSS_Get(isSummary = true): string {

		let cssString = "";

		//Hide summary if the ViewType selection is Details
		if (isSummary && this.ViewTypeValue === '1') {
			cssString = "glb_hiddenObjectImmediate";
		}

		//Hide details if the ViewType selection is Summary
		else if (!isSummary && this.ViewTypeValue === '0') {
			cssString = "glb_hiddenObjectImmediate";
		}

		return cssString;
	}

	//Init Sidebar button configs - so we don't have to repeat html. We can also delegate the function to call upon button clicks here. These are passed as objects to ng-container
	public SidebarButtons_Init(): void {

		//Clear and reinit array to that it is in the right order!!
		this.SideBarButtons.length = 0;

		//Account enquiry needs a claim check, + a method to check if assocs are loaded (ShowSpinner)
		if (this.globalFunctions.Claim_VerifyPermission(this.store, "AccountEnquiries", "Read") === true) {
			this.SideBarButtons.push(
				new SideBarButton({
					ID: this.SideBarButtonIDPrefix + "AccountEnquiry", Icon: "clipboard-question", DisplayText: "Enquiry", Tooltip: "Create an Enquiry"
					, IsSideBarVisible: this.SideBarButtonToggleState
					, ClickAction: () => this.LoanHeader.AccountEnquiry()
					, Spinner_Show: () => this.CheckAssocs_IsLoaded()
				})
			);
		}

		//Adding Journal notes, with an entity insert claim check
		if (this.GetEntityClaim('Note').Insert === true) {
			this.SideBarButtons.push(
				new SideBarButton({
					ID: this.SideBarButtonIDPrefix + "JournalUpload", Icon: "note", DisplayText: "Note", Tooltip: "Add a Journal Note"
					, IsSideBarVisible: this.SideBarButtonToggleState
					, ClickAction: () =>
						this.Entity_OpenModifyTemplate(null, '', 'LoanNotes', null, null, null, null, null, "Added to the Notes tab.")
				})
			);
		}

		//Uploading Documents, with an entity insert claim check
		if (this.GetEntityClaim('Document').Insert === true) {
			this.SideBarButtons.push(
				new SideBarButton({
					ID: this.SideBarButtonIDPrefix + "DocumentUpload", Icon: "file-arrow-up", DisplayText: "Doc Upload", Tooltip: "Upload a Document"
					, IsSideBarVisible: this.SideBarButtonToggleState
					, ClickAction: () => this.Entity_OpenModifyTemplate(null, '', 'LoanDocuments', null, null, null, null, null, "Added to the Documents tab.")
				})
			);
		}

		//Document Generator with claim check
		if (this.globalFunctions.Claim_VerifyPermission(this.store, "DocumentGenerator", "Read") === true) {
			this.SideBarButtons.push(
				new SideBarButton({
					ID: this.SideBarButtonIDPrefix + "DocumentGenerator", Icon: "file-plus", DisplayText: "Doc Template", Tooltip: "Generate a Document"
					, IsSideBarVisible: this.SideBarButtonToggleState
					, ClickAction: () => {
						this.DocumentGenerator_Launch();
					}
				})
			);
		}

		//Statement Generator with claim check
		if (this.globalFunctions.Claim_VerifyPermission(this.store, "StatementGenerator", "Read") === true) {
			this.SideBarButtons.push(
				new SideBarButton({
					ID: this.SideBarButtonIDPrefix + "StatementGenerator", Icon: "book", DisplayText: "Statement", Tooltip: "Generate a Statement"
					, IsSideBarVisible: this.SideBarButtonToggleState
					, ClickAction: () => {
						this.StatementGenerator_Launch();
					}
				})
			);
		}

		//Check the account product type to show Resi or Lease payout calculator in the side bar
		if (this.AccountProductType === "Lease") {

			//Lease Payout Calculator Generator with claim check
			if (this.globalFunctions.Claim_VerifyPermission(this.store, "LeasePayoutCalculator", "Read") === true) {

				//Sync Payout Buttons
				this.PayoutCalcButtonDisplay_Sync();

				//Add the button
				this.SideBarButtons.push(
					new SideBarButton({
						ID: this.SideBarButtonIDPrefix + "LeasePayoutCalculator", Icon: "garage-car", DisplayText: "Lease Payout", Tooltip: "Launch the Lease Payout Calculator"
						, IsSideBarVisible: this.SideBarButtonToggleState
						, ClickAction: () => {
							this.LeasePayout_Launch();
						}
					})
				);
			}
		}
		//ProductType & ProductClass are loaded via the account details entity, and therefore comes in after a delay. Show the button for loan product type with class Residential
		else if (this.AccountProductType !== "Lease" && this.AccountProductClass === "Residential") {

			//Payout Calculator Generator with claim check
			if (this.globalFunctions.Claim_VerifyPermission(this.store, "PayoutCalculator", "Read") === true) {

				//Sync Payout Buttons
				this.PayoutCalcButtonDisplay_Sync();

				//Add the button
				this.SideBarButtons.push(
					new SideBarButton({
						ID: this.SideBarButtonIDPrefix + "PayoutCalculator", Icon: "sack-dollar", DisplayText: "Residential Payout", Tooltip: "Launch the Resi Payout Calculator"
						, IsSideBarVisible: this.SideBarButtonToggleState
						, ClickAction: () => {
							this.ResiPayout_Launch();
						}
					})
				);
			}

			//Don't display repayment calculation on offset, prepaid and retention accounts
			if (this.AccountProductType.toUpperCase() !== "OFFSET" && this.AccountProductType.toUpperCase() !== "PREPAID" && this.AccountProductType.toUpperCase() !== "RETENTION") {
				//Residential Repayment Calculator Generator with claim check
				if (this.globalFunctions.Claim_VerifyPermission(this.store, "ResidentialRepaymentCalculator", "Read") === true) {

					//Add the button
					this.SideBarButtons.push(
						new SideBarButton({
							ID: this.SideBarButtonIDPrefix + "ResidentialRepaymentCalculator", Icon: "calculator", DisplayText: "Residential Repayment Calc", Tooltip: "Launch the Resi Repayment Calculator"
							, IsSideBarVisible: this.SideBarButtonToggleState
							, ClickAction: () => {
								this.ResiRepaymentCalc_Launch();
							}
						})
					);
				}
			}
		}
	}

	//Launch the Generate Statement component
	public StatementGenerator_Launch() {

		//Launch the component of this modal
		this.globalFunctions.FeatureModal_Launch(StatementGenerator, this.globalFunctions.GetFeatureModalConfig('60%', false, false, "glb_fixedHeightDialog"), this.dialog, "Statement Generator", this.AccountID, false, true);
	}

	//Launch the Generate Document component
	public DocumentGenerator_Launch() {

		//Get the component of this modal, and set a property on it. looks like it is stored in statementModalRef.componentInstance
		const documentTemplateModalRef = this.globalFunctions.FeatureModal_Launch(DocumentGenerator, this.globalFunctions.GetFeatureModalConfig('60%'), this.dialog, "Document Generator", this.AccountID, false, true);

		documentTemplateModalRef.DialogRef.componentInstance.LoanIndex = this;
	}

	//Launch Lease Payout Calculator component
	public LeasePayout_Launch(): void {

		//Get the component of this modal, and set a property on it
		const payoutModalRef = this.globalFunctions.FeatureModal_Launch(LeasePayoutCalculator, this.globalFunctions.GetFeatureModalConfig('95%', false, false, "glb_fixedHeightDialog"), this.dialog, "Lease Payout", this.AccountID, false, true);

		payoutModalRef.DialogRef.componentInstance.LoanIndex = this;
	}

	//Launch the Payout Calculator component
	public ResiPayout_Launch(): void {

		//Get the component of this modal, and set a property on it. looks like it is stored in statementModalRef.componentInstance
		const payoutModalRef = this.globalFunctions.FeatureModal_Launch(PayoutCalculator, this.globalFunctions.GetFeatureModalConfig('90%', false, false, "glb_fixedHeightDialog"), this.dialog, "Resi Payout", this.AccountID, false, true);

		payoutModalRef.DialogRef.componentInstance.LoanIndex = this;
	}

	//Launch the Residential Repayment Calculator component
	public ResiRepaymentCalc_Launch(): void {

		//Invoke API to get the loan details to pre populate repayment calc data
		//Turn on the fullscreen loading
		this.store.SetShowFullscreenLoading(true);

		//Construct the request and send it to the server
		const apiRequest = { AccountID: this.AccountID };

		this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetResiRepaymentCalcData], apiRequest)
			.subscribe(apiResponse => {
				if (this.globalFunctions.isEmpty(apiResponse)) {
					//There was no response, or an error.

					this.notifyService.Warning_Show("Something went wrong while loading a repayment calculator. Please contact XV8 support team.", "Warning");

					//Turn off the fullscreen loading
					this.store.SetShowFullscreenLoading(false);

					return;
				}
				else {
					//Get the response, and try to display it in the document viewer
					const response = JSON.parse(JSON.stringify(apiResponse));

					//Get the component of this modal, and set a property on it
					const repaymentCalculatorModal = this.globalFunctions.FeatureModal_Launch(ResidentialRepaymentCalculator, this.globalFunctions.GetFeatureModalConfig('90%', false, false, "glb_fixedHeightDialog"), this.dialog, "Repayment Calculator", this.AccountID, false, true);

					//Get the modal dialog ref
					const repaymentCalculatorModalRef = repaymentCalculatorModal.DialogRef;

					repaymentCalculatorModalRef.componentInstance.LoanIndex = this;
					repaymentCalculatorModalRef.componentInstance.TargetAccountID = response.AccountID;

					//Format as long dates
					repaymentCalculatorModalRef.componentInstance.SettlementDate = this.globalFunctions.getCustomDateFormat(response.SettlementDate, "shortdate", "custom", "YYYY-MM-DD");
					repaymentCalculatorModalRef.componentInstance.OriginalMaturityDate = this.globalFunctions.getCustomDateFormat(response.MaturityDate, "shortdate", "custom", "YYYY-MM-DD");
					repaymentCalculatorModalRef.componentInstance.MaturityDate = repaymentCalculatorModalRef.componentInstance.EffectiveDate = this.globalFunctions.customDataTypeParser(response.MaturityDate, "longdatetime");
					repaymentCalculatorModalRef.componentInstance.EffectiveDate = this.globalFunctions.customDataTypeParser(response.EffectiveDate, "longdatetime");

					//Format as rate
					repaymentCalculatorModalRef.componentInstance.OriginalEffectiveRate = this.globalFunctions.customDataTypeParser(response.EffectiveRate, 'percent');
					repaymentCalculatorModalRef.componentInstance.EffectiveRate = response.EffectiveRate;

					//Format as currency
					repaymentCalculatorModalRef.componentInstance.Balance = response.Balance;
					repaymentCalculatorModalRef.componentInstance.RepaymentAmountMonthlyUnformatted = response.MonthlyRepayment;
					repaymentCalculatorModalRef.componentInstance.CurrentRepaymentAmount = this.globalFunctions.customDataTypeParser(response.CurrentInstalmentAmount, 'currency.2');
					repaymentCalculatorModalRef.componentInstance.RepaymentAmountMonthly = this.globalFunctions.customDataTypeParser(response.MonthlyRepayment, 'currency.2');
					repaymentCalculatorModalRef.componentInstance.RepaymentAmountFortnightly = this.globalFunctions.customDataTypeParser(response.FortnightlyRepayment, 'currency.2');
					repaymentCalculatorModalRef.componentInstance.RepaymentAmountWeekly = this.globalFunctions.customDataTypeParser(response.WeeklyRepayment, 'currency.2');
					repaymentCalculatorModalRef.componentInstance.TotalInterestChargedDisplay = this.globalFunctions.customDataTypeParser(response.TotalInterestCharged, "currency.2", "aus");
					repaymentCalculatorModalRef.componentInstance.TotalRepaymentsDisplay = this.globalFunctions.customDataTypeParser(response.TotalRepayment, "currency.2", "aus");

					//Format as integer
					repaymentCalculatorModalRef.componentInstance.TermInMonths = this.globalFunctions.customDataTypeParser(response.TermInMonths, 'integer');
					repaymentCalculatorModalRef.componentInstance.CurrentRemainingTermsInMonths = this.globalFunctions.customDataTypeParser(response.RemainingTermInMonths, 'integer');

					//String input
					repaymentCalculatorModalRef.componentInstance.OriginalPaymentType = this.globalFunctions.customDataTypeParser(response.PaymentType, 'string');
					repaymentCalculatorModalRef.componentInstance.BalanceType = this.globalFunctions.customDataTypeParser(response.BalanceType, 'string');
					repaymentCalculatorModalRef.componentInstance.ProductName = this.globalFunctions.customDataTypeParser(response.ProductName, 'string');
					repaymentCalculatorModalRef.componentInstance.CurrentRemainingTermsInMonthsDisplay = this.globalFunctions.customDataTypeParser(response.RemainingTermDisplay, 'string');

					//Amort Data
					repaymentCalculatorModalRef.componentInstance.AmortData = response.ChartAmortDataPerYear;

					//Turn off the fullscreen loading
					this.store.SetShowFullscreenLoading(false);

					return;
				}
			});
	}

	//Load Entity data on click event
	public EntityData_Load(eventTargetID: string, entityName: string): void {
		if (eventTargetID === 'nav-' + entityName + '-tab') {
			//Check if it has already started its initial load before
			if (this.globalFunctions.isEmpty(this.EntityDict[entityName].InitialLoad) || this.EntityDict[entityName].InitialLoad === false) {
				if (entityName === 'CustomFields') {
					//Load all custom fields data eg: String, Numeric, Currency, Dates
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['CustomFieldStrings'].Entity, 'CustomFieldStrings');
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['CustomFieldNumeric'].Entity, 'CustomFieldNumeric');
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['CustomFieldDates'].Entity, 'CustomFieldDates');
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['CustomFieldCurrency'].Entity, 'CustomFieldCurrency');
					this.EntityDict[entityName].InitialLoad = true;
				}
				else if (entityName === 'Securities') {
					//Load all security data eg: Properties and Vehicles
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['LoanSecurities'].Entity, 'LoanSecurities');
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['LoanVehicleSecurities'].Entity, 'LoanVehicleSecurities');
					this.EntityDict[entityName].InitialLoad = true;
				}
				else if (entityName === 'Facility') {
					//Load all Facility nav tab data eg: Facility Balances, LVR, Linked accounts
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['FacilityBalances'].Entity, 'FacilityBalances');
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['FacilityLVR'].Entity, 'FacilityLVR');
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['LinkedAccounts'].Entity, 'LinkedAccounts');
					this.EntityDict[entityName].InitialLoad = true;
				}
				else {
					if (entityName === 'Arrears') {
						//Load the Arrears Breakdown in addition to arrears, as they are on the same nav tab
						this.Loan_GetAllEntityData(this.AccountID, this.EntityDict['ArrearsBreakdown'].Entity, 'ArrearsBreakdown');
					}
					//Load the entity
					this.Loan_GetAllEntityData(this.AccountID, this.EntityDict[entityName].Entity, entityName);
				}
			}
		}
	}

	//We can't show account enquiry until assocs are loaded. This is used for the sidebar account enquiry button
	public CheckAssocs_IsLoaded() {
		//Lookup the assoc entity, and see if it is loaded
		if (this.EntityDict["LoanAssociations"].Spinner == 1) {
			return false;
		}

		//Otherwise, it is loaded
		return true;
	}

	//Name of the first nav tab to activate on entry to loan index
	firstNavItem: string;

	//Name of the currently displayed nav tab
	CurrentNavItem: string;

	//Checks the name of the supplied entity, and supplies the css to fade IN the content (make it visible with a short fade in animation using css keyframes)
	public ShowCurrentNavItem(entityName: string): string {
		if ('nav-' + entityName + '-tab' === this.CurrentNavItem) {
			//If we are showing the content, supply the css keyframes that fade it in
			return 'glb_keyFrameFadeIn';
		}
		return '';
	}

	//Checks the name of the supplied entity, and supplies the css to just REMOVE the content for unmatching items. Used on parent items to completely remove it from rendering (display: none)
	public CurrentNavItemForceRemoval(entityName: string): string {
		if ('nav-' + entityName + '-tab' !== this.CurrentNavItem)
			return 'glb_hiddenObjectImmediate';
		else return '';
	}

	//Is the redraw on this account frozen?
	public RedrawFrozen = false;

	//Type of the product linked to the account
	public AccountProductType = "";

	//Product class linked to the account
	public AccountProductClass = "";

	//Show Collection Status label
	public ShowCollectionStatusLabel = false;

	//List of Hardship Collection Statuses
	public HardshipCollectionStatuses = ["Hardship Requested", "Hardship Approved", "Financial Hardship"];

	//Highlight arrears loan status
	public LoanInArrears = false;

	//Highlight Pending Loan Discharge loan status
	public LoanInPendingDischarge = false;

	//Highlight loan status
	public ShowAnticipatedDischargeDate = false;

	ngOnInit(): void {
		//Init the entity dictionairy for this account
		this.InitEntityDict();

		//Get control data from the client cache
		this.StaticData_GetAndCache();

		//Let's init the sidebar buttons here
		this.SidebarButtons_Init();

		this.routeSubscription = this.route.paramMap.subscribe(() => {
			//Nothing requred here now. the loan is an observable, just subscribe to its value on the components that need it
			//But, in case there is no active loan, return the user to the search screen. perhaps a short delay might be appropriate here too?
			this.globalFunctions.delay(500).then(() => {
				//Run after a small delay.
				if (this.SelectedAccount == null) {
					this.notifyService.Warning_Show("No Account found, returning you to the search page", "Returning to search page")
					this.router.navigate([NavigationUrls.AccountSearch]);
				}
			});
		})

		//This subscription ensure that we get the latest loan that was clicked on via the search screen.
		this.accountSubscription = this.store.SelectedAccount.subscribe((account) => {
			if (this.LinkedAccountClicked) {
				//Do not load the different loan on the same loan index
				return;
			}

			if (account != null) {
				this.Account_Load(account);
			}
		});

		//Check if the user has access to ViewAccountSummary claim
		const viewAccountSummaryClaimName = "ViewAccountSummary";
		if (this.globalFunctions.Claim_VerifyPermission(this.store, viewAccountSummaryClaimName, "Read") === true) {

			//Show view type option selector
			this.ShowViewTypeOption = true;

			//Now, lets check if we have a default landing page config
			const viewAccountSummaryClaim = this.store.ClientClaims.filter(x => x.Name == viewAccountSummaryClaimName)[0];

			//Check the value to see if we land the user to summary or details
			if (!this.globalFunctions.isEmpty(viewAccountSummaryClaim) && !this.globalFunctions.isEmpty(viewAccountSummaryClaim.Value)) {
				if (viewAccountSummaryClaim.Value.includes("Summary") === true) {
					this.ViewTypeValue = "0";
				}
			}
		}
	}

	ngOnDestroy(): void {
		if (this.routeSubscription != null) {
			this.routeSubscription.unsubscribe();
		}
		if (this.accountSubscription != null) {
			this.accountSubscription.unsubscribe();
		}

		//Initialise the minimized dialogs to destroy the minimized windows linked to this account, if there are any
		this.globalFunctions.MinimizedDialogs_Reset();
	}

	//store a script for doing callback type actions from headlessmode
	public invokingParent;

	//for when we want to run this headless, let's have an init method that preps it just for loading the speficic entity that we care about
	public HeadlessModeInit(loanID: string, entityName: string, dataRowGUID: string, invokingParent) {
		//set the loanID
		this.AccountID = loanID;
		//might as well init the entire entity dict (later we could just init the one that we passed)
		this.InitEntityDict();
		//get control data from the client cache. we don't need to block and wait for this to complete, as the login has already cached it. so just grab the cached versions from clientdatastore (pass true here)
		this.StaticData_GetAndCache(true);

		this.invokingParent = invokingParent;
		//typically we want to store a specific entity, and a single DataRow. use entityName and dataRowGUID, pass that to the server request
		//let's init the array for this entity, so that it can be processed into it as per usual
		const value = this.EntityDict[entityName];
		//reset the array
		value.Entity = [];

		//this is the request data that we will pass down to Loan_GetAllEntityData later
		const customRequest = { accountID: this.AccountID, entity: value.Entity, entityName: entityName, dataRowGUID: dataRowGUID, loadEditModal: true, customEntityHeaderName: '' };

		//we also need the summary information about this loan, what is usually shown in this.SelectedAccount. like principal borrower, account status and balance. specifically this.SelectedAccount.IsParent as that is used later when inserting some entities (child notes and docs to user tasks)
		//lets make a specific call to the server for it. e.g. GetAccountSummary. make its return data that is identical to AccountSearch. this is reponsible for chaining the call to Loan_GetAllEntityData afterward
		this.GetAccountSummary(true, customRequest);
	}

	public entityHeaderAddtional = '';

	//Sometimes we will need to manually grab the loan summary info. e.g. when we load up an account in headless mode, via a click from the user tasking dashboard
	public GetAccountSummary(loadEditModal = false, customRequest = null) {

		const endpoint = AccountsControllerMethods[AccountsControllerMethods.GetAccountSummary];
		const apiRequest = { AccountID: this.AccountID }

		this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, endpoint, apiRequest)
			.subscribe(apiResponse => {

				//Parse the response. should also check for error
				if (!this.globalFunctions.isEmpty(apiResponse)) {
					//console.log('apiResponse', apiResponse);

					if (apiResponse.length === 1) {

						//Load this into the selected account
						this.SelectedAccount = apiResponse[0];

						//Check and show the Redraw Frozen label if needed
						if (this.SelectedAccount.RedrawFrozen === true) {
							this.RedrawFrozen = true;
						}

						//Check if the collection status is empty
						if (!this.globalFunctions.isEmpty(this.SelectedAccount.CollectionStatus)) {

							//Here we are checking if the collection is in the list of Hardship types
							const hardshipCollectionStatus = this.HardshipCollectionStatuses.filter(x => x === this.SelectedAccount.CollectionStatus)[0];
							if (!this.globalFunctions.isEmpty(hardshipCollectionStatus)) {

								//It's hardship type, lets show the label
								this.ShowCollectionStatusLabel = true;

								//Unescape the collection status value
								this.SelectedAccount.CollectionStatus = this.globalFunctions.HTMLUnescape(this.SelectedAccount.CollectionStatus);
							}
						}

						//Check if the loan status is empty
						if (!this.globalFunctions.isEmpty(this.SelectedAccount.LoanStatus)) {

							//Check if the loan status is of type "Arrears"
							if (this.SelectedAccount.LoanStatus.toUpperCase().includes("ARREARS") === true) {

								//We will highlight the status
								this.LoanInArrears = true;
							}

							//Check if the loan status is "Loan Pending Discharge"
							else if (this.SelectedAccount.LoanStatus.toUpperCase() === "LOAN PENDING DISCHARGE") {

								//We will highlight the pending discharge status
								this.LoanInPendingDischarge = true;
							}
						}

						//Check if the loan has Anticipated Discharge Date
						if (!this.globalFunctions.isEmpty(this.SelectedAccount.AnticipatedDischargeDate)) {

							//We will show the anticipated discharge date
							this.ShowAnticipatedDischargeDate = true;

							//Format the date
							this.SelectedAccount.AnticipatedDischargeDate = this.globalFunctions.getCustomDateFormat(this.SelectedAccount.AnticipatedDischargeDate, "shortdate", "custom", "YYYY-MM-DD");
						}

						//Set the account product type
						if (!this.globalFunctions.isEmpty(this.SelectedAccount.ProductType)) {
							this.AccountProductType = this.SelectedAccount.ProductType
						}

						//Set the product class
						if (!this.globalFunctions.isEmpty(this.SelectedAccount.ProductClass)) {
							this.AccountProductClass = this.SelectedAccount.ProductClass
						}

						//Check if the loan header is empty. This loan header is not loaded when visiting the loan task detail from dashboard
						if (!this.globalFunctions.isEmpty(this.LoanHeader)) {
							//Push the account summary to loan header
							this.LoanHeader.LoadAccount(this.SelectedAccount);
						}

						//Let's also update the entity name, if requested to do so
						if (loadEditModal) {
							//Modify the entity header name here
							customRequest.customEntityHeaderName = this.AccountID + ' (' + this.globalFunctions.HTMLUnescape(this.SelectedAccount.PrincipalBorrower) + ')';

							//We can use Loan_GetAllEntityData, and pass a GUID in the request to force a single row to be retrieved. passing the loadEditModal bool will let us also trigger the edit modal to be launched straight away.
							this.Loan_GetAllEntityData(customRequest.accountID, customRequest.entity, customRequest.entityName, customRequest.dataRowGUID, customRequest.loadEditModal, customRequest.customEntityHeaderName)
						}

						//Check if account summary is null. It won't exist for headless mode (e.g. SLA Reporting, Dashboard)
						if (!this.globalFunctions.isEmpty(this.AccountSummary)) {

							//Initialise Account Summary
							this.AccountSummary.Page_Init();
						}
						else {

							//Don't show the Summary/Details buttons
							this.ViewTypeValue = "1";
							this.ShowViewTypeOption = false;
						}

						//Initialise the sidebar
						this.SidebarButtons_Init();
					}
				}
			});
	}

	//initializes the Entity Dictionary that contains all the arrays and loan data
	public InitEntityDict() {
		//initialize an array of all the entities. could this be filled once, in a service? which could be provided by the server? yeah, sounds like an idea. for later. let's just put it in the dictionary here, for now. push display names, and also spinner starting state (0 = loading, 1 = not loading).
		//the DisplayName below are no longer used - LoanEntity will look it up at runtime and use the server navFriendlyName instead.
		this.EntityDict = {};
		this.EntityDict["AccountDetails"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LinkedAccounts"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["AccountSubDetails"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["ForeignKeys"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanRates"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["BankDetails"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanDates"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["CalculatedFields"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["AccountDataFields"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["Securities"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanSecurities"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanVehicleSecurities"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["PendingTasks"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanAssociations"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanBalances"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanTasks"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanPayments"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanTransfers"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanTransactions"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanDocuments"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanNotes"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["Arrears"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["ArrearsBreakdown"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["CustomFields"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["CustomFieldStrings"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["CustomFieldNumeric"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["CustomFieldDates"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["CustomFieldCurrency"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["LoanFeeSetup"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["FacilityBalances"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["FacilityLVR"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		//leave the spinner for this one off (value 1). we are going to load it when the user clicks on the tab.
		this.EntityDict["InterestAccruals"] = { Entity: [], DisplayName: "TBD", Spinner: 1, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
		this.EntityDict["Facility"] = { Entity: [], DisplayName: "TBD", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
	}

	//get static data from the client data store (loginmodal grabs it for us and puts it into the client data store). NOTE this is already done during login as well. this is here as a second check that is triggered every time a new loan is navigated to.
	public StaticData_GetAndCache(useLocalCacheOnly = false): void {
		//first, just grab whatever is in the client cache and set it. this is so that Headless mode can have it straight away, without needing to block and wait for its completion
		this.ControlData = this.store.ControlData;
		this.ClientViewModelData = this.store.ClientViewModelData;
		//a list of all the entity types can be set straight away, as the login endpoint should have retrieved that already
		this.ClientEntityTypes = this.store.EntityTypes;

		if (useLocalCacheOnly) {
			//don't bother checking and updating cache, just using what is available already
			return;
		}

		//let's double check the control data GUID and refresh it as we travel to each loan, as needed.
		this.CheckAndRetrieveControlData();
	}

	//checks local cache of control data, to see if it needs refreshing
	public CheckAndRetrieveControlData() {
		//console.log('Checking Static Control and View Model Data');
		//get and store the cache guid from cache. needed for the check routine inside GetControlDataCacheGUID.
		this.store.ControlDataCacheGUID = JSON.parse(localStorage.getItem(this.store.loginDataDirect.LoginID + StoreItemTypes[StoreItemTypes.GetControlDataCacheGUID]));
		//console.log('this.store.ControlDataCacheGUID', this.store.ControlDataCacheGUID);

		//now see if we need to retrieve them again.
		this.GetControlDataCacheGUID();
	}

	//checks the control data cache GUID from the server, refresh the contents if needed
	public GetControlDataCacheGUID() {
		this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StoreItemTypes[StoreItemTypes.GetControlDataCacheGUID])
			.subscribe(apiResponse => {
				if (this.globalFunctions.isEmpty(apiResponse)) {
					return;
				}
				else {
					//deserialize it into an class that we can understand
					const response = JSON.parse(JSON.stringify(apiResponse));
					//console.log('ControlDataCacheGUID from server', response);
					//console.log('this.clientDataStore.loginDataDirect.LoginID', this.store.loginDataDirect.LoginID);

					//are they different? if so, request the data from the server again.
					if (this.store.ControlDataCacheGUID !== this.store.loginDataDirect.LoginID + response) {
						//console.log('Control Data is different, refresh it from the server');
						//get the static data needed for the entity model
						this.GetControlDataWrapper();
						//let's make it specific for each user, inject user number here
						const combinedCacheGUID = this.store.loginDataDirect.LoginID + response
						//and store this new GUID into the data store
						this.store.ControlDataCacheGUID = combinedCacheGUID;
						//dont forget to store the actual ControlCacheGUID for use next time
						localStorage.setItem(this.store.loginDataDirect.LoginID + StoreItemTypes[StoreItemTypes.GetControlDataCacheGUID], JSON.stringify(combinedCacheGUID));
					}
					else {
						//console.log('Control Data is the same, grab it from cache');
						//data is identical. we must have the same control data. just reuse it. remember to use JSON.parse when pulling from the cache
						//control data is common, no need to use loginID in its name
						const controlData = JSON.parse(localStorage.getItem(StoreItemTypes[StoreItemTypes.GetControlData]));
						this.store.ControlData = controlData;
						//repeat for the view model too
						const clientViewModelData = JSON.parse(localStorage.getItem(this.store.loginDataDirect.LoginID + StoreItemTypes[StoreItemTypes.GetClientViewModel]));
						this.store.ClientViewModelData = clientViewModelData;

						//here we also want to set it on the class variable, so that loan index can do stuff with it
						//this contains the client side control data used for control type data units (in other words, dropdown selections)
						this.ControlData = this.store.ControlData;
						//this is the client view model (entities and their field names and data types)
						this.ClientViewModelData = this.store.ClientViewModelData;
					}
				}
			});
	}

	//wrapper for calling all control related data
	public GetControlDataWrapper() {
		//console.log('Refreshing Static Control and View Model Data from the server');
		//TODO this really needs to block the standard ngOnInit, as we must have control data to process datarows recieved from the server. Headless doesn't need to worry, as by the time headless mode is run, we should already have some data.
		//hmm. actually no, its not necessary for the client to have navigated to a loanIndex page to have cached it. damn.
		//let's also trigger and cache/redownload this on login. that should solve our problems.

		//let's grab the control data
		this.RequestDataFromServer(StoreItemTypes.GetControlData, this.store.ControlData);
		//client view model. by adding a dummy body property, it will send the access token. as we now want to evaluate role level entity property mods to the client view model.
		this.RequestDataFromServer(StoreItemTypes.GetClientViewModel, this.store.ClientViewModelData);
		//Entity Types was already retrieved earlier, during login itself   
	}

	//Sync display of resi and lease payout buttons on transactions
	private PayoutCalcButtonDisplay_Sync(): void {

		if (!this.globalFunctions.isEmpty(this.LoanEntityScripts)) {

			//Check for transaction component and sync lease payout button
			const transactionComponent = this.LoanEntityScripts.filter(component => component.EntityName == "LoanTransactions")[0];

			if (!this.globalFunctions.isEmpty(transactionComponent)) {

				//Sync the display of Payout Calculator buttons
				transactionComponent.PayoutCalculatorButton_Show();
			}
		}
	}

	//generic method to get some data from an endpoint, and push it into the supplied array. in this case, static data oriented endpoints. and puts it into local storage (cache)
	private RequestDataFromServer(endpointType: StoreItemTypes, dataToStore, requestData = null): void {
		//convert enum to string, expect that the enum also describes the route exactly
		const endpoint = StoreItemTypes[endpointType];
		this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, endpoint, requestData)
			.subscribe(apiResponse => {
				//parse the response. should also check for error
				if (!this.globalFunctions.isEmpty(apiResponse)) {
					dataToStore.length = 0;
					//loop through the responding apiResponse array and push the values in
					apiResponse.forEach(element => {
						//we need to unescape some values here
						const itemControlValue = element['ControlValue'];
						const itemControlDisplay = element['ControlDisplay'];

						//console.log('itemControlValue', itemControlValue);
						//console.log('itemControlDisplay', itemControlDisplay);

						//unescape the ControlValue and ControlDisplay elements, if they exist.
						if (!this.globalFunctions.isEmpty(itemControlValue)) {
							element['ControlValue'] = this.globalFunctions.HTMLUnescape(element['ControlValue']);
						}

						if (!this.globalFunctions.isEmpty(itemControlDisplay)) {
							element['ControlDisplay'] = this.globalFunctions.HTMLUnescape(element['ControlDisplay']);
						}

						dataToStore.push(element)
					});

					//save it into local storage, JSON stringify it as its an array of objects
					if (endpointType === StoreItemTypes.GetControlData) {
						//don't store the control data per user, as its identical.
						localStorage.setItem(endpoint, JSON.stringify(dataToStore));
					}
					else {
						localStorage.setItem(this.store.loginDataDirect.LoginID + endpoint, JSON.stringify(dataToStore));
					}

				}
			});
	}

	//filter control data based on some incoming input, client side only
	public FilterControlData(controlType = '', controlFilter = '') {
		let filteredControlData = [];
		//console.log('controlType', controlType);
		//console.log('controlFilter', controlFilter);

		if (this.globalFunctions.isEmpty(controlFilter)) {
			//grabs Control data, no filters
			filteredControlData = this.ControlData.filter(x => x.ControlType == controlType);
		}
		else {
			//grabs Control data, but applies a custom filter. at the moment just the one (account enquiry)
			filteredControlData = this.ControlData.filter(x => x.ControlType == controlType && this.store.AccountEnquiryControlGUIDArray.includes(x.ControlGUID));
		}

		//console.log('filteredControlData', filteredControlData);
		return filteredControlData;
	}

	//downloads the selected entity into a csv file
	public DownloadCSV(EntityName: string) {
		//loop through each row and write the value into a csv item array
		const csvItems = [];

		//Flag to indicate whether the ghosted transactions need to be exported or not
		let showGhostedTransactions = true;
		if (EntityName === 'LoanTransactions') {

			//Check the claim first. If the user doesn't have a claim, hide ghosted transactions
			const transactionGhostedClaim = this.store.ClientClaims.filter(x => x.Name === "ShowTransactionGhostedTickbox")[0];
			if (!this.globalFunctions.isEmpty(transactionGhostedClaim) && transactionGhostedClaim.Read === false) {
				showGhostedTransactions = false;
			}
			else {

				//Check the Hide Ghosted checkbox state to include or exclude ghosted transactions
				showGhostedTransactions = !this.hideGhostedTransactionState;
			}
		}

		//loop through each row in the entity
		for (const key in this.EntityDict[EntityName].Entity) {
			const value = this.EntityDict[EntityName].Entity[key];
			//create the row for the csv file
			const csvLine = {
			}

			//and now loop through each data unit
			for (const key2 in value.DataUnits) {
				const value2 = value.DataUnits[key2];
				//remove any highlight tags that may exist
				let targetDataUnitValue = value2.ValueDisplay;
				if (!this.globalFunctions.isEmpty(targetDataUnitValue)) {
					targetDataUnitValue = targetDataUnitValue.replace(this.globalFunctions.customHighlightTagStart, '');
					targetDataUnitValue = targetDataUnitValue.replace(this.globalFunctions.customHighlightTagEnd, '');
				}
				//now write it to the csv line
				csvLine[value2.Name] = targetDataUnitValue
			}

			//console.log('row value', value);
			//now check and add a value for the ghosted CSS class.
			if (value.Entity === 'LoanTransactions' && showGhostedTransactions) {
				let isGhosted = false;
				if (value.RowStyle === 'ghosted') {
					isGhosted = true;
				}

				//now write it to the csv line
				csvLine['Is Ghosted'] = isGhosted;
			}

			//don't push the transaction line if it is ghosted
			if (value.Entity === 'LoanTransactions' && !showGhostedTransactions) {
				if (value.RowStyle !== 'ghosted') {
					//this is NOT ghosted. show it
					csvItems.push(csvLine);
				}
			}
			else {
				//add this to the array
				csvItems.push(csvLine);
			}
		}
		//console.log("csv items: ", csvItems);

		//call the service to produce and download the csv file
		this.csvDataService.exportToCsv(EntityName, csvItems);
	}

	//Loads all entity data for a loan
	private Account_Load(account) {
		if (this.globalFunctions.isEmpty(account)) {
			const warningTitle = "No Account selected"
			const warningMsg = "Please select an Account"
			this.notifyService.Warning_Show(warningMsg, warningTitle)
			return;
		}

		//Put the loan from the store into the class variable
		this.SelectedAccount = account;
		this.AccountID = account.AccountID.toString();

		//Get latest account summary data from the server (we want to get the redraw frozen label)
		this.GetAccountSummary(false);

		//Now request data, for each entity
		for (const key in this.EntityDict) {
			const value = this.EntityDict[key];

			//Reset the array
			value.Entity = [];

			//Now retrieve fresh data for all entities except on demand ones
			if (this.globalFunctions.OnDemandEntities.filter(x => x.EntityName === key).length === 0) {
				this.Loan_GetAllEntityData(account.AccountID.toString(), value.Entity, key);
			}
		}
	}

	//fills data for all entities, used when first hitting the loan index page, after clicking on a search result
	public Loan_GetAllEntityData(LoanID: string, list: DataRow[], Entity: string, dataRowGUID = "", loadEditModal = false, customEntityHeaderName = ''): void {
		//console.log('Getting Entity data for: ', Entity);
		//spinner turns on while loading data
		this.EntityDict[Entity].Spinner = 0;
		//and no results...yet. change it to minus 1, so it doesn't show
		this.EntityDict[Entity].Count = -1;
		//indicate that this entity has started its first 'initial' load. used to stop loading it again when switching back and forth between nav tabs
		this.EntityDict[Entity].InitialLoad = true;

		const apiRequest = { AccountID: LoanID, Entity: Entity, RowGUID: dataRowGUID };
		this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetAccountEntity], apiRequest)
			.subscribe(apiResponse => {
				if (this.globalFunctions.isEmpty(apiResponse)) {
					//turn the spinner back off, even if there was an error. this allows the user to rerun the request after a session timeout
					this.EntityDict[Entity].Spinner = 1;
					return;
				}
				else {
					//process the response, if the list variable was not empty
					this.Entity_ProcessResponse(apiResponse, list, Entity);

					//Check if it needs any stuff on initialization, e.g. hiding ghosted transactions, showing and hiding Add New Entity button. Send the client data store so that the method can inspect and look for the AMALEmployee claim
					if (!this.globalFunctions.isEmpty(this.LoanEntityScripts)) {
						const entityComponent = this.LoanEntityScripts.filter(component => component.EntityName == Entity)[0];
						if (!this.globalFunctions.isEmpty(entityComponent)) {
							entityComponent.CheckGhostedTransactionsOnInit(this.store);

							//Sync the display of add new entity button
							entityComponent.InsertNewEntityButton_Sync();
						}
					}

					//once its processed, we may want to immediately launch into the Edit modal
					if (loadEditModal) {
						//let's load the entity modal. From here we can see the child entities, and modify/insert them too. (e.g. notes and documents on user tasks)
						this.LaunchSingleEntityModal(customEntityHeaderName);
					}
				}
			});
	}

	//processes the entity data returned by the server
	private Entity_ProcessResponse(apiResponse, list: DataRow[], Entity): void {
		//parse the response. should also check for error
		if (!this.globalFunctions.isEmpty(apiResponse)) {
			//reset the entity array contents
			list.length = 0;
			this.globalFunctions.Entity_ParseIntoDataRows(this.AccountID, apiResponse, list, Entity);
			//indicate that the spinner for this entity can be turned off
			this.EntityDict[Entity].Spinner = 1;
			//update the count of how many rows we got!
			this.EntityDict[Entity].Count = this.EntityDict[Entity].Entity.length;
			//clear any search filters
			this.Entity_ResetSearchFilter(Entity);
			//its been loaded at least once now, turn indicate that initial load has now completed
			this.EntityDict[Entity].InitialLoadCompleted = true;
			//should also have its page reset back to the start
			//check that it exists, in headless mode its not there!
			if (!this.globalFunctions.isEmpty(this.LoanEntityScripts)) {
				const component = this.LoanEntityScripts.filter(component => component.EntityName == Entity)[0];

				//And go to the first page!
				if (!this.globalFunctions.isEmpty(component)) {
					component.onPageChange(0, "none");
				}
			}
		}
	}

	//Retrieve the entity data unit value
	public EntityDataUnit_Retrieve(entityDict, targetDataUnitName): string {

		//Lets loop through the dictionary and target the child entity type dictionary
		for (const key in entityDict) {

			const value = entityDict[key];

			//Look for the Entity type dictionary
			if (key === "Entity") {

				//Get the first entity value, it contains the target data units
				const entityValue = value[0];

				if (!this.globalFunctions.isEmpty(entityValue)) {

					//Get the entity data units
					const dataUnits = value[0].DataUnits;

					if (!this.globalFunctions.isEmpty(dataUnits)) {

						//Get the target data unit to retrieve
						const targetDataUnit = dataUnits.filter(x => x.Name === targetDataUnitName)[0];

						if (!this.globalFunctions.isEmpty(targetDataUnit)) {
							return targetDataUnit.Value ?? "";
						}
					}
				}
			}
		}

		//Return empty string
		return "";
	}

	//refresh all records of a entity, by reference and a supplied name
	public Entity_FullRefresh(EntityName: string) {
		//remove any selected data unit
		this.DataUnit_RemoveSelected();
		this.Loan_GetAllEntityData(this.AccountID, this.EntityDict[EntityName].Entity, EntityName);
	}

	//retrieve the data unit label names to produce a header row for the tabular style view of an entity
	public Entity_GetHeaders(EntityName: string) {
		//lookup from the view model table directly. get only matching entity columns, that are not hidden.
		const headers = this.ClientViewModelData.filter(x => x.Entity == EntityName && x.HideDisplay === false);
		//console.log("headers: ", headers);
		const headersClone = JSON.parse(JSON.stringify(headers));

		//uppercase the headers
		Object.entries(headersClone).forEach(
			([rowkey,]) => {
				if (!this.globalFunctions.isEmpty(headersClone[rowkey])) {
					headersClone[rowkey].PropertyName = headersClone[rowkey].PropertyName.toUpperCase();
				}
			})

		//return the results, sorted by the display order
		return headersClone.sort((n1, n2) => n1.DisplayOrder - n2.DisplayOrder);
	}

	//reset the Entity Search filter text
	public Entity_ResetSearchFilter(EntityName: string) {
		//console.log("EntitySearchFilter firing for: ", EntityName, " - ", searchTerm);
		//if this entity is still doing its first load, don't bother doing anything here.
		if (this.EntityDict[EntityName].InitialLoadCompleted === false) { return }

		//unselected any data units
		this.DataUnit_RemoveSelected();

		//console.log('EntityName:', EntityName);

		//now remove any text inside the related entity filter
		const component = this.LoanEntityScripts.filter(component => component.EntityName == EntityName)[0];

		if (!this.globalFunctions.isEmpty(component)) {
			//this resets the text on the keyUp element.
			component.filterSearchValue = "";

			//and this tries to reset the observable value, so that it can search again (even when searching the exact same value after a refresh)
			//make it look like the shape of the data that the subscription expects
			component.filterSearchValueKeyUp.next({ target: "" });
		}
	}

	//this applies a filter based on the search term to the input entity.
	public Entity_SearchFilter(EntityName: string, searchTerm: string) {
		//unselected any data units (they might get hidden by the filter)
		this.DataUnit_RemoveSelected();

		//try to catch null or undefined, replace with blank string
		if (this.globalFunctions.isEmpty(searchTerm)) {
			//force it to an empty value (if null or undefined)
			searchTerm = "";
		}

		//first remove all hide filters and tags
		for (const key in this.EntityDict[EntityName].Entity) {
			const value = this.EntityDict[EntityName].Entity[key];

			if (!this.globalFunctions.isEmpty(value)) {
				//leave ghosted as they are, based on the flag
				let leaveThisRowGhosted = false;
				if (value.RowStyle === 'ghosted' && (this.hideGhostedTransactionState || this.hideSystemNotesState)) {
					leaveThisRowGhosted = true;
				}
				else {
					leaveThisRowGhosted = false;
				}

				if (leaveThisRowGhosted) {
					value.isHidden = true;
				}
				else {
					value.isHidden = false;
				}

				//loop through each data unit and remove mark tags
				for (const key2 in value.DataUnits) {
					const value2 = value.DataUnits[key2];
					if (!this.globalFunctions.isEmpty(value2) && !this.globalFunctions.isEmpty(value2.ValueDisplay)) {
						//we found a hit. remove all mark tags from the value.						
						value2.ValueDisplay = value2.ValueDisplay.replaceAll(this.globalFunctions.customHighlightTagStart, '');
						value2.ValueDisplay = value2.ValueDisplay.replaceAll(this.globalFunctions.customHighlightTagEnd, '');
					}
				}
			}
		}

		//console.log("running search filter for Entity: ", EntityName);
		//let's try splitting the search filter for multiple conditions when an OR operator character (|) exists, and && for AND. store the items in a list
		let searchTermsList = [];
		//we treat AND operator a little differently
		let isAndOperator = false;

		//split the searchTerm by the operator characters, and place it into searchTermsList
		//for AND operator, use deouble ampersand, and a single ampersand could be found commonly inside data fields
		if (searchTerm.includes("&&")) {
			isAndOperator = true;
			searchTermsList = searchTerm.split('&&');
		}
		//or operator. use single pipe
		else if (searchTerm.includes("|")) {
			isAndOperator = false;
			searchTermsList = searchTerm.split('|');
		}
		//no operators. just push the single value in
		else {
			searchTermsList.push(searchTerm);
		}
		//console.log('searchTermsList pre',searchTermsList);

		//array to store index of non blank items
		const nonBlankTermsList = [];
		//any blanks, strip em out. this will help AND conditions resolve properly when any value is blank. only needed for AND operator
		if (isAndOperator) {
			//loop through and get all non blank items. used for checking whether or not we remove later (for the AND condition)
			for (const searchTermItem in searchTermsList) {
				//now check if the value is blank
				const searchTermItemValue = searchTermsList[searchTermItem];
				if (!this.globalFunctions.isEmpty(searchTermItemValue)) {
					nonBlankTermsList.push(searchTermItem);
				}
			}
		}

		//console.log("EntitySearchFilter firing for: ", EntityName, " - ", searchTermEntry);
		//upper case the search term
		const searchTermEntry = searchTerm.toUpperCase();

		//and make sure it is greater than 2 characters as well
		if (!this.globalFunctions.isEmpty(searchTerm) && searchTermEntry != null && searchTerm.length > 2) {
			//loop through each row in the entity
			for (const key in this.EntityDict[EntityName].Entity) {
				const value = this.EntityDict[EntityName].Entity[key];

				//work out if this is a ghosted row. if the hideGhostedTransactionState is true, then we don't want to show this, even if the search hit is there.
				let leaveThisRowGhosted = false;
				if (value.RowStyle === 'ghosted' && (this.hideGhostedTransactionState || this.hideSystemNotesState)) {
					leaveThisRowGhosted = true;
				}
				else {
					leaveThisRowGhosted = false;
				}

				//see if there is any match on the datarow (rowflag) here, and default it to true (hidden)
				let isRowHidden = true;
				value.isHidden = isRowHidden;

				//we need to count unique matches for AND conditions.
				const andMatchList: Array<string> = [];

				//loop through each data unit and check for search term matches
				for (const key2 in value.DataUnits) {
					const value2 = value.DataUnits[key2];
					//remove any existing mark tags before we start searching it, as this would distort it
					if (!this.globalFunctions.isEmpty(value2) && !this.globalFunctions.isEmpty(value2.ValueDisplay)) {
						value2.ValueDisplay = value2.ValueDisplay.replace(this.globalFunctions.customHighlightTagStart, '');
						value2.ValueDisplay = value2.ValueDisplay.replace(this.globalFunctions.customHighlightTagEnd, '');
					}

					//loop through each OR term
					for (const searchTermItem in searchTermsList) {
						//now check if the value matches the search term.
						const searchTermItemOriginal = searchTermsList[searchTermItem];
						const searchTermItemParsed = searchTermsList[searchTermItem].toUpperCase();

						//make sure there is some value to check!
						if (!this.globalFunctions.isEmpty(searchTermItemParsed) && searchTermItemParsed.length > 2) {
							if (value2 != null && value2.ValueDisplay != null && value2.ValueDisplay.toString().toUpperCase().includes(searchTermItemParsed) === true
							) {
								//console.log("found a hit! for: ", searchTerm);
								//we found a hit. 
								//check if this is an AND operator. check if its a unique value, if so, add it to the andMatchList list
								if (isAndOperator) {
									if (andMatchList.filter(x => x === searchTermItemParsed).length === 0) {
										andMatchList.push(searchTermItemParsed);
									}
								}

								//update the flag to false
								//value.isHidden = false;
								//and the row flag so others are not hidden either
								//isRowHidden = false;
								//but only allow it if leaveThisRowGhosted is false
								if (!leaveThisRowGhosted) {
									value.isHidden = false;
									isRowHidden = false;
								}

								//lets try putting <mark> tags around the hit! use regex to find all instances of it, and case insensitive too
								const typedVal = searchTermItemOriginal;
								const re = new RegExp("(" + typedVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + ")", "ig");
								value2.ValueDisplay = value2.ValueDisplay.replace(re, this.globalFunctions.customHighlightTagStart + '$1' + this.globalFunctions.customHighlightTagEnd);
							}
							else {
								//no match. update the hidden flag to match the rowflag.
								value.isHidden = isRowHidden;
								//remove all mark tags from the value, if it is still to remain hidden.
								if (!this.globalFunctions.isEmpty(value2) && !this.globalFunctions.isEmpty(value2.ValueDisplay === true && value.isHidden)) {
									value2.ValueDisplay = value2.ValueDisplay.replaceAll(this.globalFunctions.customHighlightTagStart, '');
									value2.ValueDisplay = value2.ValueDisplay.replaceAll(this.globalFunctions.customHighlightTagEnd, '');
								}
							}
						}
					}
				}

				//did we match all AND terms? compare entries in the andMatchList and the nonBlankTermsList.
				if (isAndOperator && (andMatchList.length !== nonBlankTermsList.length)) {
					//not all terms matched. remove this row. loop through each data unit
					for (const key2 in value.DataUnits) {
						const value2 = value.DataUnits[key2];
						if (!this.globalFunctions.isEmpty(value2) && !this.globalFunctions.isEmpty(value2.ValueDisplay)) {
							//remove all mark tags from the value.						
							value2.ValueDisplay = value2.ValueDisplay.replaceAll(this.globalFunctions.customHighlightTagStart, '');
							value2.ValueDisplay = value2.ValueDisplay.replaceAll(this.globalFunctions.customHighlightTagEnd, '');
						}
					}
					value.isHidden = true;
				}
			}
		}

		//turn the spinner off after we have finished with filtering.
		this.EntityDict[EntityName].Spinner = 1;
		//we should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
		//look for the component that matches our entity
		const component = this.LoanEntityScripts.filter(component => component.EntityName == EntityName)[0];
		//and go to the first page!
		component.onPageChange(0, "none");
	}

	//initial state of hiding ghosted transactions
	public hideGhostedTransactionState = false;
	//specific method to hide transactions - ghosted ones
	public Entity_HideGhostedTransactions(flag: boolean) {
		//unselected any data units (they might get hidden by the filter)
		this.DataUnit_RemoveSelected();
		const EntityName = 'LoanTransactions';
		this.hideGhostedTransactionState = flag;

		//loop through each row in the entity
		for (const key in this.EntityDict[EntityName].Entity) {
			const value = this.EntityDict[EntityName].Entity[key];

			//we are just looking for a specific thing - is this ghosted? e.g. is it italicized. should be inside some css markup - look for a field called RowStyle
			//console.log('value', value);
			//console.log('value.RowStyle', value.RowStyle);
			if (value.RowStyle === 'ghosted' && flag) {
				value.isHidden = true;
			}
			else {
				value.isHidden = false;
			}
		}

		//turn the spinner off after we have finished with filtering.
		this.EntityDict[EntityName].Spinner = 1;
		//we should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
		//look for the component that matches our entity
		const component = this.LoanEntityScripts.filter(component => component.EntityName == EntityName)[0];
		//and go to the first page!
		component.onPageChange(0, "none");
	}

	//initial state of hiding system notes
	public hideSystemNotesState = false;
	//specific method to hide system notes
	public Entity_HideSystemNotes(flag: boolean) {
		//unselected any data units (they might get hidden by the filter)
		this.DataUnit_RemoveSelected();
		const EntityName = 'LoanNotes';
		this.hideSystemNotesState = flag;

		//loop through each row in the entity
		for (const key in this.EntityDict[EntityName].Entity) {
			const value = this.EntityDict[EntityName].Entity[key];

			//we are just looking for a specific thing - is this ghosted? e.g. is it italicized. should be inside some css markup - look for a field called RowStyle
			//console.log('value', value);
			//console.log('value.RowStyle', value.RowStyle);
			if (value.RowStyle === 'ghosted' && flag) {
				value.isHidden = true;
			}
			else {
				value.isHidden = false;
			}
		}

		//turn the spinner off after we have finished with filtering.
		this.EntityDict[EntityName].Spinner = 1;
		//we should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
		//look for the component that matches our entity
		const component = this.LoanEntityScripts.filter(component => component.EntityName == EntityName)[0];
		//and go to the first page!
		component.onPageChange(0, "none");
	}

	//returns the friendly name of an entity
	public Entity_GetFriendlyName(Entity: string) {
		if (!this.globalFunctions.isEmpty(Entity)) {
			const entityLookup = this.ClientEntityTypes.filter(x => x.Name == Entity)[0];
			if (!this.globalFunctions.isEmpty(entityLookup)) {
				{
					return entityLookup.FriendlyName;
				}
			}
		}
		return "";
	}

	//gets the icon of the entity
	public Entity_GetFontAwesomeIcon(Entity: string) {
		if (!this.globalFunctions.isEmpty(Entity)) {
			const entityLookup = this.ClientEntityTypes.filter(x => x.Name == Entity)[0];
			if (!this.globalFunctions.isEmpty(entityLookup)) {
				{
					return entityLookup.FontAwesomeIcon;
				}
			}
		}
		//default - question mark (unknown)
		return "question";
	}

	//Launch Create Reminder Email
	public CreateEmail_Launch(dataRow, emailTemplate = "Reminder"): void {

		this.store.SetShowFullscreenLoading(true);

		//Create Email Data
		const createEmailData = new CreateEmailData();
		const emailTemplateName = "CS_" + emailTemplate;

		//Construct api request
		const apiRequest = { AccountID: this.AccountID, UserTaskGUID: dataRow.GUID, TemplateName: emailTemplateName };

		this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetConditionSubsequentEmailPrefill], apiRequest)
			.subscribe(apiResponse => {
				if (this.globalFunctions.isEmpty(apiResponse)) {

					this.store.SetShowFullscreenLoading(false);
					return;
				}
				else {

					//Get the response
					const response = JSON.parse(JSON.stringify(apiResponse));

					//Update the client side based on the response
					createEmailData.ReplyTo = this.globalFunctions.HTMLUnescape(response.ReplyTo);
					createEmailData.CCTo = this.globalFunctions.HTMLUnescape(response.CCEmail);
					createEmailData.BCCTo = this.globalFunctions.HTMLUnescape(response.BCCEmail);
					createEmailData.Subject = this.globalFunctions.HTMLUnescape(response.Subject);
					createEmailData.Body = response.Body;
					createEmailData.FromEmailDisplay = this.globalFunctions.HTMLUnescape(response.FromEmailDisplay);

					//Turn off the fullscreen loading
					this.store.SetShowFullscreenLoading(false);

					//Launch the Create Email Component with the modal config
					const createReminderEmail = this.globalFunctions.FeatureModal_Launch(CreateEmail, this.globalFunctions.GetFeatureModalConfig('70%', true), this.dialog, "Create Email", this.AccountID, true, false);

					//Set the LoanIndex so that it can call back and update the client side entities that are updated, as required.
					createReminderEmail.DialogRef.componentInstance.LoanIndex = this;

					//Set the form header
					createReminderEmail.DialogRef.componentInstance.PageHeader = "Create " + emailTemplate + " Email";

					//Pass data that will be relevant for sending email
					createReminderEmail.DialogRef.componentInstance.DataRow = dataRow;

					//Loop through each element and decode the email
					response.RecipientEmails.forEach(element => {

						//Unescape html encoding
						element.Email = this.globalFunctions.HTMLUnescape(element.Email);
						element.DisplayName = this.globalFunctions.HTMLUnescape(element.DisplayName);
					});

					//Pass email data prefill
					createReminderEmail.DialogRef.componentInstance.CreateEmailData = createEmailData;
					createReminderEmail.DialogRef.componentInstance.RecipientEmails = response.RecipientEmails;
					createReminderEmail.DialogRef.componentInstance.EmailTemplateName = emailTemplateName;
					createReminderEmail.DialogRef.componentInstance.Page_Init();
				}
			});


	}

	//Launch Finalise Task Form
	public TaskFinalise_Launch(dataRow): void {

		//Launch the Finalise Task Component with the modal config
		const finaliseTask = this.globalFunctions.FeatureModal_Launch(FinaliseTask, this.globalFunctions.GetFeatureModalConfig('60%', false, false), this.dialog, "Finalise Task", this.AccountID, true, false);

		//Set the LoanIndex so that it can call back and update the client side entities that are updated, as required.
		finaliseTask.DialogRef.componentInstance.LoanIndex = this;

		//We should pass the TaskGUID that we are targeting to finalise the task. Since we passed the dataRow, we can just get the GUID from it directly
		finaliseTask.DialogRef.componentInstance.DataRow = dataRow;
	}

	//Launch Task Followup Form
	public TaskFollowup_Launch(dataRow): void {

		//Launch the Task Followup Component with the modal config
		const taskFollowup = this.globalFunctions.FeatureModal_Launch(TaskFollowup, this.globalFunctions.GetFeatureModalConfig('50%', true), this.dialog, "Task Followup", this.AccountID, true, false);

		//Set the LoanIndex so that it can call back and update the client side entities that are updated, as required.
		taskFollowup.DialogRef.componentInstance.LoanIndex = this;

		//We should pass the TaskGUID that we are targeting for the followup date/note. Since we passed the dataRow, we can just get the GUID from it directly
		taskFollowup.DialogRef.componentInstance.DataRow = dataRow;
	}

	//Launch Task Feedback Form
	public TaskFeedback_Launch(dataRow): void {

		//Launch the Task Quality Review Component with the modal config
		const taskQualityReview = this.globalFunctions.FeatureModal_Launch(TaskQualityReview, this.globalFunctions.GetFeatureModalConfig('50%', true), this.dialog, "Task Quality Review", this.AccountID, true, false);

		//Set the LoanIndex so that it can call back and update the client side entities that are updated, as required.
		taskQualityReview.DialogRef.componentInstance.LoanIndex = this;

		//We should pass the TaskGUID that we are targeting for the feedback. Since we passed the dataRow, we can just get the GUID from it directly
		taskQualityReview.DialogRef.componentInstance.DataRow = dataRow;
	}

	//Works out if the QC Feedback Button can be displayed, and also checks the claim of the logged in user.
	public TaskFeedbackButton_IsVisible(dataRow): boolean {
		let isVisible = false;

		if (!this.globalFunctions.isEmpty(dataRow)) {
			if (!this.globalFunctions.isEmpty(dataRow.Entity)) {
				const entityLookup = this.ClientEntityTypes.filter(x => x.Name == dataRow.Entity)[0];

				if (!this.globalFunctions.isEmpty(entityLookup)) {

					//Check if the entity is "LoanTasks"
					if (entityLookup.Name === "LoanTasks") {

						//Now lookup the users claims
						if (this.globalFunctions.Claim_VerifyPermission(this.store, "TaskQualityReview", "Edit") === true) {

							//Now check if the logged in user is the assigned to user
							const assignedToUser = dataRow.DataUnits.filter(x => x.Name === "Assigned To")[0];

							//If no user, make it visible
							if (this.globalFunctions.isEmpty(assignedToUser)) {
								isVisible = true;
							}

							//Otherwise check if the logged in user matches the assigned user. If not, make it visible
							else if (assignedToUser.ControlGUID != this.store.loginDataDirect.LoggedInClientGUID) {
								isVisible = true;
							}
						}
					}
				}
			}
		}

		return isVisible;
	}

	//Works out if the CS Task Buttons can be displayed
	public CSActionButton_IsVisible(dataRow, claimName: string): boolean {
		let isVisible = false;

		if (!this.globalFunctions.isEmpty(dataRow)) {
			if (!this.globalFunctions.isEmpty(dataRow.Entity)) {
				const entityLookup = this.ClientEntityTypes.filter(x => x.Name == dataRow.Entity)[0];

				if (!this.globalFunctions.isEmpty(entityLookup)) {

					//Check if the entity is "LoanTasks"
					if (entityLookup.Name === "LoanTasks") {

						//Now lookup the users claims
						if (this.globalFunctions.Claim_VerifyPermission(this.store, claimName, "Read") === true) {

							//Now check the task type
							const taskType = dataRow.DataUnits.filter(x => x.Name === "Task Type")[0];

							//Check if the task type is Condition Subsequent
							if (!this.globalFunctions.isEmpty(taskType) && taskType.ControlGUID === this.ConditionSubsequentControlGUID) {

								//Check the task status, don't display if the task is complete
								const taskStatus = dataRow.DataUnits.filter(x => x.Name === "Task Status")[0];
								if (!this.globalFunctions.isEmpty(taskStatus) && taskStatus.ControlGUID !== this.TaskStatusCompleteControlGUID) {
									isVisible = true;
								}
							}
						}

					}
				}
			}
		}
		return isVisible;
	}

	//works out if the entity can be inserted to, and also checks the claim of the logged in user.
	public ChildEntity_IsInsertable(Entity: string) {
		if (!this.globalFunctions.isEmpty(Entity)) {
			const entityLookup = this.ClientEntityTypes.filter(x => x.Name == Entity)[0];
			//console.log('entityLookup', entityLookup);

			if (!this.globalFunctions.isEmpty(entityLookup)) {
				{
					//now lookup the users claims
					const matchingClaim = this.GetEntityClaim(entityLookup.FriendlyName);
					//both the enum insert support, plus the users claim, must both align for insertion to be allowed.
					if (entityLookup.SupportsInsert === true && matchingClaim.Insert === true) {
						return true;
					}
				}
			}
		}
		//default - false. not able to insert
		return false;
	}

	//Wrapper for confirm flow on all modify/edit requests
	public Entity_OpenModifyTemplate(dataRow: DataRow, labelName: string, EntityName = "", cloneFlag = false, parent1GUID = "", childEntityLevel2 = null, customEntityTitleName: string = null, showOverrideDueDateCheckBox = false, customConfirmMessage = null) {

		//Check if this was a clone request except Account Enquiries
		if (cloneFlag === true && customEntityTitleName !== 'Account Enquiry') {

			//Adding a confirmer flow
			const confirmDialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.AccountID, true, false);
			let entityName = "entity";

			if (!this.globalFunctions.isEmpty(dataRow) && !this.globalFunctions.isEmpty(dataRow.EntityFriendlyName)) {
				entityName = dataRow.EntityFriendlyName;
			}

			confirmDialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to clone this " + entityName + "?";

			confirmDialogRef.DialogRef.afterClosed().subscribe(result => {
				if (result === true) {

					//User has confirmed to clone
					this.Entity_OpenModifyTemplateForm(dataRow, labelName, EntityName, cloneFlag, parent1GUID, childEntityLevel2, customEntityTitleName, showOverrideDueDateCheckBox, customConfirmMessage);
				}
			});

		}
		else {

			//Not cloning eg. edit, ignore confirm flow
			this.Entity_OpenModifyTemplateForm(dataRow, labelName, EntityName, cloneFlag, parent1GUID, childEntityLevel2, customEntityTitleName, showOverrideDueDateCheckBox, customConfirmMessage);
		}
	}

	//This is used to prepare a datarow and its data units for editing, and launch the Modal for editing any entity field. it will use the LoanEntityModify html template
	public Entity_OpenModifyTemplateForm(dataRow: DataRow, labelName: string, EntityName = "", cloneFlag = false, parent1GUID = "", childEntityLevel2 = null, customEntityTitleName: string = null, showOverrideDueDateCheckBox = false, customConfirmMessage = null) {

		let dataUnitArray: DataUnit[];
		//construct a new array of dataunits to store only the columns we are interested in. this will get items pushed into it as needed
		const iDataUnits = [];
		//console.log("childEntityLevel2: ", childEntityLevel2);

		//all file uploading fields to null/false
		this.FileUpload_FileBase64String = null;
		this.FileUpload_FileName = null;
		this.FileUpload_IsValid = false;
		this.FileUpload_IsChosen = false;

		//Determine if the user has LockTaskCompletedDate claim
		const lockTaskCompletedDateCheck = this.globalFunctions.Claim_VerifyPermission(this.store, "LockTaskCompletedDate", "Read");

		//if we are supplied a level 2 child entity, lets transform the control data. (at the moment we know that address and contacts needs it)
		let controlAddressType = ""
		let controlContactType = ""

		//console.log('dataRow', dataRow);
		//console.log('childEntityLevel2', childEntityLevel2);

		if (!this.globalFunctions.isEmpty(childEntityLevel2)) {
			//console.log("child entity supplied, will try to use it.");
			if (childEntityLevel2.Entity == "ClientIndividual") {
				controlAddressType = "Individual Address Type"
				controlContactType = "Individual Contact Type"
			}
			else if (childEntityLevel2.Entity == "ClientCompany") {
				controlAddressType = "Company Address Type"
				controlContactType = "Company Contact Type"

				//what about internal departments? hmm. use the new field we created to signal this
				if (childEntityLevel2.ControlDataType == "Internal Department Contact Type") {
					controlContactType = "Internal Department Contact Type"
				}
			}
		}

		//few more things that are switched, dependant on the request.
		let chosenAccountID = "";
		let chosenEntity = "";
		let chosenGUID = "";
		let chosenEntityName = "";
		let chosenParent1GUID = "";
		//check if a parent GUID was supplied, which adds support to insert child entities
		if (!this.globalFunctions.isEmpty(parent1GUID)) {
			chosenParent1GUID = parent1GUID;
		}
		else {
			//we may have the parent set here in the loan index script, when the data unit was clicked (to enable support for cloning child entities).
			if (!this.globalFunctions.isEmpty(this.lastClickedParentGUID)) {
				chosenParent1GUID = this.lastClickedParentGUID
			}
		}

		//we need to work out if this request relates to the account, or its parent. (at the moment, the only 2 afffected entities are security and association, plus their children)
		let isParentAccount = false;

		//what if there are no data units? expect that its a create request. grab data from the Client View Model
		if (this.globalFunctions.isEmpty(dataRow)) {
			//we want to create dummy/placeholder data units from the View Model
			const Entity = this.ClientViewModelData.filter(x => x.Entity == EntityName)
			chosenAccountID = this.AccountID;
			chosenEntity = EntityName;
			chosenGUID = "{NewGUID}";

			//we still need to know if this is for a parent account. how can we tell this here? without a datarow to guide us.
			//maybe a combination of the account record, (check if this is a child account), and also the entity name.
			//so loan security, valuation and insurance should all trigger a isParent = true, if this is a child account (RMR_IDLink_Parent is not itself).
			//console.log('this.SelectedAccount', this.SelectedAccount);
			//console.log('chosenEntity', chosenEntity);
			if (this.SelectedAccount.IsParent === false && (chosenEntity === 'LoanSecurities' || chosenEntity === 'LoanValuations' || chosenEntity === 'LoanInsurance' || chosenEntity === 'LoanAssociations' || chosenEntity === 'Client' || chosenEntity === 'ClientIndividual' || chosenEntity === 'ClientCompany' || chosenEntity === 'ClientContact' || chosenEntity === 'ClientAddress' || chosenEntity === 'ClientCustomFields' || chosenEntity === 'ClientCustomNumeric' || chosenEntity === 'ClientIdentifiers' || chosenEntity === 'IndividualEmployments' || chosenEntity === 'IndividualSelfEmployed' || chosenEntity === 'LoanVehicleSecurities' || chosenEntity === 'AssetLocations')) {
				//flip isParentAccount to true, so that the server knows to switch to the parent when inserting the record
				isParentAccount = true;
			}

			//use the pretty name instead for the Entity.
			chosenEntityName = this.ClientEntityTypes.filter(x => x.Name == EntityName)[0].FriendlyName;

			//sort the Entity object by its order
			Entity.sort(function (a, b) {
				return a.DisplayOrder - b.DisplayOrder;
			});

			//ignore any nested child entities
			Object.entries(Entity.filter(x => x.PropertyType !== "entity")).forEach(
				([, value]) => {

					const iDataUnit = {
						Name: value.PropertyName,
						//no placeholder? perhaps the viewmodel can contain some sample defaults? (for static fields, anyway)
						Value: "",
						ValueDisplay: "",
						Type: value.PropertyType,
						EditBoxSize: value.EditBoxSize,
						//these are fields added client side to help with adding control/autocomplete lookups and validation
						AutoCompleteControlGUID: null,
						AutoCompleteControlDisplayValue: null,
						AutoCompleteControlData: null,
						AutoCompleteLastSearchedValue: null,
						//for selecting the default control item. this is the the selection (dropdown) control
						SelectedControl: null,
						HideEdit: value.HideEdit,
						HideCreate: value.HideCreate,
						isHidden: false,
						CSSClass: value.CSSClass,
						MinLength: value.MinLength,
						MaxLength: value.MaxLength,
						//there is no data unit to attach a custom filter from. so we can't really set it here. just leave it empty
						CustomFilter: '',
						Disabled: value.Disabled,
						MinDate: value.MinDate,
						//When taken directly from the view model, there us no ClientDisplay value. Default it to true
						ClientDisplay: true
					};

					//we need to transform certain control names (e.g. address)
					if (value.PropertyName.includes("Address Type") === true) {
						iDataUnit.Name = controlAddressType
					}
					//we need to transform certain control names (e.g. contact type)
					else if (value.PropertyName.includes("Contact Type") === true) {
						iDataUnit.Name = controlContactType
					}

					//clear/initialize existing selections for autocomplete and dropdowns
					iDataUnit.AutoCompleteControlGUID = null;
					iDataUnit.AutoCompleteControlDisplayValue = { value: "" };
					iDataUnit.SelectedControl = "";

					//store the control data array returned from the server. this is used by autocomplete to show the available options
					iDataUnit.AutoCompleteControlData = [];
					//store the last control value that we asked the server to provide for us. this is for local client tracking, so that we don't incessantly ask the server for the same data over and over again.
					iDataUnit.AutoCompleteLastSearchedValue = "";

					//If entity is Loan Task Note, set the default value for Note Type to "XChange Note"
					if (chosenEntity === "LoanTaskNotes" && value.PropertyName.includes("Note Type") === true) {
						iDataUnit.AutoCompleteControlGUID = "{2e8adb37-5a11-435d-888c-16c4b6552101}";
						iDataUnit.AutoCompleteControlDisplayValue = { value: "xChange Note" };
						iDataUnit.Value = iDataUnit.AutoCompleteControlGUID;
						iDataUnit.ValueDisplay = "xChange Note";
						iDataUnit.AutoCompleteLastSearchedValue = "xChange Note";
					}

					//add this to the array
					iDataUnits.push(iDataUnit)
				})
		}
		else {
			//this is an edit (or clone) request
			chosenAccountID = dataRow.AccountID;
			chosenEntity = dataRow.Entity;
			//we can just use the IsParentAccount that was set on the existing item
			isParentAccount = dataRow.IsParentAccount;

			//check if this was a clone request
			if (cloneFlag) {
				//this will tell the server to make a new record
				chosenGUID = "{NewGUID}";
			}
			else {
				//update the existing one
				chosenGUID = dataRow.GUID;
			}

			//EntityName is no longer used here, its only used for Display purposes. TODO - rename it to EntityDisplayName
			//chosenEntityName = dataRow.EntityName;
			//use the propery friendly name of the entity based on the ENUM!
			chosenEntityName = dataRow.EntityFriendlyName;

			//lookup the entity from the types so we can get the enum named needed for the lookup against the ClientViewModel later
			const chosenEntityEnumName = this.ClientEntityTypes.filter(x => x.FriendlyName == chosenEntityName)[0].Name;

			//create an array of all the data units that we want to fill based on the supplied dataRow
			dataUnitArray = dataRow.DataUnits;
			//and also, if a label name was supplied, only provide that single data unit
			if (!this.globalFunctions.isEmpty(labelName)) {
				dataUnitArray = dataRow.DataUnits.filter(x => x.Name === labelName);
			}

			//only take fields that are NOT editlocked and NOT hidden for edit
			Object.entries(dataUnitArray.filter(x => !x.EditLock && !x.HideEdit)).forEach(
				([, value]) => {
					const filteredDataRow = value;

					//parse it as an iso date, if it is one. required for the calendar picker
					let valParse = "";

					//deep clone the column name, since we have to transform due to address and contact types
					let viewModelLookup = JSON.parse(JSON.stringify(filteredDataRow.Name));

					//grab the view model record, so we can grab some other data required for validation (e.g min and max lengths)
					//required for addresses and contacts, we need to transform back to the generic view model name
					if (filteredDataRow.Name.includes("Address Type")) {
						viewModelLookup = "Address Type";
					}
					if (filteredDataRow.Name.includes("Contact Type")) {
						viewModelLookup = "Contact Type";
					}

					//now lookup the corresponding view model record
					const viewModelRecord = this.ClientViewModelData.filter(x => x.Entity == chosenEntityEnumName && x.PropertyName == viewModelLookup)[0];

					//If the client view Model is empty, force user to login again. Permission might have changed on the server E.g. Entity Property Claim Updated
					if (this.globalFunctions.isEmpty(viewModelRecord)) {

						//Set fullscreenloading so that user can't interact with the edit model while logging the user out
						this.store.SetShowFullscreenLoading(true);
						this.notifyService.Warning_Show("Please login again.", "Permission Error");

						//Delay a bit before logging out the user
						this.globalFunctions.delay(5000).then(() => {
							this.store.SetShowFullscreenLoading(false);
							this.SearchBarComponent.Logout();
						});
						return;
					}

					if (filteredDataRow.Type.includes("longdatetime")) {

						//Validate the date from the server on filteredDataRow.Value
						if (this.globalFunctions.isEmpty(filteredDataRow.Value) || filteredDataRow.Value == this.globalFunctions.getServerNullString()) {
							filteredDataRow.ValueDate = null;
							valParse = "";
						}
						else {
							//Convert the incoming date to moment date
							const DTPMomentDate = moment(filteredDataRow.Value, "MM/DD/YYYY hh:mm:ss A");

							//Convert the moment date to javascript date for the primeng timepicker and set the ValueDate property in DataUnit
							filteredDataRow.ValueDate = new Date(Number(DTPMomentDate.format("YYYY")), Number(DTPMomentDate.format("MM")) - 1, Number(DTPMomentDate.format("DD")), Number(DTPMomentDate.format("HH")), Number(DTPMomentDate.format("mm")), Number(DTPMomentDate.format("ss")));

							//Set valParse with the string value of the javascript date. Won't be used for longdatetime display
							valParse = filteredDataRow.ValueDate.toString();
						}

						//Disable completed date on UI on loan tasks if the user is assigned a role with LockTaskCompletedDate claim
						if (lockTaskCompletedDateCheck && chosenEntity === "LoanTasks" && filteredDataRow.Name === "Completed Date") {
							filteredDataRow.Disabled = true;
						}
					}
					//the calendar picker on the modal wants the datetime in a specific ISO format
					else if (filteredDataRow.Type.includes("datetime")) {
						valParse = this.globalFunctions.getISODate(filteredDataRow.Value);
					}
					else {
						//other values can stay as is. except nulls.
						valParse = filteredDataRow.Value;
						if (!this.globalFunctions.isEmpty(filteredDataRow.CSSClass)) {
							if (filteredDataRow.CSSClass.includes("glb_emptyStyle")) {
								valParse = "";
							}
						}
					}

					//let's unescape html values, as the server values recieved would be encoded
					if (filteredDataRow.Type.includes("string") || filteredDataRow.Type.includes("autocomplete") || filteredDataRow.Type.includes("control")) {
						//unescape both value and valuedisplay. Why? this is because you need to allow users to edit the raw value.
						valParse = this.globalFunctions.HTMLUnescape(valParse);
						//we need to unescape the valuedisplay too, for autocompletes
						filteredDataRow.ValueDisplay = this.globalFunctions.HTMLUnescape(filteredDataRow.ValueDisplay);
					}

					const iDataUnit = {
						Name: filteredDataRow.Name,
						Value: valParse,
						ValueDate: filteredDataRow.ValueDate,
						ValueDisplay: filteredDataRow.ValueDisplay,
						Type: filteredDataRow.Type,
						//Index: filteredDataRow.Index,
						EditBoxSize: filteredDataRow.EditBoxSize,
						//these are fields added client side to help with adding control/autocomplete lookups and validation
						AutoCompleteControlGUID: null,
						AutoCompleteControlDisplayValue: null,
						AutoCompleteControlData: null,
						AutoCompleteLastSearchedValue: null,
						//for selecting the default control item. this is the the selection (dropdown) control
						SelectedControl: null,
						HideEdit: filteredDataRow.HideEdit,
						HideCreate: filteredDataRow.HideCreate,
						isHidden: false,
						CSSClass: filteredDataRow.CSSClass,
						MinLength: viewModelRecord.MinLength,
						MaxLength: viewModelRecord.MaxLength,
						CustomFilter: filteredDataRow.CustomFilter,
						Disabled: filteredDataRow.Disabled,
						MinDate: value.MinDate,
						ClientDisplay: filteredDataRow.ClientDisplay
					};

					//clear/initialize existing selections for autocomplete and dropdowns
					iDataUnit.AutoCompleteControlGUID = null;
					iDataUnit.AutoCompleteControlDisplayValue = { value: "" };
					iDataUnit.SelectedControl = "";
					//store the control data array returned from the server. this is used by autocomplete to show the available options
					iDataUnit.AutoCompleteControlData = [];
					//store the last control value that we asked the server to provide for us. this is for local client tracking, so that we don't incessantly ask the server for the same data over and over again.
					iDataUnit.AutoCompleteLastSearchedValue = "";

					//set the default selected control for the dropdown selections, (if there are any)
					if (filteredDataRow.Type === "control") {
						//look for the value, dependant on whether we have a GUID or not. stick the result in an array.
						let controlValue = [];
						if (!this.globalFunctions.isEmpty(filteredDataRow.ControlGUID)) {
							controlValue = this.ControlData.filter(x => x.ControlType === filteredDataRow.Name && x.ControlGUID === filteredDataRow.ControlGUID);
						}
						else {
							controlValue = this.ControlData.filter(x => x.ControlType === filteredDataRow.Name && x.ControlValue === filteredDataRow.Value);
						}

						//make sure it exists before trying to populate it
						if (controlValue[0] != null) {
							if (filteredDataRow.Type === "control") {
								//sets default for the control type version (dropdowns). this is a local variable we can bind right here (no callback or server query required)
								iDataUnit.SelectedControl = controlValue[0].ControlGUID;
							}
						}
					}

					if (filteredDataRow.Type === "autocomplete") {
						//we have a ControlGUID and ControlDisplay value in the data unit. ControlDisplay is already loaded across into ValueDisplay.
						//its important to make sure that a ControlGUID exists, otherwise it will try to retrieve a random value!
						if (!this.globalFunctions.isEmpty(filteredDataRow.ControlGUID)) {
							//since we do have a value, let's place it on the field that gets used for the updates
							iDataUnit.AutoCompleteControlGUID = filteredDataRow.ControlGUID;
							//we stored the controldisplay in valuedisplay already.
							iDataUnit.AutoCompleteControlDisplayValue.value = filteredDataRow.ValueDisplay;
							//let's stick an initial value for the last searched value. this is to ensure that the autocomplete will fire when it first changes.
							iDataUnit.AutoCompleteLastSearchedValue = filteredDataRow.ValueDisplay;
						}
						else {
							//we found no control guid. empty out the GUID
							iDataUnit.AutoCompleteControlGUID = "";
						}
					}

					iDataUnits.push(iDataUnit);
				}
			)
		}

		//we will need a new datarow to represent the row that we want to update, and place the dataunits (iDataUnits) we collected above into it.
		const iDataRow = {
			AccountID: chosenAccountID,
			GUID: chosenGUID,
			Entity: chosenEntity,
			DataUnits: iDataUnits,
			OverlayDataUnits: [],
			ShowOverlayDataUnits: false,
			ChildEntity: null,
			RequestType: RequestTypes.Update,
			isHidden: null,
			RowStyle: null,
			EntityName: chosenEntityName,
			EntityFriendlyName: chosenEntityName,
			DeleteLock: null,
			//for anchoring the child to a parent record
			Parent1GUID: chosenParent1GUID,
			ShowFilePicker: false,
			ControlDataType: null,
			ChildNavTabs: null,
			IsParentAccount: isParentAccount,
		};

		//creating documents needs a input box to select a file. add a param here so that the entity can determine whether or not to show it. make it visible for Account Enquiry.
		//console.log("EntityName", EntityName);
		//console.log("chosenGUID", chosenGUID);

		//Declare a local variable to store Autocomplete Link Type
		let autocompleteLinkType = "";

		if ((EntityName === "LoanDocuments" || EntityName === "LoanTaskDocuments" || customEntityTitleName == "Account Enquiry") && chosenGUID === "{NewGUID}") {
			iDataRow.ShowFilePicker = true;
		}

		//Check if chosen GUID is not a new GUID, i.e. we are modifying an entity
		if (chosenGUID !== "{NewGUID}") {
			//Check if current nav tab is LoanAssociations and Data Unit being clicked is Association Type
			if (!this.globalFunctions.isEmpty(this.CurrentNavItem) && !this.globalFunctions.isEmpty(this.dataUnitItem) && !this.globalFunctions.isEmpty(this.dataUnitItem.DataUnits)) {

				//CurrentNavItem consists of nav-entityname-tab, so let's look for the text "LoanAssociations" in CurrentNavItem using includes() string function
				if (this.CurrentNavItem.toUpperCase().includes("LOANASSOCIATIONS")) {

					//Get the Association Type data unit
					const duAssocType = this.dataUnitItem.DataUnits.filter(x => x.Name === "Association Type")[0];

					if (!this.globalFunctions.isEmpty(duAssocType)) {

						//Let's compare if Association Type is Portfolio/Fund
						if (duAssocType.Value === this.PortfolioAssociationTypeGUID) {

							//Set link type to Portfolio
							autocompleteLinkType = "Portfolio";
						}
					}
				}
			}
		}

		//some more processing on the data unit
		for (const key2 in iDataRow.DataUnits) {

			const value2 = iDataRow.DataUnits[key2];
			//console.log(value2);

			//Employees should be able to Create against the Private note field on LoanUserTaskNotes, and we should default it to true as well. This needs to be overridden from the default on the View model (as this defaults to HideCreate this field on the server)
			//grab the claim to work out if this is an AMAL Employee
			const AMALEmployeeClaim = this.store.ClientClaims.filter(x => x.Name === "AMALEmployee");

			//are we targeting the relevant column, and creating a new object
			if (!this.globalFunctions.isEmpty(AMALEmployeeClaim) && value2.Name === "Is Private Note?" && chosenGUID === "{NewGUID}") {

				//This is an AMAL Employee trying to create a newloan user task note. make the private note field visible, and default its value to false.
				value2.isHidden = false;
				value2.Value = 'false';
				value2.ValueDisplay = 'false';
			}
			else {
				//standard processing, utilize the server based defaults (HideCreate). remove any dataunits that we don't want to show while creating.
				if (value2.HideCreate === true && chosenGUID === "{NewGUID}") {
					//console.log("hiding this field: " + value2)
					value2.isHidden = true;
				}
			}

			//remove any dataunits that we don't want to show while editing.
			if ((value2.HideEdit === true) && chosenGUID != "{NewGUID}") {
				//console.log("hiding this field: " + value2)
				value2.isHidden = true;
			}

			//remove any existing mark tags, as this would distort it
			if (!this.globalFunctions.isEmpty(value2.ValueDisplay)) {
				value2.ValueDisplay = value2.ValueDisplay.replace(this.globalFunctions.customHighlightTagStart, '');
				value2.ValueDisplay = value2.ValueDisplay.replace(this.globalFunctions.customHighlightTagEnd, '');
				//console.log("searching inside value2.ValueDisplay: ", value2.ValueDisplay);
			}

			//in addition, check if AutoCompleteControlDisplayValue exists and fix that too
			if (!this.globalFunctions.isEmpty(value2.AutoCompleteControlDisplayValue) && !this.globalFunctions.isEmpty(value2.AutoCompleteControlDisplayValue.value)) {
				value2.AutoCompleteControlDisplayValue.value = value2.AutoCompleteControlDisplayValue.value.replace(this.globalFunctions.customHighlightTagStart, '');
				value2.AutoCompleteControlDisplayValue.value = value2.AutoCompleteControlDisplayValue.value.replace(this.globalFunctions.customHighlightTagEnd, '');
			}

		}

		//Flag to check whether the entity is minimizable or not
		let isMinimizableDialog = false;

		//Don't show minimize button on child entities and edit modal
		if (!this.globalFunctions.isEmpty(iDataRow) && !this.globalFunctions.ChildEntities.includes(iDataRow.Entity) && !this.globalFunctions.isEmpty(iDataRow.GUID) && iDataRow.GUID === "{NewGUID}") {

			//This is a create request, lets show the minimize button
			isMinimizableDialog = true;
		}

		//Target entity modal name
		let entityModalName = "";
		if (!this.globalFunctions.isEmpty(customEntityTitleName)) {
			entityModalName = customEntityTitleName;
		}
		else {
			entityModalName = iDataRow.EntityName;
		}

		//Check if it is nested dialog
		let isNestedDialog = false;
		let targetEntityName = EntityName;

		//Check if the iDataRow is empty
		if (!this.globalFunctions.isEmpty(iDataRow.Entity)) {
			targetEntityName = iDataRow.Entity;
		}

		//Check if the target entity is a child entity. The Notes/Documents being attached from a nested modal (SLAREporting ==>UserTasks or Dashboar ==> UserTask) should be rendered as a child entity and must rendered above all
		if (this.globalFunctions.ChildEntities.includes(targetEntityName)) {

			//This is a child entity
			isNestedDialog = true;
		}

		//Check if this loan entity modify is being launched in headless mode. invokingParent will have a parent component attached to it if invoked in headless mode.
		if (!this.globalFunctions.isEmpty(this.invokingParent)) {

			//This is being launched from SLA reporting or Dashboard (Headless mode)
			isNestedDialog = true;
		}

		//Create modal using Mat dialog service
		const updateMatDialogModal = this.globalFunctions.FeatureModal_Launch(LoanEntityModify, this.globalFunctions.GetFeatureModalConfig('40%'), this.dialog, entityModalName, this.AccountID, isNestedDialog, isMinimizableDialog);

		//Set the component inputs
		updateMatDialogModal.DialogRef.componentInstance.LoanIndex = this;
		updateMatDialogModal.DialogRef.componentInstance.DataRowModal = iDataRow;
		updateMatDialogModal.DialogRef.componentInstance.CustomConfirmMessage = customConfirmMessage;
		updateMatDialogModal.DialogRef.componentInstance.ShowMinimizedButton = isMinimizableDialog;
		updateMatDialogModal.DialogRef.componentInstance.AutoCompleteLinkType = autocompleteLinkType;

		//Change the save button text to "Submit", if this is a Account Enquiry
		if (showOverrideDueDateCheckBox) {
			updateMatDialogModal.DialogRef.componentInstance.SaveButtonText = "Submit";

			//Disable the Submit button until Due Date is set
			updateMatDialogModal.DialogRef.componentInstance.EnableSubmitButton = false;
		}

		//Set showOverrideDueDateCheckBox based on the claim permission passed to this method
		updateMatDialogModal.DialogRef.componentInstance.ShowOverrideDueDateCheckBox = showOverrideDueDateCheckBox;

		//Update the entity header name at the top of the page, if requested.
		if (!this.globalFunctions.isEmpty(customEntityTitleName)) {
			updateMatDialogModal.DialogRef.componentInstance.CustomEntityTitleName = customEntityTitleName;
		}
	}

	public CloseEntityEditModal(modalIdentifier) {

		//Close the modal
		this.globalFunctions.FeatureModal_Close(modalIdentifier);
	}

	//Test launching an entity modal
	public LaunchSingleEntityModal(customEntityHeaderName = '') {

		//This is always a Loan Task entity
		const entityName = 'LoanTasks';

		//We need to keep a ref to this modal, so that we can close it later
		const singleEntityFeatureModal = this.globalFunctions.FeatureModal_Launch(LoanEntity, this.globalFunctions.GetFeatureModalConfig('60%'), this.dialog, "Loan Entity", 0, true, false);

		//Set the identifier to be used later for closing
		this.SingleEntityModalIdentifier = singleEntityFeatureModal.GUID;

		//Now set the properties of the entity class
		//Be careful- anything set here can get overridden by ngoninit!
		singleEntityFeatureModal.DialogRef.componentInstance.loanIndex = this;
		singleEntityFeatureModal.DialogRef.componentInstance.EntityName = entityName;
		singleEntityFeatureModal.DialogRef.componentInstance.ShowHeader = true;
		singleEntityFeatureModal.DialogRef.componentInstance.ColumnsInHeader = 2;
		singleEntityFeatureModal.DialogRef.componentInstance.WrapOddRowsHeader = true;
		singleEntityFeatureModal.DialogRef.componentInstance.ColumnsInBody = 1;

		//Let's try having a single column in the nav tab
		singleEntityFeatureModal.DialogRef.componentInstance.ColumnsInTab = 1;
		singleEntityFeatureModal.DialogRef.componentInstance.CombineLabelAndData = true;
		singleEntityFeatureModal.DialogRef.componentInstance.EntityDict = this.EntityDict;

		//Hide a few things at the top right (no search filter box needed, or the Create New entity button)
		singleEntityFeatureModal.DialogRef.componentInstance.ShowSearchFilterBox = false;
		singleEntityFeatureModal.DialogRef.componentInstance.ShowCreateNewEntityButton = false;

		//Use new input property to force ngoninit to behave differently
		singleEntityFeatureModal.DialogRef.componentInstance.SingleEntityModalView = true;

		//Don't show download and refresh buttons
		singleEntityFeatureModal.DialogRef.componentInstance.ShowDownloadButton = false;
		singleEntityFeatureModal.DialogRef.componentInstance.ShowRefreshButton = false;

		//We want to show the close modal button, so that users can click to close this.
		singleEntityFeatureModal.DialogRef.componentInstance.ShowModalCloseButton = true;

		//Give it a padding around the edges
		singleEntityFeatureModal.DialogRef.componentInstance.AddBorderPadding = true;

		//This will fill the entity friendly name into the header (override handled in ngOnInit)
		singleEntityFeatureModal.DialogRef.componentInstance.NavDisplayNameCustom = customEntityHeaderName;

		//turn the loading spinner off
		this.store.SetShowFullscreenLoading(false);
	}

	//Close the launched single entity modal
	public CloseSingleEntityModal() {

		//Update the feature modal array and close the entity modal
		this.globalFunctions.FeatureModal_Close(this.SingleEntityModalIdentifier);
	}

	//track state of the update request, so that we can show the spinner, if necessary.
	public isUpdatingEntityRequest = false;

	//Save a record (update) request to the server
	public Entity_UpdateEntityRequest(dataRowModal: any, modalIdentifier: any, showOverrideDueDateCheckBox: boolean, customConfirmMessage = null): void {

		//Switch the spinner on
		this.isUpdatingEntityRequest = true;

		//Turn on the loading spinner
		this.store.SetShowFullscreenLoading(true);

		//Get the hidden data units that has ClientDisplay set to false
		const clientHidden = dataRowModal.DataUnits.filter(x => x.ClientDisplay === false);

		//Now loop through client/user driven hidden items
		clientHidden.forEach(item => {
			const targetDataUnit = dataRowModal.DataUnits.filter(x => x.Name === item.Name)[0];

			//Null check
			if (!this.globalFunctions.isEmpty(targetDataUnit)) {

				//Remove client/user driven hidden dataunit from the DataRowModal so that it doesn't get sent over to the server
				dataRowModal.DataUnits.splice(dataRowModal.DataUnits.indexOf(targetDataUnit), 1);
			}
		});

		//Remove the timezone on the date item that material calendar picker added on the UI
		Object.entries(dataRowModal.DataUnits).forEach(([rowkey,]) => {
			const item = dataRowModal.DataUnits[rowkey];
			if (item.Type == "shortdatetime") {
				if (!this.globalFunctions.isEmpty(item.Value)) {
					item.Value = moment(item.Value).format('YYYY-MM-DDTHH:mm:ss');
				}
			}
			else if (item.Type == "longdatetime") {
				if (!this.globalFunctions.isEmpty(item.ValueDate)) {

					//Convert the server datetime to ISO datetime format
					item.Value = moment(item.ValueDate, "DD/MM/YYYY HH:mm A").format('YYYY-MM-DDTHH:mm:ss');
				}
			}
		});

		//Deep clone the DataRowModal
		const newDataRow = JSON.parse(JSON.stringify(dataRowModal))

		let updateValidated = true;

		//And now update each data units value based on the type, values that were stored locally on client
		Object.entries(newDataRow.DataUnits).forEach(
			([rowkey,]) => {
				const item = newDataRow.DataUnits[rowkey];
				//console.log("newDataRow item: ", item);

				//for create requests, we can reject if a value is missing here
				if (newDataRow.GUID === '{NewGUID}' && this.globalFunctions.isEmpty(item.Value) &&
					//we should actually have mandatory on create as a setting on the view model. hmm. we can look it up here and check it.. or it might even be already available inside this data unit. 
					//this prevents a blank subject from being saved for a loan task document
					((newDataRow.Entity === 'LoanTaskDocuments' && item.Name === 'Subject')
						//this prevents a blank subject from being saved for a loan task note
						|| (newDataRow.Entity === 'LoanTaskNotes' && item.Name === 'Subject')
						//this prevents a blank subject from being saved for a Loan Document
						|| (newDataRow.Entity === 'LoanDocuments' && item.Name === 'Subject'))
				) {
					const message = item.Name + ' is missing a value'
					this.notifyService.Warning_Show(message, "Please enter a value");
					updateValidated = false;
				}

				if (item.Type === 'autocomplete') {
					item.Value = item.AutoCompleteControlGUID;
					//for create requests, we can reject if a value is missing here
				} else if (item.Type === 'control') {
					item.Value = item.SelectedControl;
					//console.log('newDataRow', newDataRow);
					//for create requests, we can reject if a value is missing here
					if (newDataRow.GUID === '{NewGUID}' && this.globalFunctions.isEmpty(item.Value)
						//we should actually have mandatory on create as a setting on the view model. hmm. we can look it up here and check it.. or it might even be already available inside this data unit.
						&& (item.Name === 'Task Type' || item.Name === 'Document Category')
					) {
						const message = item.Name + ' is missing a value'
						this.notifyService.Warning_Show(message, "Please choose an option");
						updateValidated = false;
					}
				}
			})

		//don't allow the update to continue if we didn't pass validation
		if (!updateValidated) {
			//switch the spinner off
			this.isUpdatingEntityRequest = false;

			//Turn the loading spinner off
			this.store.SetShowFullscreenLoading(false);
			return;
		}

		//check if this was a new document, and verify if a file was chosen, and if it is valid
		if (newDataRow.Entity === "LoanDocuments" && newDataRow.GUID === "{NewGUID}" && !this.FileUpload_IsChosen) {
			this.notifyService.Warning_Show("No file selected", "Please choose a file");
			//switch the spinner off
			this.isUpdatingEntityRequest = false;

			//Turn the loading spinner off
			this.store.SetShowFullscreenLoading(false);
			return;
		}
		else if (newDataRow.Entity === "LoanDocuments" && newDataRow.GUID === "{NewGUID}" && !this.FileUpload_IsValid) {
			this.notifyService.Warning_Show("Invalid file selected, please choose another file", "The file is not valid");
			//switch the spinner off
			this.isUpdatingEntityRequest = false;

			//Turn the loading spinner off
			this.store.SetShowFullscreenLoading(false);
			return;
		}
		else {
			//file was chosen, and it appears valid. check if there are any file contents that need to be appended to the request, and add it if so.
			if (!this.globalFunctions.isEmpty(this.FileUpload_FileBase64String)) {
				newDataRow.FileBase64 = this.FileUpload_FileBase64String;
				newDataRow.FileName = this.FileUpload_FileName;
			}
		}

		//test an invalid or expired token
		//this.store.loginDataDirect.AccessToken = "b9cc1b31-5bd4-4c33-b5a7-7bb2d4e25c46"

		//now its ready to send to the server
		//let apiRequest = any;
		//stringify to remove the root
		const apiRequest = JSON.parse(JSON.stringify(newDataRow));

		this.apiService
			.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.ModifyAccountEntity], apiRequest)
			.subscribe((response) => {
				if (this.globalFunctions.isEmpty(response)) {
					//Spinner off
					this.isUpdatingEntityRequest = false;

					//Turn the loading spinner off
					this.store.SetShowFullscreenLoading(false);

					return null;
				}

				this.Entity_ProcessUpdateResponse(response, modalIdentifier);

				//Spinner off
				this.isUpdatingEntityRequest = false;

				//Turn the loading spinner off
				this.store.SetShowFullscreenLoading(false);

				//Check if we need to show the additional confirmation dialog. Use the showOverrideDueDateCheckBox variable, as this is always true for account enquiries
				if (showOverrideDueDateCheckBox) {
					//Get the standard confirmer config
					const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.AccountID, true, false);

					//Default message that is displayed
					let confirmMessage = "Thanks for your enquiry. We're working on it.";

					//Check if they have the Lender Tasks claim, and adjust the confirmation text
					const lenderTasksClaimCheck = this.globalFunctions.Claim_VerifyPermission(this.store, "LenderTasks", "Read");

					//Check if they have the AccountEnquiryRedirect claim (e.g. GLS Mortgage Manager), to see if we need to adjust the confirmation text
					const accountEnquiryRedirectClaimCheck = this.globalFunctions.Claim_VerifyPermission(this.store, "AccountEnquiryRedirect", "Read");

					if (lenderTasksClaimCheck && !accountEnquiryRedirectClaimCheck) {
						//Here we can add some additional text, indicating that they can check their lender tasks on the dashboard
						confirmMessage = "Thanks for your enquiry. We're working on it and you can track it with Lender Tasks in your XCHANGE dashboard.";
					}

					//Use html content so that we can style it
					dialogRef.DialogRef.componentInstance.htmlContent = confirmMessage;

					//Hide the No Button
					dialogRef.DialogRef.componentInstance.ShowNoButton = false;

					//Change the Yes button text to "OK"
					dialogRef.DialogRef.componentInstance.YesButtonText = "Ok";
				}

				//Check if we need to show the additional confirmation dialog. Use the showOverrideDueDateCheckBox variable, as this is always true for account enquiries
				if (!this.globalFunctions.isEmpty(customConfirmMessage)) {
					//Get the standard confirmer config
					const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.AccountID, true, false);

					//Use html content so that we can style it
					dialogRef.DialogRef.componentInstance.htmlContent = customConfirmMessage;

					//Hide the No Button
					dialogRef.DialogRef.componentInstance.ShowNoButton = false;

					//Change the Yes button text to "OK"
					dialogRef.DialogRef.componentInstance.YesButtonText = "Ok";
				}

				//If we have any headless mode parent components, then fire the callback on them
				this.ParentComponent_RefreshTaskData();
			});
	}

	//Refresh any parent components that were linked to us
	public ParentComponent_RefreshTaskData(): void {
		if (!this.globalFunctions.isEmpty(this.invokingParent)) {
			//console.log('RefreshParentTaskData from invokingParent');

			//After any update, we should just refresh all child task component data
			this.invokingParent.ParentTaskData_Refresh();
		}
	}

	//delete a record request to the server
	public Entity_Delete(dataRow: DataRow): void {
		const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.AccountID, true, false);

		//use html content so that we can style it
		dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to delete this <b>" + dataRow.EntityName + "</b>?"

		dialogRef.DialogRef.afterClosed().subscribe(result => {
			if (result === true) {

				console.log("Delete subscription fired");
				//turn the global loading screen on
				this.store.SetShowFullscreenLoading(true);

				//the GUID tells the server which record we want to target
				const deleteRecordRequest: DataRow = {
					AccountID: dataRow.AccountID
					, GUID: dataRow.GUID
					, Entity: dataRow.Entity
					, DataUnits: null
					, OverlayDataUnits: null
					, ShowOverlayDataUnits: false
					, ChildEntity: null
					, isHidden: null
					, EntityName: null
					, EntityFriendlyName: null
					, RowStyle: null
					, RequestType: RequestTypes.Delete
					, DeleteLock: dataRow.DeleteLock
					, Parent1GUID: null
					, ShowFilePicker: null
					, ControlDataType: null
					, ChildNavTabs: null
					, IsParentAccount: dataRow.IsParentAccount
				};

				//stringify to remove the root
				const apiRequest = JSON.parse(JSON.stringify(deleteRecordRequest));

				//send the request to the server to fulfill
				this.apiService
					.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.ModifyAccountEntity], apiRequest)
					.subscribe((response) => {
						if (this.globalFunctions.isEmpty(response)) {
							//turn the global loading screen off
							this.store.SetShowFullscreenLoading(false);
							return;
						}
						this.Entity_ProcessUpdateResponse(response, dialogRef.GUID);
						//turn the global loading screen off
						this.store.SetShowFullscreenLoading(false);
					});
			}
		});
	}

	//processes the response from an update or delete request
	private Entity_ProcessUpdateResponse(apiResponse, modalIdentifier): void {
		const apiUpdateResponse: Entity = apiResponse;
		//now feed it to a method to do the actual update here locally on the client. this now also handles delete for us as well.
		this.Entity_UpdateLocal(apiUpdateResponse, true, modalIdentifier);
	}

	//returns the entity based on the name passed in, used for getting the value by ref when wanting to manipulate its entries
	private Entity_GetLocalValue(EntityName: string): DataRow[] {
		return this.EntityDict[EntityName].Entity;
	}

	//method to get the relevant parent entity
	private Entity_GetLocalParent(inputParam1: Entity, entityGUID: string = null, forceDelete = false) {
		//find the entity that we want to insert into, respecting any nested parents.
		let entityType = null;
		//console.log("Entity_GetLocalParent.inputParam1", inputParam1);

		let EntityGUID = "";
		if (this.globalFunctions.isEmpty(entityGUID)) {
			EntityGUID = inputParam1.EntityRows[0].EntityIdentifier.GUID;
		}
		else {
			EntityGUID = entityGUID;
		}

		//how do we deal with child entities? we need to know the parent entity, grab that, and traverse to the matching child.
		if (inputParam1.ParentEntityName2 === null && inputParam1.ParentEntityName != null) {
			//console.log("first child level lookup");
			const parentEntityType = this.Entity_GetLocalValue(inputParam1.ParentEntityName);
			const parentEntity = parentEntityType.find(x => x.GUID === inputParam1.ParentEntityGUID);

			//the child exists inside this.
			entityType = parentEntity.ChildEntity;
			//console.log('parentEntity', parentEntity);

			//this could be located inside a child nav tab. let's look for it in there and update it too.
			//its inside parentEntity.ChildNavTabs
			const navTabEntityParent = parentEntity.ChildNavTabs.filter(x => x.EntityName === inputParam1.EntityType);
			if (!this.globalFunctions.isEmpty(navTabEntityParent)) {
				//console.log('found a navTabEntityParent', navTabEntityParent);

				let matchingDataRow = null;

				let foundIndex = 0;
				//loop through and look for a matching entity in the child nav tabs
				for (const navTabParentEntity of navTabEntityParent[0].ChildNavTabEntities) {
					//console.log('p.ProcessedChildNavTabEntity', p.ProcessedChildNavTabEntity);

					if (!this.globalFunctions.isEmpty(navTabParentEntity.ProcessedChildNavTabEntity) && navTabParentEntity.ProcessedChildNavTabEntity[0].GUID === EntityGUID) {
						//we found one - most likely for deleting purposes. grab it and stop this for loop
						//console.log('navTabParentEntity', navTabParentEntity);

						matchingDataRow = navTabParentEntity.ProcessedChildNavTabEntity;
						if (forceDelete) {
							//on the html template, we lose 'active' and 'show' on this item. This means that we lose that this was the active tab.
							//let's set on the navTabEntityParent[0] some property that let's the template know that, and instead of injecting bland, puts the 'active' and 'show' back. so flip the WasUpdated property to true
							navTabEntityParent[0].WasUpdated = true;

							//just delete it now, if we were asked to remove it. deleting this content is a few parents up, so easier to just get rid of it now. otherwise the html template for LoanEntity won't update the count properly (ChildNavTabEntities.length)
							navTabEntityParent[0].ChildNavTabEntities.splice(foundIndex, 1);
						}
						break;
					}
					//increment the index
					foundIndex = foundIndex + 1;
				}

				//if we didn't find one, then let's initialize it (probably a create request)
				if (this.globalFunctions.isEmpty(matchingDataRow)) {
					//console.log('no matching data row. creating a new one');

					//create a new array to store the data row from the server
					matchingDataRow = [];

					//and initialize an entity record to hold it againt, injecting the matching data row.
					const newEntity = { EntityType: inputParam1.EntityType, ProcessedChildNavTabEntity: matchingDataRow };

					//now push this record into ChildNavTabEntities
					navTabEntityParent[0].ChildNavTabEntities.unshift(newEntity);

					//on the html template, we lose 'active' and 'show' on this item. This means that we lose that this was the active tab.
					//let's set on the navTabEntityParent[0] some property that let's the template know that, and instead of injecting bland, puts the 'active' and 'show' back. so flip the WasUpdated property to true
					navTabEntityParent[0].WasUpdated = true;
				}

				//console.log('navTabEntityParent', navTabEntityParent);
				//console.log('matchingDataRow', matchingDataRow);
				entityType = matchingDataRow;
			}
		}
		else if (inputParam1.ParentEntityName2 != null && inputParam1.ParentEntityName != null) {
			//we have a entity that is layered two levels down. need to traverse to it properly. start by going to the first parent
			const parentEntityType = this.Entity_GetLocalValue(inputParam1.ParentEntityName);
			const parentEntity = parentEntityType.find(x => x.GUID === inputParam1.ParentEntityGUID);

			//now the level 1 child exists inside this.
			//interesting behaviour here. i found that the child entities at this level (client) can be repeated sometimes. e.g. when a loan has the same client for different links, e.g. same lender, mortgage manager and Funder.
			//to allow the client to update all linked clients records, we should have the server send us an array of all link records to update. this way we get all matching client records. TODO
			const entitylevel1 = parentEntity.ChildEntity.find(x => x.Entity === inputParam1.ParentEntityName2 && x.GUID === inputParam1.ParentEntityGUID2);

			//keep going one more level down. the level 2 child exists inside this.
			entityType = entitylevel1.ChildEntity;
			//entityType = entitylevel1.ChildEntity.filter(x => x.GUID === EntityGUID);

			//this could be located inside a child nav tab. let's look for it in there and update it too.
			//its inside parentEntity.ChildNavTabs
			const navTabEntityParent = parentEntity.ChildNavTabs.filter(x => x.EntityName === inputParam1.EntityType);
			if (!this.globalFunctions.isEmpty(navTabEntityParent)) {
				//console.log('found a navTabEntityParent', navTabEntityParent);

				let matchingDataRow = null;

				//loop through and look for a matching entity in the child nav tabs
				let foundIndex = 0;
				for (const navTabParentEntity of navTabEntityParent[0].ChildNavTabEntities) {
					//console.log('p.ProcessedChildNavTabEntity', p.ProcessedChildNavTabEntity);

					if (!this.globalFunctions.isEmpty(navTabParentEntity.ProcessedChildNavTabEntity) && navTabParentEntity.ProcessedChildNavTabEntity[0].GUID === EntityGUID) {
						//we found one - most likely for deleting purposes. grab it and stop this for loop
						//console.log('navTabParentEntity', navTabParentEntity);
						matchingDataRow = navTabParentEntity.ProcessedChildNavTabEntity;
						if (forceDelete) {
							//on the html template, we lose 'active' and 'show' on this item. This means that we lose that this was the active tab.
							//let's set on the navTabEntityParent[0] some property that let's the template know that, and instead of injecting bland, puts the 'active' and 'show' back. so flip the WasUpdated property to true
							navTabEntityParent[0].WasUpdated = true;

							//just delete it now, if we were asked to remove it. deleting this content is a few parents up, so easier to just get rid of it now. otherwise the html template for LoanEntity won't update the count properly (ChildNavTabEntities.length)
							navTabEntityParent[0].ChildNavTabEntities.splice(foundIndex, 1);
						}
						break;
					}
					//increment the index
					foundIndex = foundIndex + 1;
				}

				//if we didn't find one, then let's initialize it (probably a create request)
				if (this.globalFunctions.isEmpty(matchingDataRow)) {
					//console.log('no matching data row. creating a new one');

					//create a new array to store the data row from the server
					matchingDataRow = [];

					//and initialize an entity record to hold it againt, injecting the matching data row.
					const newEntity = { EntityType: inputParam1.EntityType, ProcessedChildNavTabEntity: matchingDataRow };

					//now push this record into ChildNavTabEntities
					navTabEntityParent[0].ChildNavTabEntities.unshift(newEntity);

					//on the html template, we lose 'active' and 'show' on this item. This means that we lose that this was the active tab.
					//let's set on the navTabEntityParent[0] some property that let's the template know that, and instead of injecting bland, puts the 'active' and 'show' back. so flip the WasUpdated property to true
					navTabEntityParent[0].WasUpdated = true;
				}

				//console.log('navTabEntityParent', navTabEntityParent);
				//console.log('matchingDataRow', matchingDataRow);
				entityType = matchingDataRow;
			}

		}
		else {
			//root level, easy lookup, matches the entity name
			//console.log("root level lookup");
			//console.log("inputParam1.EntityType", inputParam1.EntityType);
			entityType = this.Entity_GetLocalValue(inputParam1.EntityType);
		}

		return entityType;
	}

	//a method to update local copies of the data that the server has updated
	public Entity_UpdateLocal(apiUpdateResponse: Entity, showToast = true, modalIdentifier): void {

		//Null check before processing
		if (this.globalFunctions.isEmpty(apiUpdateResponse)) {
			return;
		}

		//if we were supplied any new entities, lets insert them first.
		if (apiUpdateResponse.Entities != null && apiUpdateResponse.Entities.length > 0) {
			this.Entity_InsertNew(apiUpdateResponse.Entities);
		}

		//check what kind of request type we were given to perform the appropriate action
		if (apiUpdateResponse.RequestType === RequestTypes.Update) {
			//find the entity that we want to update.
			let entityType = null;
			let entity = null;

			//how do we deal with child entities? we need to know the parent entity, grab that, and traverse to the matching child.
			if (apiUpdateResponse.ParentEntityName2 === null && apiUpdateResponse.ParentEntityName != null) {
				const parentEntityType = this.Entity_GetLocalValue(apiUpdateResponse.ParentEntityName);
				//console.log("parentEntityType: ", parentEntityType);
				const parentEntity = parentEntityType.find(x => x.GUID === apiUpdateResponse.ParentEntityGUID);
				//console.log("parentEntity: ", parentEntity);
				//now the child exists inside this.
				entity = parentEntity.ChildEntity.find(x => x.GUID === apiUpdateResponse.EntityGUID);
				//for the deletion, we need to keep an handle on the direct parent of the element we are deleting. use entityType to establish that
				entityType = parentEntity.ChildEntity;

				//this could be located inside a child nav tab. let's look for it in there and update it too. similar to how Entity_GetLocalValue is working
				//console.log('parentEntity', parentEntity);
				//its inside parentEntity.ChildNavTabs
				const navTabEntityParent = parentEntity.ChildNavTabs.filter(x => x.EntityName === apiUpdateResponse.EntityType);
				if (!this.globalFunctions.isEmpty(navTabEntityParent)) {
					//console.log('found a navTabEntityParent', navTabEntityParent);

					let matchingDataRow = null;
					for (const navTabParentEntity of navTabEntityParent[0].ChildNavTabEntities) {
						//console.log('navTabParentEntity.ProcessedChildNavTabEntity', navTabParentEntity.ProcessedChildNavTabEntity);

						//we found one - most likely for updating purposes. grab it and stop this for loop
						if (!this.globalFunctions.isEmpty(navTabParentEntity.ProcessedChildNavTabEntity) && navTabParentEntity.ProcessedChildNavTabEntity[0].GUID === apiUpdateResponse.EntityGUID) {
							matchingDataRow = navTabParentEntity.ProcessedChildNavTabEntity;
							break;
						}
					}

					//console.log('matchingDataRow', matchingDataRow);
					entityType = matchingDataRow;
					entity = matchingDataRow[0];
				}

			}
			else if (apiUpdateResponse.ParentEntityName2 != null && apiUpdateResponse.ParentEntityName != null) {
				//console.log("level 2 child search");
				//console.log("apiUpdateResponse", apiUpdateResponse);

				//we have a entity that is layered two levels down. need to traverse to it properly.
				const parentEntityType = this.Entity_GetLocalValue(apiUpdateResponse.ParentEntityName);
				//console.log("parentEntityType: ", parentEntityType);
				const parentEntity = parentEntityType.find(x => x.GUID === apiUpdateResponse.ParentEntityGUID);
				//console.log("parentEntity: ", parentEntity);
				//now the level 1 child exists inside this.
				//interesting behaviour here. i found that the child entities at this level (client) can be repeated sometimes.
				//e.g. when a loan has the same client for different links, e.g. same lender, mortgage manager and Funder.
				//to allow the client to update all linked clients records, we should have the server send us an array of all link records to update.
				//this way we get all matching client records. TODO

				const entitylevel1 = parentEntity.ChildEntity.find(x => x.Entity === apiUpdateResponse.ParentEntityName2 && x.GUID === apiUpdateResponse.ParentEntityGUID2);
				//console.log("entitylevel1: ", entitylevel1);

				//keep going one more level down
				//the level 2 child exists inside this.
				entity = entitylevel1.ChildEntity.find(x => x.Entity === apiUpdateResponse.EntityType && x.GUID === apiUpdateResponse.EntityGUID);
				//for the deletion, we need to keep an handle on the direct parent of the element we are deleting. use entityType to establish that
				entityType = entitylevel1.ChildEntity;

				//this could be located inside a child nav tab. let's look for it in there and update it too. similar to how Entity_GetLocalValue is working
				//console.log('parentEntity', parentEntity);
				//its inside parentEntity.ChildNavTabs
				const navTabEntityParent = parentEntity.ChildNavTabs.filter(x => x.EntityName === apiUpdateResponse.EntityType);
				if (!this.globalFunctions.isEmpty(navTabEntityParent)) {
					//console.log('found a navTabEntityParent', navTabEntityParent);

					let matchingDataRow = null;
					for (const navTabParentEntity of navTabEntityParent[0].ChildNavTabEntities) {
						//console.log('navTabParentEntity.ProcessedChildNavTabEntity', navTabParentEntity.ProcessedChildNavTabEntity);

						//we found one - most likely for updating purposes. grab it and stop this for loop
						if (!this.globalFunctions.isEmpty(navTabParentEntity.ProcessedChildNavTabEntity) && navTabParentEntity.ProcessedChildNavTabEntity[0].GUID === apiUpdateResponse.EntityGUID) {
							matchingDataRow = navTabParentEntity.ProcessedChildNavTabEntity;
							break;
						}
					}

					//console.log('matchingDataRow', matchingDataRow);
					entityType = matchingDataRow;
					entity = matchingDataRow[0];
				}
			}
			else {
				//single level, easy lookup, match the GUID.
				entityType = this.Entity_GetLocalValue(apiUpdateResponse.EntityType);
				entity = entityType.find(x => x.GUID === apiUpdateResponse.EntityGUID);
			}
			//console.log("entity: ", entity);
			//console.log("EntityProperty: ", EntityProperty);

			//now update all the data units inside this entity
			//console.log("DataUnits to update: ", apiUpdateResponse.DataUnits);
			if (!this.globalFunctions.isEmpty(apiUpdateResponse.DataUnits) && apiUpdateResponse.DataUnits.length > 0) {
				Object.entries(apiUpdateResponse.DataUnits).forEach(
					([, value]) => {
						const dataitem = entity.DataUnits.find(x => x.Name === value.Name)
						//console.log("value from server: ", value);
						//console.log("dataitem: ", dataitem);

						dataitem.CSSClass = value.CSSClass;

						//and update its ControlGUID value, dependant on whether or not we have a guid returned by the server.
						if (!this.globalFunctions.isEmpty(value.ControlGUID)) {
							dataitem.ControlGUID = value.ControlGUID;
							dataitem.Value = value.Value;
						}
						else {
							dataitem.Value = value.Value;
							//no control GUID. so blank it out. needed to ensure that client side controls get fresh updates and decorate the input boxes/options properly.
							dataitem.ControlGUID = "";
						}

						//customDataTypeParser now handles unescaping
						dataitem.ValueDisplay = this.globalFunctions.customDataTypeParser(value.Value, dataitem.Type);
						//why don't we have to unescape dataunit.Value too? because the loan entity model only shows the valuedisplay. only when launching the loanentitymodify modal do we care about modifying this so that the user can edit it in its raw form.
					})
			}

			//Update doesn't have a confirm flow, and still showing the Modal. So hide the modal now.
			this.CloseEntityEditModal(modalIdentifier);
			this.notifyService.Success_Show(apiUpdateResponse.ResponseMessage, "Record Updated")
			//remove the selected data unit
			this.DataUnit_RemoveSelected();
		}
		else if (apiUpdateResponse.RequestType === RequestTypes.Delete) {
			//find the relevant parent entity
			//console.log("Entity_UpdateLocal.apiUpdateResponse", apiUpdateResponse);
			const foundEntity = this.Entity_GetLocalParent(apiUpdateResponse, apiUpdateResponse.EntityGUID, true);
			//console.log('foundEntityPre', foundEntity);

			//if its an array, look for an index with matching entityGUID
			if (!this.globalFunctions.isEmpty(foundEntity) && Array.isArray(foundEntity)) {
				//now get its actual index
				const entityIndex = foundEntity.findIndex(x => x.GUID === apiUpdateResponse.EntityGUID)
				//and use splice to remove it
				foundEntity.splice(entityIndex, 1);
			}
			else {
				//not an array. might be a child nav tab. which was force deleted by the call to Entity_GetLocalParent, so nothing more to do here	
			}

			//sync the count so that the no results section will hide or show as needed. but not for child entities.
			if (this.globalFunctions.isEmpty(apiUpdateResponse.ParentEntityGUID)) {
				this.EntityDict[apiUpdateResponse.EntityType].Count = this.EntityDict[apiUpdateResponse.EntityType].Count - 1;
			}

			//deleting an entity means that we can't display that data unit anymore. this will remove the action bar.
			this.DataUnit_RemoveSelected();

			//deleting an entity doesn't have any other display issues (not rendering any new content). nothing more required
			this.notifyService.Success_Show(apiUpdateResponse.ResponseMessage, "Record Deleted")
		}
		else if (apiUpdateResponse.RequestType === RequestTypes.Create) {
			//Update doesn't have a confirm flow, and still showing the Modal. So hide the modal now.
			this.CloseEntityEditModal(modalIdentifier);

			if (showToast) {
				this.notifyService.Success_Show(apiUpdateResponse.ResponseMessage, "Record Created");
			}
			//remove the selected data unit
			this.DataUnit_RemoveSelected();
		}

		//UI display sync after CRUD operation, eg. hide/show insert button on Bank Details
		if (!this.globalFunctions.isEmpty(this.LoanEntityScripts)) {
			const entityComponent = this.LoanEntityScripts.filter(component => component.EntityName == apiUpdateResponse.EntityType)[0];
			if (!this.globalFunctions.isEmpty(entityComponent)) {

				//Sync the display of add new entity button
				entityComponent.InsertNewEntityButton_Sync();
			}
		}
	}

	//method to insert a new element inside a specific entity, used when we have an update back from the server
	private Entity_InsertNew(Entities: Entity[]) {
		//loop through each entity
		Object.entries(Entities).forEach(
			([, value]) => {
				//find the matching entity on the client side. need to deal with child entities.
				const chosenArray = this.Entity_GetLocalParent(value);
				//console.log('chosenArray', chosenArray);

				//loop through each entity row here, parse it as a client side data row, same as before. and then push it into the chosenArray (which contains client side DataRow objects). use push location from server to know how to push it.  we don't know the sorting order, so let the next refresh re-sort the data on server before being sent to client again.
				//before we push it in though, it may already exist. so let's remove any matching GUID rows.
				const existingItem = chosenArray.findIndex(x => x.GUID === value.EntityRows[0].EntityIdentifier.GUID)
				//console.log('existingItem', existingItem);

				//if we found it, can we just replace it? let's hand the index over to parse data rows and let that deal with it.
				this.globalFunctions.Entity_ParseIntoDataRows(this.AccountID, value, chosenArray, value.EntityType, value.PushLocation, existingItem);
				//console.log('chosenArray after update', chosenArray);

				//to ensure that the no results section is removed/synced, we need to update this entity's count. but only a root entity, not children.
				if (this.globalFunctions.isEmpty(value.ParentEntityGUID)) {
					this.EntityDict[value.EntityType].Count = this.EntityDict[value.EntityType].Count + 1;
				}
			})
	}

	//store the last clicked data unit (target)
	private lastClickedDataUnit: HTMLInputElement;
	//store the last clicked parent data row
	private lastClickedParentDataRow: HTMLElement;
	private lastClickedParentGUID: string;
	//store the item and selected dataunit label name of this selected data unit
	public dataUnitItem: DataRow;
	public dataUnitLabelName: string;
	public dataUnitValue: string;

	//should we show the context bar
	public showContextBar = false;
	//showing or hiding the individual buttons inside the context bar
	public showEditButton: boolean;
	//sometimes we want to hide the clone button
	public showCloneObjectButton: boolean;
	public showEditObjectButton: boolean;
	public showDeleteButton: boolean;
	public showDocumentLaunchButton: boolean;
	public showDocumentViewButton: boolean;

	//method to recurse up to the parent datarow
	public GetParentDataRow(item: HTMLElement): HTMLElement {
		//have to declare a variable to hold the result
		let result;
		if (item.classList.contains('ParentDataRow')) {
			//console.log("found a parentElement!", item);
			result = item;
		}
		else {
			//when doing recursive function calls in Typescript, remember to pass the result down. otherwise we get 'undefined'
			result = this.GetParentDataRow(item.parentElement);
		}
		//result has to be returned from the first caller (recursive calls cannot return result directly)
		return result;
	}

	//clicking a data unit
	public DataUnit_Clicked(event: Event, item, labelName, parentGUID: string = null): void {

		const eventTarget = event.currentTarget as HTMLInputElement;

		//console.log('event.currentTarget: ', event.currentTarget);
		//console.log('event.currentTarget.classList: ', event.currentTarget.classList);
		//console.log("clicked item: ", item);

		//throwing an error test
		//throw Error("Something went wrong dude");

		//set the last clicked parent GUID
		this.lastClickedParentGUID = parentGUID;

		//use a temp class to indicate that we are selecting this.
		eventTarget.classList.add('newSelection');
		//can we try to get the entire row, and highlight that too? use a recursive function to find it
		//console.log('event.currentTarget.parentElement', event.currentTarget.parentElement);
		const parentDataRow = this.GetParentDataRow(eventTarget.parentElement);
		//console.log("parentDataRow: ", parentDataRow);
		//now that we have the parent data row, let's also give it a selected class
		parentDataRow.classList.add('newSelection');

		if (!parentDataRow.classList.contains('selectedParentDataRow')) {
			parentDataRow.classList.add('selectedParentDataRow');

			//remove it from the prior one, if this wasn't the same item.
			if (this.lastClickedParentDataRow != null) {
				//use the temp css newSelection as a variable to check if this item is the same
				if (!this.lastClickedParentDataRow.classList.contains('newSelection')) {
					this.lastClickedParentDataRow.classList.remove('selectedParentDataRow');
				}
			}
		}
		else {
			//it already contains it. leave it
			//parentDataRow.classList.remove('selectedParentDataRow');
		}

		//now set the local variable to this selected dataunit target, and remove the temp css (newSelection) we used
		parentDataRow.classList.remove('newSelection');
		//this.lastClickedTarget = event.target;
		//current target is better. represents the parent object that contains the click handler, easier to direct our class changes here.		
		this.lastClickedParentDataRow = parentDataRow;

		//testing
		//let firstChild = event.currentTarget.firstChild;
		//console.log('firstChild', firstChild);
		//give this the newSelection temp class too
		//firstChild.classList.add('newSelection');

		//now, only add the highlight if this was NOT selected before.
		if (!eventTarget.classList.contains('glb_highlightSelectedDataUnit')) {
			eventTarget.classList.add('glb_highlightSelectedDataUnit');
			//firstChild.classList.add('glb_highlightSelectedDataUnit');

			this.dataUnitItem = item;
			this.dataUnitLabelName = labelName;
			const dataUnit = item.DataUnits.filter(x => x.Name === labelName);
			//this is used by the clipboard
			this.dataUnitValue = dataUnit[0].ValueDisplay;

			//are we actually allowed to edit this data unit? let's check before we enable the context bar.
			//this depends on the edit lock field. we could pull this out from the data unit.
			//first hide all buttons
			this.showEditButton = false;
			//default the clone object button to true
			this.showCloneObjectButton = true;
			this.showContextBar = false;
			this.showDeleteButton = false;
			this.showDocumentLaunchButton = false;
			this.showDocumentViewButton = false

			//track if we have a single button that should allow the context bar to appear. hmm. we may not want this. we want to show it always, since the copy to clipboard is globally available. so just set the context bar to always true
			this.showContextBar = true;

			//track if the server is telling us something is edit locked.
			let editLockForObject = false;

			//count how many fields are editable before we decide to show the edit object button
			const editFieldCount = item.DataUnits.filter(x => x.EditLock === false).length;
			if (!this.globalFunctions.isEmpty(editFieldCount) && editFieldCount > 0) {
				//at least one editable field exists. disable edit lock on the object
				editLockForObject = false;
			}
			else {
				//no editable fields exists. edit lock is active
				editLockForObject = true;
			}

			//what if the role of the user doesn't allow this data unit to be edited? let's find out by inspecting their claims.
			//console.log('this.store.ClientClaims', this.store.ClientClaims);
			//console.log('item', item);

			//find the matching entity claim, if it exists.
			const matchingEntityClaim = this.GetEntityClaim(item.EntityFriendlyName);

			//now we inspect the Edit value on this role claim, and combine it with the Edit Lock on the data units of the entire object.			
			if (!editLockForObject && matchingEntityClaim.Edit === true) {
				//there is at least one editable fields, and the user can edit the entity, so lets allow it.
				this.showEditObjectButton = true;
			}
			else {
				//no editable fields exists, and the user can't edit them
				this.showEditObjectButton = false;
			}

			//now the edit single field button. compare both edit lock and the claim Edit flag.
			if (dataUnit[0].EditLock === false && matchingEntityClaim.Edit === true) {
				//let's switch edit button on here
				this.showEditButton = true;
			}
			else {
				//either its edit locked, or the user doesn't have permission to edit it. let's switch edit button off here
				this.showEditButton = false;
			}

			//If the dataunit is not editable, Check the editclaim on the individual entity property
			if (this.showEditButton === false && matchingEntityClaim.Read === true && !editLockForObject) {
				const matchingEntityPropertyClaim = this.EntityPropertyClaim_Get(dataUnit[0].Name);

				//now the edit single field button. compare both edit lock and the claim Edit flag.
				if (!this.globalFunctions.isEmpty(matchingEntityPropertyClaim) && dataUnit[0].EditLock === false && matchingEntityPropertyClaim.Edit === true) {
					//let's switch edit button on here
					this.showEditButton = true;
					//This DataUnit property is editable, so lets allow editing the entire object.
					this.showEditObjectButton = true;
				}
			}

			//Check if the target data row is of type: PivotedEntities
			if (!this.globalFunctions.isEmpty(this.store.PivotedEntities.filter(x => x === item.Entity)[0])) {

				if (dataUnit[0].EditLock === true) {

					//Let's switch edit button on here
					this.showEditButton = false;

					//This DataUnit property is editable, so lets allow editing the entire object.
					this.showEditObjectButton = false;

				}

				else {
					//Get the matching entity property filter
					const matchingEntityPropertyFilterClaim = this.EntityPropertyFilterClaim_Get(item.EntityName);

					//Check if the matching entity property filter claim exists
					if (!this.globalFunctions.isEmpty(matchingEntityPropertyFilterClaim)) {

						//Get the target data unit label
						const targetDataUnit = item.DataUnits.filter(x => x.Type.toUpperCase() === "CONTROL" || x.Type.toUpperCase() === "AUTOCOMPLETE")[0];

						let targetDataUnitName = "";

						//Get the target data unit label name
						if (!this.globalFunctions.isEmpty(targetDataUnit)) {
							targetDataUnitName = targetDataUnit.ValueDisplay;
						}

						//Continue if we find the valid data unit
						if (!this.globalFunctions.isEmpty(targetDataUnitName)) {

							//Check if it is allowed to edit (Whitelisted)
							if (!this.globalFunctions.isEmpty(matchingEntityPropertyFilterClaim.EntityPropertyFilterClaim.EntityEditWhitelistFilter)) {
								if (!this.globalFunctions.isEmpty(matchingEntityPropertyFilterClaim.EntityPropertyFilterClaim.EntityEditWhitelistFilter.split(',').filter(x => x === targetDataUnitName)[0])) {

									//Let's switch edit button on here
									this.showEditButton = true;

									//This DataUnit property is editable, so lets allow editing the entire object.
									this.showEditObjectButton = true;
								}
								else {
									//Let's switch edit button on here
									this.showEditButton = false;

									//This DataUnit property is editable, so lets allow editing the entire object.
									this.showEditObjectButton = false;
								}
							}
							else {

								//Let's switch edit button on here
								this.showEditButton = false;

								//This DataUnit property is editable, so lets allow editing the entire object.
								this.showEditObjectButton = false;
							}
						}
					}
				}
			}

			//now the clone object button. compare both supportscloning, and the claim Insert flag.
			//console.log('this.store.EntityTypes', this.store.EntityTypes);
			let entityType = this.store.EntityTypes.filter(x => x.FriendlyName === item.EntityFriendlyName)[0];
			//if its not found, fall back to to the All entity type.
			if (this.globalFunctions.isEmpty(entityType)) {
				entityType = this.store.EntityTypes.filter(x => x.FriendlyName.toUpperCase() === "All")[0];
			}

			if (entityType.SupportsCloning === true && matchingEntityClaim.Insert === true) {
				//let's switch the button on
				this.showCloneObjectButton = true;
			}
			else {
				//either its doesnt support cloning, or the user doesn't have permission to insert to this entity. let's switch the button off here
				this.showCloneObjectButton = false;
			}

			//now the delete object button. compare both delete lock and the claim Delete flag.
			if (item.DeleteLock === false && matchingEntityClaim.Delete === true) {
				//let's switch the button on here
				this.showDeleteButton = true;
			}
			else {
				//either its delete locked, or the user doesn't have permission to delete it. let's switch the button off here
				this.showDeleteButton = false;
			}

			//check if the document launch button should be shown
			if (item.Entity === "LoanDocuments" || item.Entity === "LoanTaskDocuments") {
				this.showDocumentLaunchButton = true;
				//check the filename extension, and only show the view doc button when its on the supported list for DocViewer, e.g. doc, xls, jpg, png.

				//Look for the File Type data unit.
				const fileType = item.DataUnits.filter(x => x.Name === 'File Type')[0];

				//Continue processing if it exists
				if (!this.globalFunctions.isEmpty(fileType) && !this.globalFunctions.isEmpty(fileType.ValueDisplay)) {

					//Now grab the file type value
					const fileTypeValue = fileType.ValueDisplay.toUpperCase();

					//Run the tests against every element in the array
					const fileTypeCheck = this.globalFunctions.AllowedFileExtensions.some(el => fileTypeValue.includes(el.toUpperCase()));

					if (fileTypeCheck) {

						//Allow the view button to appear
						this.showDocumentViewButton = true;
					}
				}
			}

			//i want to try and send the user to a new account page, if a account ID was clicked, from the LinkedAccounts Entity. let's try it here
			//console.log('item', item);

			if (item.Entity === 'LinkedAccounts') {
				//console.log('dataUnit', dataUnit);

				//find the Data Unit with the AccountID inside it. if it is the accountID, and it is not equal to the item we are looking at now
				if (dataUnit[0].Name === 'Account ID' && dataUnit[0].Value != this.AccountID) {

					//not going to bother with sending this into the 'last clicked' client side list. just update the data store with this new account. make a new object that looks like the search result object.
					//this is needed to set the values in the loan header					
					const loanStatus = item.DataUnits.filter(x => x.Name === 'Account Status')[0].ValueDisplay;
					const principalBalance = item.DataUnits.filter(x => x.Name === 'Principal Balance')[0].Value;
					const productName = item.DataUnits.filter(x => x.Name === 'Product Name')[0].ValueDisplay;
					const principalBorrower = item.DataUnits.filter(x => x.Name === 'Principal Borrower')[0].Value;
					const clickedAccount = { AccountID: dataUnit[0].Value, AccountID_NoHighlights: dataUnit[0].Value, LoanStatus: loanStatus, PrincipalBalance: principalBalance, ProductName: productName, PrincipalBorrower: principalBorrower }
					//console.log('clickedAccount: ' + clickedAccount);

					//Set this flag to true to avoid loading this clicked account under same loan index page due to subscription
					//This is to fix the hitting of api twice on navigating to the linked account
					this.LinkedAccountClicked = true;

					//feed it to the data store
					this.store.SetSelectedAccount(clickedAccount);

					//let's send them to that account index page
					//console.log('sending user to: ' + dataUnit[0].Value);
					const targetURL = [NavigationUrls.Account.replace(':AccountID', dataUnit[0].Value)];
					//console.log('sending user to url: ' + targetURL);
					this.router.navigate(targetURL);

					return;
				}
			}

			//remove it from the prior one, if this wasn't the same item.
			if (this.lastClickedDataUnit != null) {
				//use the temp css newSelection as a variable to check if this item is the same
				if (!this.lastClickedDataUnit.classList.contains('newSelection')) {
					this.lastClickedDataUnit.classList.remove('glb_highlightSelectedDataUnit');
				}
			}
		}
		else {
			//it already contains it. the same data unit was clicked! remove it!
			eventTarget.classList.remove('glb_highlightSelectedDataUnit');
			//firstChild.classList.remove('glb_highlightSelectedDataUnit');
			//remove it from the parent too
			parentDataRow.classList.remove('selectedParentDataRow');
			this.showContextBar = false;
		}

		//now set the local variable to this selected dataunit target, and remove the temp css (newSelection) we used
		eventTarget.classList.remove('newSelection');
		//firstChild.classList.remove('newSelection');
		//this.lastClickedTarget = event.target;
		//current target is better. represents the parent object that contains the click handler, easier to direct our class changes here.		
		this.lastClickedDataUnit = eventTarget;
		//this.lastClickedDataUnit = firstChild;
	}

	//removes the currently selected data unit target (the one that exists purely for editing and saving)
	public DataUnit_RemoveSelected() {
		if (this.lastClickedDataUnit != null) {
			//if it contains the selectedDataUnit, remove it.
			if (this.lastClickedDataUnit.classList.contains('glb_highlightSelectedDataUnit')) {
				this.lastClickedDataUnit.classList.remove('glb_highlightSelectedDataUnit');
				//also remove the parent data row highlight
				if (this.lastClickedParentDataRow != null) {
					this.lastClickedParentDataRow.classList.remove('selectedParentDataRow')
				}
			}
			//also, hide the context bar
			this.showContextBar = false;
		}

		//Reset last clicked parent guid
		this.lastClickedParentGUID = null;

	}

	//gets the matching entity claim, or the ALL claim as a fallback
	public GetEntityClaim(entityName: string) {
		//find the matching entity claim, if it exists. match either Name or NameFriendly, e.g. Name: "Entities" or NameFriendly: "Entity"
		let matchingClaim = this.store.ClientClaims.filter(x => x.Name === "Entities" && x.EntityClaim.EntityFriendlyName === entityName);
		//console.log('matchingClaim', matchingClaim);

		if (!this.globalFunctions.isEmpty(matchingClaim)) {
			//console.log('found a matching entity claim');				
		}
		else {
			//there was no matching claim. fall back to the value on 'All'
			matchingClaim = this.store.ClientClaims.filter(x => x.Name === "Entities" && x.EntityClaim.EntityFriendlyName === "All");
		}
		return matchingClaim[0];
	}

	//Gets the matching entity property claim
	public EntityPropertyClaim_Get(entityPropertyName: string) {

		//Find the matching entity property claim, if it exists
		const matchingClaim = this.store.ClientClaims.filter(x => x.Name === "EntityProperty" && x.EntityPropertyClaim.EntityPropertyName === entityPropertyName);

		if (this.globalFunctions.isEmpty(matchingClaim)) {
			return null;
		}
		return matchingClaim[0];
	}

	//Gets the matching entity property filter claim
	public EntityPropertyFilterClaim_Get(entityName: string) {

		//Find the matching entity property claim, if it exists
		const matchingClaim = this.store.ClientClaims.filter(x => x.Name === "EntityPropertyFilter" && x.EntityPropertyFilterClaim != null && x.EntityPropertyFilterClaim.EntityFriendlyName === entityName);

		if (this.globalFunctions.isEmpty(matchingClaim)) {
			return null;
		}
		return matchingClaim[0];
	}

	//gets the matching entity claim, when supplied the Entity Name (non friendly)
	public GetEntityClaimNonFriendlyName(entityName: string) {
		//find the matching entity
		const entityLookup = this.ClientEntityTypes.filter(x => x.Name == entityName)[0];
		//console.log('entityLookup', entityLookup);

		let matchingClaim = { Read: false };

		if (!this.globalFunctions.isEmpty(entityLookup)) {
			//now lookup the users claims
			matchingClaim = this.GetEntityClaim(entityLookup.FriendlyName);
		}
		//console.log('matchingClaim[0]', matchingClaim);
		return matchingClaim;
	}

	//gets the document GUID and commences a server request to get the base64 data of the doc
	public Document_Launcher(dataRow: DataRow) {
		//get the document GUID
		const docGUID = dataRow.DataUnits.filter(x => x.Name == "Document GUID")[0].Value;
		//now start a server request for the document data (base 64 string)
		this.Document_RequestData(docGUID, dataRow.Entity, false);
	}

	//gets the document GUID and commences a server request to get the base64 data of the doc
	public Document_Viewer(dataRow: DataRow) {
		//get the document GUID
		const docGUID = dataRow.DataUnits.filter(x => x.Name == "Document GUID")[0].Value;
		const fileType = dataRow.DataUnits.filter(x => x.Name == "File Type")[0].Value;
		//grab file type and send that down to. so that we can mark up display if needed.
		//now start a server request for the document data (base 64 string)
		this.Document_RequestData(docGUID, dataRow.Entity, true, fileType);
	}

	//Calls the server for the base64 data, injects a link into the page and silently 'clicks' it to emulate a download
	public Document_RequestData(GUID: string, entityType: string, useViewer = false, originalFileType = ''): void {
		//Start with a notification, and prevent other clicks
		if (!useViewer) {
			this.notifyService.Info_Show("Please wait", "Download Starting");
		}

		//Turn the loading spinner on
		this.store.SetShowFullscreenLoading(true);

		//Send the entity type as a custom parameter, so that the server can behave appropriately (loan doc vs task doc), also put the DOC GUID as CustomizeParameter2
		const apiRequest = { AccountID: this.AccountID, Entity: "DocumentData", DocumentParentEntity: entityType, DocumentGUID: GUID, GetPreview: false };

		//Check if we are using viewer, and then set the preview flag to true if so
		if (useViewer) {
			apiRequest.GetPreview = true;
		}

		this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetAccountEntity], apiRequest)
			.subscribe(apiResponse => {
				if (this.globalFunctions.isEmpty(apiResponse)) {
					//Turn the loading spinner off
					this.store.SetShowFullscreenLoading(false);
					return;
				}
				else {
					//We need a custom client side parser for documents. first deserialize it into an Entity that we can understand
					const incomingServerEntity: Entity = JSON.parse(JSON.stringify(apiResponse));
					//console.log("incomingServerEntity: ", incomingServerEntity);
					if (incomingServerEntity.EntityRows.length > 0) {
						//Now pull the data out that we need for downloading it
						const documentBase64 = incomingServerEntity.EntityRows[0].DataUnits.filter(x => x.Name === "Document Data")[0].Value;
						let fileName = incomingServerEntity.EntityRows[0].DataUnits.filter(x => x.Name === "File Name")[0].Value;
						const fileType = incomingServerEntity.EntityRows[0].DataUnits.filter(x => x.Name === "File Type")[0].Value;
						const fileNameWithType = fileName + "." + fileType;

						//Generate a synthetic primary key for uniqueness
						if (this.globalFunctions.isEmpty(fileName)) {
							fileName = this.globalFunctions.GenerateFastGUID();
						}

						//Create a blob that can be used to represent the binary version of the file
						const blob = this.globalFunctions.base64toBlob(documentBase64);
						//Construct a URL for it
						const docURL = URL.createObjectURL(blob);
						//Lets create a html element so that we can decorate it with a custom file name.
						const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
						a.href = docURL;
						//Give it the file name that the server data gave us
						a.download = fileNameWithType;

						//Non viewer version, just downloads
						if (!useViewer) {
							//Add and click it. this should launch a download in the browser.
							document.body.appendChild(a);
							a.click();

							//Turn the loading spinner off
							this.store.SetShowFullscreenLoading(false);

							//A notification might be a nice touch here
							this.notifyService.Info_Show("Please check your Downloads folder", "Download Completed");

							//Add a small delay before removing the injected link element.
							this.globalFunctions.delay(500).then(() => {
								document.body.removeChild(a);
								URL.revokeObjectURL(docURL);
							});
						}
						else {
							//Open DocViewer
							const docViewer = this.globalFunctions.FeatureModal_Launch(DocViewer, this.globalFunctions.GetFeatureModalConfig(), this.dialog, "Doc Viewer", this.AccountID, true, false);
							docViewer.DialogRef.componentInstance.LoanIndex = this;
							docViewer.DialogRef.componentInstance.FileBlob = blob;
							docViewer.DialogRef.componentInstance.FileName = fileNameWithType;
							docViewer.DialogRef.componentInstance.FileType = fileType;
							docViewer.DialogRef.componentInstance.OriginalFileType = originalFileType;
							docViewer.DialogRef.componentInstance.DocumentGUID = GUID;
							docViewer.DialogRef.componentInstance.EntityType = entityType;

							//Turn the loading spinner off
							this.store.SetShowFullscreenLoading(false);
						}
					}
					else {
						//Response does not contain a document
						//Turn the loading spinner off
						this.store.SetShowFullscreenLoading(false);

						this.notifyService.Warning_Show("Document data was not found", "Document Not found ");
					}
				}
			});
	}

	//Event that fires when the file input box is changed/file is selected
	public FileSelected_Process(event: Event, fileUpload): void {

		//Reset the file content on LoanIndex
		this.FileUpload_FileBase64String = null;
		this.FileUpload_FileName = null;
		this.FileUpload_IsValid = false;
		this.FileUpload_IsChosen = false;

		//Get the Primeng target file
		const targetFile = event["currentFiles"][0] as HTMLInputElement;

		//Check that a file was chosen
		if (this.globalFunctions.isEmpty(targetFile)) {
			this.notifyService.Warning_Show("No file chosen", "Please select a file")

			//Clear the file selection
			fileUpload.clear();
			return;
		}
		else {

			//Ok so a file was chosen
			this.FileUpload_IsChosen = true;
		}

		//Check file size, stop large ones.
		const fileSize = targetFile.size;

		//This is in bytes. anything larger than 15 MB is probably too big.
		if (fileSize > 15728640) {
			this.FileUpload_IsValid = false;
			this.notifyService.Warning_Show("File must be under 15 Megabytes", "File too large")
			fileUpload.clear();
			return;
		}

		//Check if the file is empty
		if (fileSize <= 0) {
			this.FileUpload_IsValid = false;
			this.notifyService.Warning_Show("File is empty or missing content", "Empty File")
			fileUpload.clear();
			return;
		}

		//Check file type, only allow certain ones.
		//Grab the file name
		this.FileUpload_FileName = targetFile.name

		//Now grab the extension
		const fileExtension = this.FileUpload_FileName.substring(this.FileUpload_FileName.lastIndexOf('.') + 1).toUpperCase();

		//Check if its an allowed file type
		if (this.globalFunctions.AllowedFileExtensions.filter(x => x.toUpperCase() === fileExtension).length === 0) {

			//Invalid
			this.FileUpload_IsValid = false;
			this.notifyService.Warning_Show("Allowed types are: " + this.globalFunctions.GetAllowedFileTypes(), "Invalid file Type")
			fileUpload.clear();
			return;
		}
		else {

			//Indicate that its valid
			this.FileUpload_IsValid = true;
		}

		//Convert the file into Base64 in prep for upload
		this.File_GetBase64(this, targetFile, this.File_HandleFileBase64Result)
	}

	//Gets the base64 from a selected file
	private File_GetBase64(obj: LoanIndex, file, ResultHandler): { (): void } {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		//This is a callback
		reader.onload = function () {

			//Pass the parent class to the handler, so that it can set variables on it
			ResultHandler(obj, reader.result);
		};
		reader.onerror = function (error) {

			//TODO improve this
			console.log('Error: ', error);
		};
		return;
	}

	//store the base64 of the file selected
	FileUpload_FileBase64String: string;
	FileUpload_FileName: string;
	//is the file allowed?
	FileUpload_IsValid = false;
	//is a file chosen
	FileUpload_IsChosen = false;

	//handles getting the base64 of the selected file
	private File_HandleFileBase64Result(obj: LoanIndex, result: string) {
		//the raw base64 string is after the first comma
		const base64result2 = result.split(',')[1];
		//set the variable on the parent class (LoanIndex) so that it can be pulled into the save requested later
		obj.FileUpload_FileBase64String = base64result2;
	}

	//when we want to unselect a file
	public File_Unselect() {
		//reset the file content on LoanIndex
		this.FileUpload_FileBase64String = null;
		this.FileUpload_FileName = null;
		this.FileUpload_IsValid = false;
		this.FileUpload_IsChosen = false;
	}

	//returns all the valid children entities allowed for the supplied parent entity
	public Entity_GetValidChildren(parentEntity: string) {
		const childEntityArray = [];
		if (!this.globalFunctions.isEmpty(parentEntity)) {
			const parentEntityLookup = this.store.EntityTypes.filter(x => x.Name == parentEntity)[0];
			if (!this.globalFunctions.isEmpty(parentEntityLookup)) {
				{
					const childEntities = parentEntityLookup.SupportedChildren;
					const splitted = childEntities.split(",");

					Object.entries(splitted).forEach(
						([, value]) => {
							//there are some illegal ones, at this stage (e.g. clients)
							if (!this.globalFunctions.isEmpty(value)) {
								//console.log('value', value);
								childEntityArray.push(value);
							}
						}
					);
				}
			}
		}
		return childEntityArray;
	}
}