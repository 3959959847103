import { Component, OnInit } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
  selector: 'AccountSummaryDetail',
  templateUrl: './AccountSummaryDetail.html',
  styleUrls: ['./AccountSummaryDetail.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class AccountSummaryDetail implements OnInit {

  //Unique Modal Identifer
  public ModalIdentifier;

  public AccountSummaryConfig;
  public AccountSummaryGroups = [];
  public AccountSummaryDetails = [];
  public AccountSummaryDataUnits = [];

  public ConnectedToAccountSummaryGroups = [];
  public ConnectedToDataUnits = [];
  public AccountSummaryDetailsDisplay = [];
  public AccountSummaryDetailsData = [];

  public DefaultDataUnitGUIDs = [];
  public UsedDataUnitGUIDs = [];

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AccountSummaryDetail>
  ) {

  }

  ngOnInit(): void {

    this.AccountSummaryGroups = [

      { GUID: "{9be543e6-5da9-45aa-a797-0530f6ab6daf}", Name: "Group 1", DisplayName: "Associations", DisplayOrder: 1 }
      , { GUID: "{dfd6c09f-1867-4ef6-83c9-6c5ad0a4b2d4}", Name: "Group 2", DisplayName: "Statement Balance and Status", DisplayOrder: 2 }
      , { GUID: "{dc8694a2-c1c5-49be-a079-041273559fab}", Name: "Group 3", DisplayName: "Interest Rate", DisplayOrder: 3 }
      , { GUID: "{4589b983-af00-4be7-9156-f1f0f06aef3b}", Name: "Group 4", DisplayName: "Security", DisplayOrder: 4 }
      , { GUID: "{83203c1a-9ec0-4639-83da-78413fbf54f5}", Name: "Group 5", DisplayName: "Direct Debit", DisplayOrder: 5 }
      , { GUID: "{eaa6825e-9fd5-46b0-a35b-6d20e50c9a9d}", Name: "Group 6", DisplayName: "Instalment", DisplayOrder: 6 }
      , { GUID: "{12f567d7-0693-40d7-ad86-e10976eded2f}", Name: "Group 7", DisplayName: "Linked Accounts", DisplayOrder: 7 }

    ];

    this.AccountSummaryDetails = [
      { GUID: "{a45adf00-e826-4bde-83f6-0664af599c8f}", DUSummaryConfigGroupGUID: "{9be543e6-5da9-45aa-a797-0530f6ab6daf}", DataUnitGUID: "{2b414dfb-bae4-484a-a22d-64f331b9465b}", DisplayOrder: 1 }
      , { GUID: "{e6a4194f-9a86-41df-8917-75b899d0033d}", DUSummaryConfigGroupGUID: "{9be543e6-5da9-45aa-a797-0530f6ab6daf}", DataUnitGUID: "{b8dba330-5135-4324-9635-7520493c8ec4}", DisplayOrder: 2 }
      , { GUID: "{4beb3c4c-129b-4898-a4ef-0bc1b4b5e7b9}", DUSummaryConfigGroupGUID: "{9be543e6-5da9-45aa-a797-0530f6ab6daf}", DataUnitGUID: "{8d2b7b54-72de-4670-88e8-656bc0401a75}", DisplayOrder: 3 }
      , { GUID: "{1c3e637f-e45e-4f7a-80fd-d4bfd2107539}", DUSummaryConfigGroupGUID: "{9be543e6-5da9-45aa-a797-0530f6ab6daf}", DataUnitGUID: "{77f4b5e1-84a2-4e32-870b-36c498d16df1}", DisplayOrder: 4 }
      , { GUID: "{72a370ac-9706-407c-aa42-d9b3fac3d38d}", DUSummaryConfigGroupGUID: "{9be543e6-5da9-45aa-a797-0530f6ab6daf}", DataUnitGUID: "{7d3cf104-f03b-4b91-bdd8-f983b919232c}", DisplayOrder: 5 }
      , { GUID: "{c95d1914-dd5f-4e99-8341-17b82d3bec34}", DUSummaryConfigGroupGUID: "{dfd6c09f-1867-4ef6-83c9-6c5ad0a4b2d4}", DataUnitGUID: "{b4dc77a5-8145-4014-8e20-9bcb55d8f2fd}", DisplayOrder: 6 }
      , { GUID: "{02ad11d6-ac45-4858-a859-2980b6c179b5}", DUSummaryConfigGroupGUID: "{dfd6c09f-1867-4ef6-83c9-6c5ad0a4b2d4}", DataUnitGUID: "{5a911ec7-6598-4e90-9f80-be42f5d493b8}", DisplayOrder: 7 }
      , { GUID: "{2110131c-ce50-41b8-8f29-7d9bada2482f}", DUSummaryConfigGroupGUID: "{dfd6c09f-1867-4ef6-83c9-6c5ad0a4b2d4}", DataUnitGUID: "{0aa315e2-d4aa-41e4-b7b5-26fec7464c58}", DisplayOrder: 8 }
      , { GUID: "{464f2406-9911-4b86-a3f6-7839b283bc22}", DUSummaryConfigGroupGUID: "{dfd6c09f-1867-4ef6-83c9-6c5ad0a4b2d4}", DataUnitGUID: "{e8469ba5-0624-416b-8894-11d4fd4ca041}", DisplayOrder: 9 }
      , { GUID: "{c68d8225-4178-4b28-bd10-241c019d9716}", DUSummaryConfigGroupGUID: "{dc8694a2-c1c5-49be-a079-041273559fab}", DataUnitGUID: "{f26a4869-eebb-490c-a63d-25a585c5dc35}", DisplayOrder: 10 }
      // , { GUID: "{01c7d407-6e07-40d2-b2de-f6e904f40929}", DUSummaryConfigGroupGUID: "{4589b983-af00-4be7-9156-f1f0f06aef3b}", DataUnitGUID: "{32f0ec03-f5a7-43d0-8151-53cae154aa68}", DisplayOrder: 11 }
      // , { GUID: "{88ddf302-9540-4f66-9a07-78628eda2bfb}", DUSummaryConfigGroupGUID: "{4589b983-af00-4be7-9156-f1f0f06aef3b}", DataUnitGUID: "{fbcc9b4a-8676-4eca-be7f-0f1573eca073}", DisplayOrder: 12 }
      // , { GUID: "{3a09c471-e18d-4e4e-97ad-f3018930c435}", DUSummaryConfigGroupGUID: "{83203c1a-9ec0-4639-83da-78413fbf54f5}", DataUnitGUID: "{f16ad9b7-f1db-46c1-a4a7-ee497f3478e0}", DisplayOrder: 13 }
      // , { GUID: "{20354f20-f551-4286-9d4e-1faac8b39c74}", DUSummaryConfigGroupGUID: "{eaa6825e-9fd5-46b0-a35b-6d20e50c9a9d}", DataUnitGUID: "{d91b39f4-659e-41bb-ac20-e89ac62e5baf}", DisplayOrder: 14 }
      // , { GUID: "{e9af54c3-577c-4bba-9b39-938d30a2d6f8}", DUSummaryConfigGroupGUID: "{12f567d7-0693-40d7-ad86-e10976eded2f}", DataUnitGUID: "{2998c6f7-cebb-460d-9789-43001ee5b9c6}", DisplayOrder: 15 }
    ];



    this.AccountSummaryDataUnits = [
      { GUID: "{2b414dfb-bae4-484a-a22d-64f331b9465b}", Name: "Principal Borrower", DisplayOrder: 1 }
      , { GUID: "{b8dba330-5135-4324-9635-7520493c8ec4}", Name: "Secondary Borrower", DisplayOrder: 2 }
      , { GUID: "{8d2b7b54-72de-4670-88e8-656bc0401a75}", Name: "Guarantor", DisplayOrder: 3 }
      , { GUID: "{77f4b5e1-84a2-4e32-870b-36c498d16df1}", Name: "Correspondent", DisplayOrder: 4 }
      , { GUID: "{7d3cf104-f03b-4b91-bdd8-f983b919232c}", Name: "Authorised 3rd Party", DisplayOrder: 5 }
      , { GUID: "{b4dc77a5-8145-4014-8e20-9bcb55d8f2fd}", Name: "Statement Balance", DisplayOrder: 6 }
      , { GUID: "{5a911ec7-6598-4e90-9f80-be42f5d493b8}", Name: "Account Status", DisplayOrder: 7 }
      , { GUID: "{0aa315e2-d4aa-41e4-b7b5-26fec7464c58}", Name: "Advance Balance", DisplayOrder: 8 }
      , { GUID: "{e8469ba5-0624-416b-8894-11d4fd4ca041}", Name: "Arrears Balance", DisplayOrder: 9 }
      , { GUID: "{f26a4869-eebb-490c-a63d-25a585c5dc35}", Name: "Account Interest Rate", DisplayOrder: 10 }
      , { GUID: "{32f0ec03-f5a7-43d0-8151-53cae154aa68}", Name: "Security Property", DisplayOrder: 11 }
      , { GUID: "{fbcc9b4a-8676-4eca-be7f-0f1573eca073}", Name: "Equipment Security", DisplayOrder: 12 }
      , { GUID: "{f16ad9b7-f1db-46c1-a4a7-ee497f3478e0}", Name: "Direct Debit", DisplayOrder: 13 }
      , { GUID: "{d91b39f4-659e-41bb-ac20-e89ac62e5baf}", Name: "Instalment Details", DisplayOrder: 14 }
      , { GUID: "{2998c6f7-cebb-460d-9789-43001ee5b9c6}", Name: "Linked Accounts", DisplayOrder: 15 }
    ];

    this.AccountSummaryDetailsDisplay = [];

    //Initialise Account Summary Groups with the available data
    this.AccountSummaryGroups.forEach(group => {
      const groupDetails = this.AccountSummaryDetails.filter(x => x.DUSummaryConfigGroupGUID === group.GUID);
      const detailDUGUIDs = [];
      if (!this.globalFunctions.isEmpty(groupDetails)) {

        groupDetails.forEach(detail => {
          detailDUGUIDs.push(detail.DataUnitGUID);
          this.UsedDataUnitGUIDs.push(detail.DataUnitGUID);
        });

        this.AccountSummaryDetailsDisplay.push(
          {
            GroupGUID: group.GUID
            , Name: group.DisplayName
            , Type: "Section"
            , DetailGUIDs: detailDUGUIDs
          }
        );
      }
    });

    //Lets add all avialable DataUnits to the same group
    const DUGUIDs = [];
    this.AccountSummaryDataUnits.forEach(du => {
      this.DefaultDataUnitGUIDs.push(du.GUID);
    });

    this.DefaultDataUnitGUIDs.forEach(du => {
      if (!this.UsedDataUnitGUIDs.includes(du)) {
        DUGUIDs.push(du);
      }

    });


    this.AccountSummaryDetailsDisplay.push(
      {
        GroupGUID: "Total"
        , Name: "Available Data Units"
        , Type: "DataUnits"
        , DetailGUIDs: DUGUIDs
      }

    );


    for (const detailDisplay of this.AccountSummaryDetailsDisplay) {
      this.ConnectedToAccountSummaryGroups.push(detailDisplay.GroupGUID);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("Event", event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    // console.log("Weeks", this.weeks);
  }

  public AccountSummaryGroups_Get() {
    //return this.AccountSummaryDetailsDisplay.filter(x => x.GroupGUID !== "Total");
    return this.AccountSummaryDetailsDisplay.filter(x => x.Type === "Section");
  }

  public DataUnitGroups_Get() {
    //return this.AccountSummaryDetailsDisplay.filter(x => x.GroupGUID === "Total");
    return this.AccountSummaryDetailsDisplay.filter(x => x.Type === "DataUnits");
  }


  public SummarySetup_Click() {
    console.log("this.AccountSummaryDetailsDisplay", this.AccountSummaryDetailsDisplay);
  }

  public RemoveSection_Click(groupGUID) {
    const matchingGroup = this.AccountSummaryDetailsDisplay.filter(x => x.GroupGUID === groupGUID)[0];

    if (!this.globalFunctions.isEmpty(matchingGroup)) {

      //Lets sync the removed DUs
      const groupDUDetails = matchingGroup.DetailGUIDs;
      if (!this.globalFunctions.isEmpty(groupDUDetails)) {
        const availableDUDetails = this.AccountSummaryDetailsDisplay.filter(x => x.Type === "DataUnits")[0];

        if (!this.globalFunctions.isEmpty(availableDUDetails)) {
          groupDUDetails.forEach(deletedDU => {
            availableDUDetails.DetailGUIDs.push(deletedDU);
          });
        }
      }

      //Find the index to splice from the array
      const matchingGroupIndex = this.AccountSummaryDetailsDisplay.findIndex(x => x.GroupGUID === matchingGroup.GroupGUID);
      this.AccountSummaryDetailsDisplay.splice(matchingGroupIndex, 1);

      const matchingConnectedGroupIndex = this.ConnectedToAccountSummaryGroups.findIndex(x => x === matchingGroup.GroupGUID);
      if (!this.globalFunctions.isEmpty(matchingConnectedGroupIndex)) {
        this.ConnectedToAccountSummaryGroups.splice(matchingConnectedGroupIndex, 1);
      }

      this.DataUnits_Sync();
    }
  }

  public AddNewSection_Click() {
    const newGroupGUID = this.globalFunctions.GenerateFastGUID();

    this.AccountSummaryDetailsDisplay.push(
      {
        GroupGUID: newGroupGUID
        , Name: "{New Group}"
        , Type: "Section"
        , DetailGUIDs: []
      }

    );

    this.ConnectedToAccountSummaryGroups.push(newGroupGUID);
  }

  public DataUnitName_Get(dataUnitGUID: string) {
    const targetDU = this.AccountSummaryDataUnits.filter(x => x.GUID === dataUnitGUID)[0];
    if (!this.globalFunctions.isEmpty(targetDU)) {
      return targetDU.Name;

    }

    return "Not found";
  }

  public AccountSummaryGroupName_Get(groupGUID: string) {
    const targetSummaryGroup = this.AccountSummaryGroups.filter(x => x.GUID === groupGUID)[0];

    if (!this.globalFunctions.isEmpty(targetSummaryGroup)) {
      return targetSummaryGroup.Name;
    }

    return "Not found";
  }

  public DataUnits_Sync() {
    const availableDUDetails = this.AccountSummaryDetailsDisplay.filter(x => x.Type === "DataUnits")[0];

    if (!this.globalFunctions.isEmpty(availableDUDetails)) {
      const newAvailableDUDetails = [];
      if (this.globalFunctions.isEmpty(availableDUDetails.DetailGUIDs)) {
        availableDUDetails.DetailGUIDs = [];
      }
      this.DefaultDataUnitGUIDs.forEach(element => {
        if (availableDUDetails.DetailGUIDs.includes(element) === true) {
          newAvailableDUDetails.push(element);
        }
      });

      availableDUDetails.DetailGUIDs = newAvailableDUDetails;
    }
  }


}