<div class="glb_paddingTopLG glb_paddingBottomLG glb_formBGColor text-white glb_Font">
  <div class="glb_paddingOffset col-md-12 col-lg-12">
    <p class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">Account Summary Configurations</p>
  </div>

  <div>
    <div class="itemFirstBorder glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
      <div class="itemBorders" *ngFor="let item of AccountSummaryConfigs | searchfilter " @fadeIn
        style="padding: 0px; padding-top: 15px; padding-bottom: 10px;">
        <a (click)="AccountSummaryConfigDetail_View()" style="cursor: pointer;">
          <div class="glb_customFlexRow col-lg-12">
            <div class="col-12 col-lg-10 text-white align-items-center glb_customFlexRow">
              <div class="itemTitle glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">{{item.Name}}
              </div>
            </div>

            <div class="rightChevron col-0 col-lg-2 glb_customFlexRow justify-content-end align-items-center">
              <!-- show a chevron, only on lg screens -->
              <div class="d-none d-lg-block">
                <fa-icon [icon]="['fal', 'chevron-right']" size="lg" class="btn align-self-right glb_fixedSizeIcons" style="color:grey;"
                  matTooltip="Click to Launch"></fa-icon>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>